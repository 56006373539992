export const MODAL_SIZE = {
  SM: 'SM',
  MD: 'MD',
  LG: 'LG',
  XL: 'XL',
  XXL: 'XXL',
  XXXL: 'XXXL',
};

export const MODAL_SIZE_STYLE = {
  [MODAL_SIZE.SM]: 'tw-max-w-sm',
  [MODAL_SIZE.MD]: 'tw-max-w-md',
  [MODAL_SIZE.LG]: 'tw-max-w-lg',
  [MODAL_SIZE.XL]: 'tw-max-w-[720px]',
  [MODAL_SIZE.XXL]: 'tw-max-w-[1320px]',
  [MODAL_SIZE.XXXL]: 'tw-max-w-[1820px]',
};
