const DOCUMENT_TYPE = {
  PROOF_SIGN: 'PROOF_SIGN',
  'PREVIOUS-TOUR': 'PREVIOUS-TOUR',
  'COMMON-CHECKLIST': 'COMMON-CHECKLIST',
  'DELIVERY-DETAIL-RESERVE-PHOTO': 'DELIVERY-DETAIL-RESERVE-PHOTO',
  'ITEM-RESERVE-PHOTO': 'ITEM-RESERVE-PHOTO',
  TOUR_RESERVE_PHOTO: 'TOUR_RESERVE_PHOTO',
  'DRIVER-LETTER': 'DRIVER-LETTER',
  'DISINFECTION-DONE': 'DISINFECTION-DONE',
  'COW-PURGE': 'COW-PURGE',
  'DELIVERY-DETAIL-DOCUMENT': 'DELIVERY-DETAIL-DOCUMENT',
  VEHICLE_PHOTO_FRONT: 'VEHICLE_PHOTO_FRONT',
  VEHICLE_PHOTO_BACK: 'VEHICLE_PHOTO_BACK',
  CLIENT_PHOTOS: 'CLIENT_PHOTOS',
  BEFORE_DELIVERY_PHOTO: 'BEFORE_DELIVERY_PHOTO',
  AFTER_DELIVERY_PHOTO: 'AFTER_DELIVERY_PHOTO',
  CLIENT_RESERVE_PHOTO: 'CLIENT_RESERVE_PHOTO',

  properties: {
    PROOF_SIGN: {
      display_name: 'Signature client',
    },
    'PREVIOUS-TOUR': {
      display_name: 'Lettre de voiture du tour précédent (chauffeur externe)',
    },
    'COMMON-CHECKLIST': {
      display_name: 'Checklist commune',
    },
    'DELIVERY-DETAIL-RESERVE-PHOTO': {
      display_name: 'Photo de la réserve',
    },
    'ITEM-RESERVE-PHOTO': {
      display_name: 'Photo associé à une réserve sur un produit',
    },
    TOUR_RESERVE_PHOTO: {
      display_name: 'Photo associé à une réserve sur la tournée',
    },
    'DRIVER-LETTER': {
      display_name: 'Lettre de voiture chauffeur',
    },
    'DISINFECTION-DONE': {
      display_name: "Feuille d'alerte sanitaire",
    },
    'COW-PURGE': {
      display_name: 'Feuille de rinçage après chargement bovin',
    },
    'DELIVERY-DETAIL-DOCUMENT': {
      display_name: "Document associé à la livraison / reprise d'un client",
    },
    VEHICLE_PHOTO_FRONT: {
      display_name: 'Photo de la voiture (avant)',
    },
    VEHICLE_PHOTO_BACK: {
      display_name: 'Photo de la voiture (arrière)',
    },
    CLIENT_PHOTOS: {
      display_name: 'Photo client',
    },
    BEFORE_DELIVERY_PHOTO: {
      display_name: 'Photo avant livraison',
    },
    AFTER_DELIVERY_PHOTO: {
      display_name: 'Photo après livraison',
    },
    CLIENT_RESERVE_PHOTO: {
      display_name: 'Photo associé à une réserve sur un client',
    },
  },
};

export default DOCUMENT_TYPE;
