import React from 'react';
import noDataIllustration from './no-data.png';

export default function EmptyList({ type }) {
  return (
    <div className="tw-relative tw-flex tw-flex-col tw-items-center tw-text-base tw-text-center tracking-[-0.02em] tw-gap-2 tw-mt-[150px]">
      <img
        alt="no-data"
        src={noDataIllustration}
        height="150"
        width="150"
        className="tw-z-50 tw-select-none"
      ></img>
      <p className="tw-text-base tw-font-work tw-font-medium tw-mb-0 tw-z-50">
        Aucune donnée
      </p>
      <p className="tw-text-sm tw-font-work tw-font-normal tw-text-[#333333] tw-mb-0 tw-z-50">
        Aucune donnée disponible pour le moment.
      </p>
      <div className="tw-w-1/2 tw-translate-x-[-1/2] tw-absolute !tw-m-[0] tw-top-[-56px] [filter:blur(40px)] tw-rounded-[50%] tw-bg-primary/5 tw-h-[215px]" />
    </div>
  );
}
