import { ReactComponent as TruckOut } from '../assets/images/icons/step-type/truck-out.svg';
import { ReactComponent as TruckIn } from '../assets/images/icons/step-type/truck-in.svg';
import { ReactComponent as TruckInOut } from '../assets/images/icons/step-type/arrow-in-out.svg';
import { ReactComponent as TruckInOutTransfer } from '../assets/images/icons/step-type/truck-in-out.svg';
import { ReactComponent as TruckKeep } from '../assets/images/icons/step-type/truck-keep.svg';
import { ReactComponent as TruckTransfer } from '../assets/images/icons/step-type/truck-tranfer.svg';

export const STEP_TYPE = {
  LIVRAISON: 'LIVRAISON',
  REPRISE: 'REPRISE',
  'LIVRAISON-REPRISE': 'LIVRAISON-REPRISE',
  'TRANSFERT-SILO': 'TRANSFERT-SILO',
  GARDER: 'GARDER',
  TRANSVIDAGE: 'TRANSVIDAGE',

  properties: {
    LIVRAISON: {
      icon: TruckOut,
    },
    REPRISE: {
      icon: TruckIn,
    },
    'LIVRAISON-REPRISE': {
      icon: TruckInOut,
    },
    'TRANSFERT-SILO': {
      icon: TruckInOutTransfer,
    },
    GARDER: {
      icon: TruckKeep,
    },
    TRANSVIDAGE: {
      icon: TruckTransfer,
    },
  },
};
