import { ReactComponent as TruckIn } from '../assets/images/icons/step-type/truck-in.svg';
import { ReactComponent as TruckKeep } from '../assets/images/icons/step-type/truck-keep.svg';
import { ReactComponent as TruckOut } from '../assets/images/icons/step-type/truck-out.svg';
import { ReactComponent as TruckInOut } from '../assets/images/icons/step-type/truck-in-out.svg';
import { ReactComponent as TruckTransfer } from '../assets/images/icons/step-type/truck-tranfer.svg';

export const DELIVERY_DETAIL_TYPE = {
  LIVRAISON: 'LIVRAISON',
  'LIVRAISON-VIA-TRANSFERT-SILO': 'LIVRAISON-VIA-TRANSFERT-SILO',
  REPRISE: 'REPRISE',
  TRANSVIDAGE: 'TRANSVIDAGE',
  GARDER: 'GARDER',
  'TRANSFERT-SILO': 'TRANSFERT-SILO',

  properties: {
    LIVRAISON: {
      icon: TruckOut,
    },
    'LIVRAISON-VIA-TRANSFERT-SILO': {
      icon: TruckOut,
    },
    REPRISE: {
      icon: TruckIn,
    },
    TRANSVIDAGE: {
      icon: TruckTransfer,
    },
    GARDER: {
      icon: TruckKeep,
    },
    'TRANSFERT-SILO': {
      icon: TruckInOut,
    },
  },
};
