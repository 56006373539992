import React from 'react';
import Header from '../agv-header';

export default function GenericPageContainer({
  title,
  subtitle,
  filters,
  children,
  page,
}) {
  return (
    <div className="tw-flex tw-flex-col tw-h-full tw-w-full tw-relative tw-overflow-hidden">
      <div className="tw-sticky tw-top-0 tw-z-10">
        <Header title={title} subtitle={subtitle} page={page} filters={filters} />
      </div>
      <div id="page-content" className="tw-flex-1 tw-overflow-auto">
        <div className={`tw-flex tw-justify-center tw-w-full tw-px-[32px] tw-mt-2`}>
          {page == 'Dashboard' ? (
            <div className="tw-flex-1">{children}</div>
          ) : (
            <div className="tw-max-w-[1320px] tw-flex-1">{children}</div>
          )}
        </div>
      </div>
    </div>
  );
}
