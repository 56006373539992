import {
  faExclamationCircle,
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import fleetBrand from '../../assets/images/logos/FleetWhite.png';
import './reset-password.css';

export default function ResetPassword() {
  const [token, setToken] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [tokenErrorMessage, setTokenErrorMessage] = useState('');
  const [informationMessage, setInformationMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [errorStatus, setErrorStatus] = useState(false);

  const BASE_URL = useSelector((state) => state.BASE_URL);
  const params = useParams();

  useEffect(() => {
    (async () => {
      if (params?.token) {
        setToken(params?.token);

        const url = new URL(
          BASE_URL + '/auth/check-forgot-password-token-validity'
        );
        let myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');
        let myInit = {
          method: 'POST',
          headers: myHeaders,
          body: JSON.stringify({
            token: params?.token,
          }),
          cache: 'no-cache',
        };

        let response = await fetch(url, myInit);
        let result = await response.json();

        if (!response.ok) {
          setTokenErrorMessage(result.message);
        }
      } else {
        setTokenErrorMessage(
          'Le token ne semble plus être valide. Veuillez refaire la demande de réinitialisation de mot de passe !'
        );
      }
    })();
  }, []);

  const changePassword = async (e) => {
    e.preventDefault();

    if (password && passwordConfirm && password === passwordConfirm && token) {
      const url = new URL(BASE_URL + '/auth/change-password');
      let myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/json');
      let myInit = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify({
          token: token,
          password: password,
        }),
        cache: 'no-cache',
      };

      let response = await fetch(url, myInit);
      let result = await response.json();

      if (response.ok) {
        setPassword('');
        setPasswordConfirm('');
        setInformationMessage(result?.message);
        setErrorMessage('');
        setErrorStatus(false);
      } else {
        setInformationMessage('');
        setErrorMessage("Une erreur s'est produite");
        setErrorStatus(true);
      }
    }
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handlePasswordConfirmChange = (e) => {
    setPasswordConfirm(e.target.value);
  };

  return (
    <>
      <div style={{ height: '100%' }}>
        <Row className="align-items-center h-100">
          <Col lg={6} className="d-flex align-items-center">
            {tokenErrorMessage ? (
              <>
                <div className={'center-block'}>
                  <div className={'token-error-message'}>
                    {tokenErrorMessage}
                  </div>
                  <Link to="/login">
                    <p>Retour à la page de connexion</p>
                  </Link>
                </div>
              </>
            ) : token ? (
              <>
                <div className="container-form">
                  <h2 className="welcome">Réinitialisez votre mot de passe</h2>
                  <p className="p-info">
                    Saisissez votre nouveau mot de passe ci-dessous.
                  </p>
                  <Form>
                    <Form.Label className="label">Mot de passe</Form.Label>
                    <Form.Control
                      className="custom-input-reset"
                      placeholder="Votre nouveau mot de passe"
                      type="password"
                      onChange={handlePasswordChange}
                      aria-describedby="basic-addon1"
                    />
                    <Form.Control
                      className="custom-input-reset mt-3"
                      placeholder="Confirmation du mot de passe"
                      type="password"
                      onChange={handlePasswordConfirmChange}
                      aria-describedby="basic-addon1"
                    />
                    <div style={{ width: '100%' }}>
                      <Button
                        disabled={
                          password &&
                          passwordConfirm &&
                          password === passwordConfirm
                            ? false
                            : true
                        }
                        variant="none"
                        type="submit"
                        onClick={changePassword}
                        block
                        className={'change-password-btn mt-4'}
                      >
                        Changer de mot de passe
                      </Button>
                    </div>
                  </Form>
                </div>
                {informationMessage ? (
                  <>
                    <div className={'information-message-container'}>
                      <div className={'information-message'}>
                        <FontAwesomeIcon icon={faInfoCircle} />{' '}
                        {informationMessage}
                      </div>
                    </div>
                  </>
                ) : null}
                <div className={'error-message-container'}>
                  <div
                    style={{
                      display: errorStatus ? 'block' : 'none',
                    }}
                    className={'error-message'}
                  >
                    <FontAwesomeIcon icon={faExclamationCircle} />{' '}
                    {errorMessage}
                  </div>
                </div>
              </>
            ) : null}
          </Col>
          <Col
            lg={6}
            className="h-100 d-sm-none d-lg-block"
            style={{ backgroundColor: '#079992' }}
          >
            <div className="background-img d-flex align-items-center justify-content-center">
              <img src={fleetBrand} alt="logo" className="logo-login" />
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}
