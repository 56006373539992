import React from 'react';
import { STATUS_COLORS } from './status-enum';

export default function Status({ status, text, style }) {
  return (
    <div
      className={`tw-flex tw-shrink tw-grow-0 tw-justify-center tw-items-center tw-rounded-full ${style} ${STATUS_COLORS[status]?.backgroundColor} ${STATUS_COLORS[status]?.borderColor} ${STATUS_COLORS[status]?.textColor} tw-border-solid tw-border tw-px-2 tw-py-[3px] tw-font-medium tw-text-xs tw-leading-[14px] tw-font-work tw-text-center tw-select-none`}
    >
      {text}
    </div>
  );
}
