import React from 'react';
import Row from './row';

export default function GenericTable({ title, rows, iconColor }) {
  return (
    <div className="tw-w-full tw-mt-8">
      {title && rows?.length > 0 && (
        <div className="tw-font-semibold tw-mb-4">{title}</div>
      )}

      <div className="tw-rounded-md tw-bg-white tw-divide-solid tw-divide-x-0 tw-divide-y tw-divide-background tw-overflow-x-auto">
        {rows?.map((row, index) => (
          <Row
            key={`${row?.title}-${index}`}
            iconColor={iconColor}
            color={row?.color}
            title={row?.title}
            subtitle={row?.subtitle}
            icon={row?.icon}
            onClick={row?.onClick}
            buttonText={row?.buttonText}
            status={row?.status}
            statusText={row?.statusText}
            fields={row?.fields}
          />
        ))}
      </div>
    </div>
  );
}
