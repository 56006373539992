import { useState } from 'react';
import { toast } from 'react-toastify';
import fleetApi from '../../services/api.service';

export default function useCreateUser() {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(null);

  const createUser = async (
    client_id,
    first_name,
    last_name,
    email,
    phone_number,
    factory_id,
    roles
  ) => {
    resetStates();
    try {
      setIsLoading(true);
      const response = await fleetApi.post(`/users`, {
        client_id,
        first_name,
        last_name,
        email,
        phone_number,
        factory_id,
        roles,
      });
      await new Promise((resolve) => setTimeout(resolve, 500));
      if (response.status === 200) {
        setIsSuccess(true);
        toast.success(
          response.data?.message || 'Utilisateur créé avec succès.'
        );
      } else {
        throw new Error(response.data?.message || 'Une erreur est survenue.');
      }
    } catch (error) {
      setIsSuccess(false);
      toast.error(error?.response.data?.message || error?.message);
    } finally {
      setIsLoading(false);
    }
  };

  const resetStates = () => {
    setIsSuccess(false);
    setIsLoading(false);
  };

  return {
    isLoading,
    isSuccess,
    createUser,
  };
}
