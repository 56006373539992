import moment from 'moment';
import { tour_status } from '../../enums/tour-status';

const computeTourSubtitle = (features, tour) => {
  let tourSubtitleParts = [];

  if (features?.tour_number_only_last_digit && tour?.tour_number) {
    tourSubtitleParts.push(`Tournée ${tour.tour_number}`);
  } else if (tour?.vehicule_key) {
    tourSubtitleParts.push(`Véhicule ${tour.vehicule_key}`);
  }

  if (features?.tour_creation_date_used && tour?.creation_date) {
    const creationDateParsed = moment(tour.creation_date).format('HH[h]mm');
    tourSubtitleParts.push(`Chargée à ${creationDateParsed}`);
  } else if (tour?.status !== tour_status.IMPORTED && tour?.starting_date) {
    const startingDateParsed = moment(tour.starting_date).format('HH[h]mm');
    tourSubtitleParts.push(`Départ à ${startingDateParsed}`);
  } else if (tour?.starting_date) {
    tourSubtitleParts.push(`Départ non saisi`);
  }

  if (tour?.additional_datas?.cleaning_mandatory === 1) {
    tourSubtitleParts.push(`Nettoyage obligatoire`);
  }

  if (tour?.additional_datas?.key_mandatory === 1) {
    tourSubtitleParts.push(`Clé nécessaire pour la livraison`);
  }

  return tourSubtitleParts?.join(' • ');
};

export default computeTourSubtitle;
