// utils.js

/**
 * Appends query parameters to a relative URL.
 *
 * @param {string} path - The relative path to which the query parameters will be appended.
 * @param {object} params - An object containing the query parameters as key-value pairs.
 * @returns {string} - The complete URL with the query parameters.
 */
export const buildUrlWithParams = (path, params) => {
  if (
    params === null ||
    params === undefined ||
    Object.keys(params).length === 0
  ) {
    return path;
  }
  const searchParams = new URLSearchParams(params);
  return `${path}?${searchParams.toString()}`;
};
