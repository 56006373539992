import React from 'react';
import { ALIMENT_TYPE_PROPS } from './aliment-type.enum';

export default function AlimentTypeTag({ item }) {
  const IconComponent = ALIMENT_TYPE_PROPS[item].pictogram;

  return (
    <div
      className={`tw-rounded-full ${ALIMENT_TYPE_PROPS[item].backgroundColor} ${ALIMENT_TYPE_PROPS[item].borderColor} tw-box-border tw-flex tw-flex-row tw-items-center tw-justify-center tw-px-2 tw-py-0.5 tw-border tw-border-solid`}
    >
      <IconComponent
        className={`tw-w-auto tw-h-4 ${ALIMENT_TYPE_PROPS[item].textColor} tw-fill-current`}
      />
    </div>
  );
}
