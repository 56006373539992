import React from 'react'
import "./bl-reserve.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStickyNote } from '@fortawesome/free-solid-svg-icons'

export default function BlReserve({bl}) {
  return (
    <div className='bl-container'>
      <div className='left-part'>
        <div className='square'>
          <FontAwesomeIcon icon={faStickyNote} size='xs' color='#ABB5BF' />
        </div>
        <div>
          <div className='bl-title'>BL{bl?.delivery_detail_key}</div>
        </div>
      </div>
    </div>
  )
}
