import React from 'react';
import './pie-chart.scss';
import {
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';

const PieChartComponent = ({
  pie_chart_data,
  startAngle,
  endAngle,
  innerRadius,
  fill,
  paddingAngle,
  hasTooltip = true,
}) => {
  const { text, key_names, values, colors } = pie_chart_data || {};

  const pieChartDatas = values
    ? values.map((value, index) => {
        return {
          name: key_names[index],
          value: value || 0,
          color: colors[index] || '#000000',
        };
      })
    : [];

  return (
    <>
      <div className="tw-p-5 tw-flex tw-justify-between ">
        <div className=" tw-font-semibold tw-text-xs">{text ? text : ''}</div>
      </div>
      <ResponsiveContainer width="100%" height="80%" debounce={1}>
        <PieChart>
          <Pie
            data={pieChartDatas}
            startAngle={startAngle}
            endAngle={endAngle}
            innerRadius={innerRadius}
            fill={fill}
            paddingAngle={paddingAngle}
            dataKey="value"
          >
            {pieChartDatas?.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Pie>
          <Legend
            layout="horizontal"
            align="center"
            verticalAlign="bottom"
            iconSize={10}
            formatter={(value, entry, index) => (
              <span className="tw-text-xs tw-font-semibold">{value}</span>
            )}
          />
          {hasTooltip && <Tooltip />}
        </PieChart>
      </ResponsiveContainer>
    </>
  );
};

export default PieChartComponent;
