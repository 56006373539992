import {
  faClipboard,
  faComment,
  faExpand,
  faHeadset,
  faRoute,
  faTimes,
  faTruck,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, {
  Suspense,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useNavigate } from 'react-router';

import CustomDatePickerDashboard from '../../components/agv-header-date-picker';

import fr from 'date-fns/locale/fr';
import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'simplebar-react/dist/simplebar.min.css';

import 'react-circular-progressbar/dist/styles.css';

import SkeletonLoader from 'tiny-skeleton-loader-react';
import './home.scss';

import ReactDatePicker from 'react-datepicker';
import { useSelector } from 'react-redux';
import GenericPageContainer from '../../components/agv-generic-page-container';

import {
  Checkbox,
  Dialog,
  DialogPanel,
  Transition,
  TransitionChild,
} from '@headlessui/react';
import moment from 'moment';
import { io } from 'socket.io-client';
import useGetDashboard from '../../api/dashboard/useGetDashboard';
import useGetTourDetails from '../../api/tours/useGetToursDetails';
import AgrivitechCheckbox from '../../components/agv-checkbox/agv-checkbox';
import GenericTableSkeleton from '../../components/agv-generic-table/genericTableSkeleton';
import Row from '../../components/agv-generic-table/row';
import HeaderButton from '../../components/agv-header-button';
import Status from '../../components/agv-status/index';
import { STATUS_ENUM } from '../../components/agv-status/status-enum';
import AlimentTypeTag from '../../components/fleet-aliment-type-tag/aliment-type-tag';
import { ALIMENT_TYPE_ENUM } from '../../components/fleet-aliment-type-tag/aliment-type.enum';
import { DELIVERY_DETAIL_TYPE } from '../../enums/delivery-detail.enum';
import DOCUMENT_TYPE from '../../enums/documents.enum';
import { MODAL_SIZE, MODAL_SIZE_STYLE } from '../../enums/modal-size.enum';
import { reserves_types } from '../../enums/reserve-status.enum';
import { step_status } from '../../enums/step-status.enum';
import { tour_status } from '../../enums/tour-status';
import { computeRowForOneDeliveryDetail } from '../../utils/silo.util';
import ModalReserve from '../Reserves/ModalReserve';
import ModalTour from '../Tour/ModalTour';
import BarChartComponent from './Dashboard/barChart';
import Kpi from './Dashboard/kpi';
import PieChartComponent from './Dashboard/pieChart';

moment.locale('fr');

registerLocale('fr', fr);

const MapFleetDashboard = React.lazy(
  () => import('./Dashboard/mapFleetDashboard_v3')
);

let socket;
const HomeV3 = () => {
  /******
   *
   * States
   *
   ******/

  const [selectedTourID, setSelectedTourID] = useState(null);
  const [selectedTour, setSelectedTour] = useState(null);

  const [isMapReady, setIsMapReady] = useState(false);
  const [isMapUpdating, setIsMapUpdating] = useState(false);

  const [openMapModal, setOpenMapModal] = useState(false);

  const [openTourModal, setOpenTourModal] = useState(false);

  const [modalHandleReserve, setModalHandleReserve] = useState(false);
  const [selectedReserve, setSelectedReserve] = useState(null);

  let {
    dashboardData,
    isLoading,
    error,
    filters,
    updateFilter,
    refetch: getData,
  } = useGetDashboard();

  const { details, refetch: getTourDetails } =
    useGetTourDetails(selectedTourID);

  const BASE_URL = useSelector((state) => state.BASE_URL);
  const features = useSelector((state) => state.features);
  const userFactory = useSelector((state) => state.user_factory);

  const ref = useRef();
  const sectionRef = useRef(null);
  const navigate = useNavigate();

  /******
   *
   * functions
   *
   ******/

  function formatAddress(address) {
    if (!address) {
      return 'Adresse non disponible';
    }

    const { address_number, street, city, zipcode } = address;

    const formattedStreet = address_number
      ? `${address_number} ${street}`
      : street;
    const formattedAddress = [formattedStreet, city, zipcode]
      .filter(Boolean)
      .join(', ');

    return formattedAddress || 'Adresse non disponible';
  }

  const handleTourModal = (selected_tour) => {
    setSelectedTour(selected_tour);
    setOpenTourModal(true);
  };

  /******
   *
   * Effects
   *
   ******/

  useEffect(() => {
    const token = localStorage.getItem('fleetToken');
    socket = io(process.env.REACT_APP_SOCKET_API_URL, {
      autoConnect: false,
      auth: {
        token,
      },
    });

    return () => {
      if (socket?.connected) {
        console.log('Déconnexion du serveur de sockets en cours...');
        socket.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    if (userFactory != undefined) {
      const factoryName = userFactory === '' ? 'ALL' : userFactory;

      socket.on('connect', () => {
        console.log(
          `Connecté au serveur de socket, et connexion à la room : ${factoryName}`
        );
        socket.emit('join_room', { factoryName });
      });

      socket.on('disconnect', () => {
        console.log('Déconnecté du serveur de sockets.');
      });

      socket.on('connect_error', (error) => {
        console.error('Erreur de connexion au socket:', error);
      });

      socket.on('refresh_dashboard', (data) => {
        console.log(
          "Réception de l'event refresh_dashboard, on re-get les datas"
        );
        const randomTimer = Math.floor(Math.random() * 1000);
        console.log(`Waiting for ${randomTimer}ms`);
        setTimeout(() => {
          getData();
        }, randomTimer);
      });

      socket.connect();
    }

    return () => {
      if (socket?.connected) {
        socket.off('connect');
        socket.off('connect_error');
        socket.off('refresh_dashboard');
      }
    };
  }, [userFactory]);

  useEffect(() => {
    setSelectedTour();
    setSelectedTourID();
  }, [openMapModal]);

  useEffect(() => {
    setSelectedTour(details);
    // Lorsque details est mis à jour, réinitialisez le scroll en haut de la balise section
    if (sectionRef.current) {
      sectionRef.current.scrollTop = 0;
    }
  }, [details]);

  useEffect(() => {
    selectedReserve && setModalHandleReserve(true);
  }, [selectedReserve]);

  // Handle the tour-item click
  useEffect(() => {
    // if a tour is selected, set it to the selected tour state
    if (selectedTourID) {
      null;
    } else {
      // else, set it to null
      setIsMapUpdating(true);
      setTimeout(() => {
        setSelectedTour(null);
        setIsMapUpdating(false);
      }, 100);
    }
  }, [dashboardData]);

  const computeTourTitle = useCallback(
    (tour) => {
      const tourTitlePart = [];

      if (tour?.vehicule_key) {
        tourTitlePart.push(`Véhicule ${tour.vehicule_key}`);
      }

      if (tour?.imat) {
        tourTitlePart.push(tour.imat);
      }

      if (tour?.label || tour?.vehicle_type) {
        if (tour?.label) {
          tourTitlePart.push(tour.label);
        } else {
          tourTitlePart.push(tour.vehicle_type);
        }
      }

      return tourTitlePart.join(' - ');
    },
    [dashboardData?.dashboardDatas?.vehicles?.tours]
  );

  const returnReserveSubtitle = (reserve) => {
    const stringParts = [];
    if (reserve?.tour_key) {
      const tourKey = reserve.tour_key;
      if (features?.tour_number_only_last_digit) {
        stringParts.push(`Tour ${tourKey.slice(-1)}`);
      } else {
        stringParts.push(`Tour ${tourKey}`);
      }
    }

    if (reserve?.vehicle_key) {
      stringParts.push(`Camion ${reserve.vehicle_key}`);
    }

    if (reserve?.receiver_name) {
      stringParts.push(`Client ${reserve?.receiver_name}`);
    }

    return stringParts.join(' - ');
  };

  /******
   *
   * Return
   *
   ******/
  return (
    <>
      <GenericPageContainer
        title={'Tour de contrôle'}
        page={'Dashboard'}
        subtitle={`Pilotez votre flotte de véhicules depuis cette page pour la date du ${filters?.date?.toLocaleDateString(
          'fr-FR',
          {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          }
        )}.`}
        filters={
          <>
            <div className="tw-relative">
              <ReactDatePicker
                selected={filters.date}
                onChange={(date) => updateFilter('date', date)}
                customInput={<CustomDatePickerDashboard />}
                locale="fr"
                dateFormat="dd MMM yyyy"
              />
            </div>
            <HeaderButton
              onClick={() =>
                window.open(
                  'https://form.asana.com/?k=DvvfONbVIPxRadxNzMdFhg&d=1204569106693047',
                  '_blank'
                )
              }
              disabled={!window.open}
              text={'Support'}
              icon={faHeadset}
            />
          </>
        }
      >
        {/* Nouveau contenu */}

        <div className=" tw-max-h-screen ">
          <div className="tw-container tw-mx-aut tw-grid tw-grid-flow-row tw-grid-cols-4 tw-grid-rows-1 tw-gap-4 tw-mb-4 tw-mt-10">
            <Kpi kpi_data={dashboardData?.dashboardDatas?.kpis?.[0]} />
            <Kpi kpi_data={dashboardData?.dashboardDatas?.kpis?.[1]} />
            <Kpi kpi_data={dashboardData?.dashboardDatas?.kpis?.[2]} />
            <Kpi kpi_data={dashboardData?.dashboardDatas?.kpis?.[3]} />
          </div>

          <div className="tw-container tw-mx-auto tw-h-[35vh] tw-overflow-hidden tw-grid tw-grid-flow-row tw-grid-cols-2 tw-grid-rows-1 tw-gap-4 tw-mb-4">
            <div className="tw-rounded tw-bg-white tw-overflow-y-auto">
              <div className="listHeader tw-p-5 tw-flex tw-justify-between ">
                <div className=" tw-font-semibold tw-text-xs">
                  Réserves non traitées :{' '}
                  {dashboardData?.dashboardDatas?.reserves_to_solve?.length}
                </div>
                <a
                  href="/reserves"
                  className=" tw-font-semibold tw-text-xs tw-underline tw-text-[#29A9A3]"
                >
                  Voir plus
                </a>
              </div>

              <div className="tw-rounded-md tw-bg-white tw-divide-solid tw-divide-x-0 tw-divide-y tw-divide-background tw-overflow-x-auto tw-cursor-pointer">
                {isLoading ? (
                  <GenericTableSkeleton />
                ) : (
                  dashboardData?.dashboardDatas?.reserves_to_solve?.map(
                    (reserve) => (
                      <div
                        onClick={() => setSelectedReserve(reserve)}
                        className=""
                        key={`reserve_${reserve?.id}`}
                      >
                        <Row
                          iconColor={'tw-text-checklist-common'}
                          key={`reserveRow_${reserve?.id}`}
                          title={reserve.message || 'Aucun message à afficher.'}
                          subtitle={`
                            Camion ${reserve?.vehicule_key ?? '?'} - Tour ${reserve?.tour_key?.slice(-1) ?? '?'} - Client : ${reserve?.receiver_name ?? '?'}
                          `}
                          icon={faClipboard}
                          status={
                            reserve?.status == 'CREATED'
                              ? STATUS_ENUM.WARN
                              : STATUS_ENUM.POSITIVE
                          }
                          statusText={
                            reserve?.status == 'CREATED'
                              ? 'A traiter'
                              : 'Résolue'
                          }
                          statusStyle={'tw-w-[90px]'}
                          fields={[
                            {
                              name: 'Type',
                              value:
                                reserves_types?.properties[
                                  reserve?.process_linked
                                ]?.display_name,
                            },
                          ]}
                        />
                      </div>
                    )
                  )
                )}
              </div>
            </div>

            <div className="  tw-rounded tw-bg-white tw-overflow-y-auto ">
              <div className="listHeader tw-p-5 tw-flex tw-justify-between ">
                <div className=" tw-font-semibold tw-text-xs">
                  {dashboardData?.dashboardDatas?.vehicles?.vehicle_count || 0}{' '}
                  Véhicules pour{' '}
                  {dashboardData?.dashboardDatas?.vehicles?.tours?.length || 0}{' '}
                  tournées :{' '}
                </div>
                <div
                  onClick={() => navigate('tours')}
                  className=" tw-font-semibold tw-cursor-pointer tw-text-xs tw-underline tw-text-[#29A9A3]"
                >
                  Voir plus
                </div>
              </div>

              <div className="tw-rounded-md tw-bg-white tw-divide-solid tw-divide-x-0 tw-divide-y tw-divide-background tw-overflow-x-auto ">
                {isLoading ? (
                  <GenericTableSkeleton />
                ) : (
                  dashboardData?.dashboardDatas?.vehicles?.tours?.map(
                    (tour) => (
                      <div
                        onClick={() =>
                          handleTourModal(tour?.current_or_next_tour)
                        }
                        className=""
                        key={`tour_${tour?.current_or_next_tour?.tour_key}`}
                      >
                        <Row
                          iconColor={'tw-text-checklist-common'}
                          key={`tourRow_${tour?.current_or_next_tour?.tour_key}`}
                          title={computeTourTitle(tour)}
                          subtitle={
                            'Tour ' +
                            tour?.current_or_next_tour?.tour_number +
                            ' - Étape ' +
                            tour?.delivered_steps_count +
                            ' sur ' +
                            tour?.total_steps_count
                          }
                          icon={faTruck}
                          // buttonText={'Voir'}
                          status={
                            tour_status?.properties[
                              tour?.current_or_next_tour?.status
                            ]?.color
                          }
                          statusText={
                            tour_status?.properties[
                              tour?.current_or_next_tour?.status
                            ]?.display_name
                          }
                          statusStyle={'tw-w-[90px]'}
                          style={'tw-cursor-pointer'}
                          fields={
                            tour?.current_or_next_tour?.tour_index
                              ? [
                                  {
                                    name: 'Tournée',
                                    value:
                                      tour?.current_or_next_tour?.tour_index +
                                      ' sur ' +
                                      tour?.tour_count,
                                  },
                                ]
                              : []
                          }
                        />
                      </div>
                    )
                  )
                )}
              </div>
            </div>
          </div>

          <div className="tw-container tw-mx-auto tw-h-[35vh] tw-row-auto tw-grid tw-grid-flow-row tw-grid-cols-4 tw-grid-rows-1 tw-gap-4">
            <div className="tw-rounded tw-bg-white tw-overflow-y-auto">
              <PieChartComponent
                pie_chart_data={
                  dashboardData?.dashboardDatas?.pie_chart_datas?.[0]
                }
                startAngle={630}
                endAngle={280}
                innerRadius={'50%'}
                fill="#8884d8"
                paddingAngle={5}
                hasTooltip={true}
              />
            </div>
            <div className="tw-rounded tw-bg-white tw-overflow-y-auto">
              <BarChartComponent
                bar_chart_data={
                  dashboardData?.dashboardDatas?.bar_chart_datas?.[0]
                }
              />
            </div>

            <div className=" tw-relative tw-box-border tw-p-2 tw-rounded tw-bg-white tw-col-span-2 tw-z-0">
              <div
                onClick={() => setOpenMapModal(true)}
                className=" tw-absolute tw-bg-white tw-rounded-md tw-top-6 tw-right-6 tw-z-10 tw-h-[40px] tw-w-[40px] tw-flex tw-items-center tw-justify-center tw-cursor-pointer"
              >
                <FontAwesomeIcon
                  icon={faExpand}
                  className=" tw-h-[20px] tw-w-[20px]"
                />
              </div>
              <Suspense
                fallback={
                  <div className="map-wrapper ">
                    <SkeletonLoader height={'100%'} width={'100%'} />
                  </div>
                }
              >
                {openMapModal ? null : (
                  <MapFleetDashboard
                    tours={dashboardData?.dashboardDatas?.map?.tours}
                    selectedTour={selectedTour}
                    setIsMapReady={setIsMapReady}
                  />
                )}
              </Suspense>
            </div>
          </div>
        </div>
      </GenericPageContainer>

      {/* Modale */}

      <ModalTour
        tourSelected={selectedTour}
        show={openTourModal}
        onHide={() => {
          setSelectedTour();
          setSelectedTourID();
          setOpenTourModal(false);
        }}
      />

      <ModalReserve
        returnReserveSubtitle={returnReserveSubtitle}
        show={modalHandleReserve}
        onHide={(isReserveUpdated) => {
          setModalHandleReserve(false);
          setSelectedReserve(null);
          if (isReserveUpdated) {
            getData();
          }
        }}
        reserve_id={selectedReserve?.id}
      />

      <Transition appear show={openMapModal}>
        <Dialog
          as="div"
          className="tw-relative tw-z-[100] focus:tw-outline-none tw-w-full tw-rounded-8xs tw-bg-white tw-box-border tw-flex tw-flex-col tw-items-start tw-justify-start tw-gap-[32px] tw-text-base tw-text-text-dark-high-importance tw-font-list-item-title border-[1px] tw-border-solid border-dimgray-100 tw-mq750:gap-[16px]"
          onClose={() => setOpenMapModal(false)}
        >
          <div
            className="tw-fixed tw-inset-0 tw-bg-black/40"
            aria-hidden="true"
          />
          <TransitionChild
            enter="tw-ease-out tw-duration-300"
            enterFrom="tw-opacity-0 tw-transform-[scale(95%)]"
            enterTo="tw-opacity-100 tw-transform-[scale(100%)]"
            leave="tw-ease-in tw-duration-200"
            leaveFrom="tw-opacity-100 tw-transform-[scale(100%)]"
            leaveTo="tw-opacity-0 tw-transform-[scale(95%)]"
          >
            <div className="tw-fixed tw-inset-0 tw-w-screen tw-overflow-y-auto tw-p-4 ">
              <div className="tw-flex tw-min-h-full tw-items-center tw-justify-center">
                <DialogPanel
                  className={`tw-flex tw-flex-col tw-max-h-[95vh] tw-w-full ${MODAL_SIZE_STYLE[MODAL_SIZE.XXXL]} tw-rounded tw-bg-white`}
                >
                  {/* Header */}
                  <div
                    className={`tw-flex tw-items-center tw-justify-between tw-py-3 tw-p-4 tw-border-solid tw-border-background tw-border-0 tw-border-b`}
                  >
                    <div className="tw-flex tw-font-semibold tw-text-base">
                      Répartition des{' '}
                      {dashboardData?.dashboardDatas?.map?.tours?.length}{' '}
                      tournées
                    </div>
                    <FontAwesomeIcon
                      onClick={() => setOpenMapModal(false)}
                      className="tw-size-5 tw-cursor-pointer tw-text-checklist-common"
                      icon={faTimes}
                    />
                  </div>
                  {/* Body */}
                  <div className={`tw-overflow-hidden tw-h-[850px]`}>
                    <div className=" tw-flex">
                      <div className="tw-rounded-md tw-bg-white tw-divide-solid tw-divide-x-0 tw-divide-y tw-divide-background tw-h-[850px] tw-min-w-[350px] tw-w-[350px] tw-overflow-y-scroll tw-overflow-x-hidden">
                        {isLoading ? (
                          <GenericTableSkeleton />
                        ) : (
                          dashboardData?.dashboardDatas?.map?.tours?.map(
                            (tour) => (
                              <div
                                className={`tw-flex tw-p-3 ${selectedTourID === tour?.tour_id ? 'tw-bg-[#07999218] tw-border tw-border-1 tw-border-[#079992] ' : null}`}
                                key={`tourMap_${tour?.tour_key}`}
                                onClick={() => setSelectedTourID(tour?.tour_id)}
                              >
                                <div className="tw-flex tw-items-center tw-justify-between tw-w-full">
                                  <div className="tw-flex  tw-items-center">
                                    <FontAwesomeIcon
                                      className={`tw-mr-2 tw-size-4`}
                                      icon={faRoute}
                                      color="#8B8B8B"
                                    />
                                    <div className="tw-max-w-xl">
                                      <div className="tw-text-smi tw-font-work tw-leading-4 tw-mb-1">
                                        {tour?.tour_key}
                                      </div>
                                      <div className="tw-text-smi tw-font-work tw-text-text-dark-medium-importance tw-leading-4 tw-line-clamp-1">
                                        {tour?.last_step_delivered_if_any?.order
                                          ? 'Étape ' +
                                            tour?.last_step_delivered_if_any
                                              ?.order
                                          : "A l'usine"}
                                      </div>
                                    </div>
                                  </div>
                                  <>
                                    <Status
                                      status={
                                        tour_status?.properties?.[tour.status]
                                          ?.color
                                      }
                                      text={
                                        tour_status?.properties?.[tour.status]
                                          ?.display_name
                                      }
                                      style={''}
                                    />
                                  </>
                                  <Checkbox
                                    checked={selectedTourID === tour?.tour_id}
                                    className="tw-group tw-block tw-size-4 tw-rounded tw-border tw-bg-white data-[checked]:tw-bg-[#079992]"
                                  >
                                    <span className="tw-invisible tw-size-2 tw-rounded-full tw-bg-white group-data-[checked]:tw-visible" />
                                  </Checkbox>
                                </div>
                              </div>
                            )
                          )
                        )}
                      </div>
                      <div className="tw-w-full tw-h-[850px]">
                        <Suspense
                          fallback={
                            <div className="map-wrapper ">
                              <SkeletonLoader height={'100%'} width={'100%'} />
                            </div>
                          }
                        >
                          <MapFleetDashboard
                            tours={dashboardData?.dashboardDatas?.map?.tours}
                            selectedTour={selectedTour}
                            setIsMapReady={setIsMapReady}
                            origin={'modal'}
                          />
                        </Suspense>
                      </div>

                      {selectedTourID ? (
                        <div
                          className=" tw-bg-white tw-h-[850px] tw-min-w-[400px] tw-w-[400px] tw-overflow-y-scroll tw-overflow-x-hidden"
                          ref={sectionRef}
                        >
                          <section className="tw-self-stretch tw-flex tw-flex-col tw-items-stretch tw-justify-start tw-gap-6 tw-p-5 tw-overflow-y-auto tw-flex-auto">
                            {/* main row */}
                            <div className=" tw-justify-start tw-max-w-full">
                              <div className="tw-items-start tw-justify-between tw-w-full mq750:tw-flex-wrap">
                                {/* left part */}
                                <div className=" tw-items-start tw-justify-start tw-px-0 tw-pb-0 tw-text-text-dark-high-importance">
                                  <div className=" tw-items-center tw-justify-start tw-shrink-0">
                                    <div className=" tw-items-start tw-justify-start tw-px-0 tw-pb-0">
                                      <div className="tw-w-6 tw-h-6 tw-relative tw-flex tw-items-center">
                                        <FontAwesomeIcon
                                          icon={faRoute}
                                          className="tw-text-icon-gray"
                                        />
                                      </div>
                                    </div>
                                    {/* header */}
                                    <div className="tw-items-start tw-justify-start tw">
                                      <div className="tw-relative tw-font-medium tw-font-work tw-text-sm">
                                        <span>
                                          Tour{' '}
                                          {selectedTour
                                            ? selectedTour?.tour_key
                                            : '-'}
                                        </span>
                                      </div>
                                      <div className="tw-items-start tw-justify-start tw-gap-[8.5px] tw-text-smi tw-text-gray-600">
                                        <div className="list-item-title tw-text-gray-600">
                                          {features?.tour_creation_date_used
                                            ? selectedTour?.creation_date
                                              ? `Chargée à ${moment(selectedTour?.creation_date).format('HH[h]mm')}`
                                              : '-'
                                            : selectedTour?.starting_date
                                              ? `Départ à ${moment(selectedTour?.starting_date).format('HH[h]mm')}`
                                              : '-'}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className=" tw-items-center tw-justify-start tw-gap-4 tw-shrink-0 tw-max-w-full tw-text-smi mq450:tw-flex-wrap">
                                  {/* key and value */}
                                  <div className="tw-flex tw-flex-col tw-items-start tw-justify-start tw-gap-0.5">
                                    <div className="list-item-title tw-text-text-dark-high-importance">
                                      Kilomètres de départ
                                    </div>
                                    <div className="list-item-title tw-text-gray-600">
                                      {selectedTour?.last_kilometers
                                        ? selectedTour.last_kilometers
                                        : '0'}{' '}
                                      km
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* Separator */}
                            <div className="tw-h-[1px] tw-w-full tw-bg-black tw-opacity-20" />

                            {/* Scrollable content */}
                            <div className="tw-pb-16 tw-gap-6 tw-overflow-y-auto tw-flex tw-flex-col tw-items-stretch tw-justify-start tw-relative tw-max-w-full tw-text-smi tw-text-text-dark-high-importance">
                              {/* tour steps block */}
                              <div className="tw-flex tw-flex-col tw-items-start tw-justify-start tw-max-w-full tw-text-gray-600">
                                {/* section header */}
                                <div className="tw-flex tw-flex-col tw-pb-6 tw-text-base tw-text-text-dark-high-importance">
                                  <div className="tw-relative tw-font-medium">
                                    Liste des étapes
                                  </div>
                                  <div className="tw-relative tw-text-smi tw-leading-[15px] tw-text-text-dark-medium-importance">
                                    Retrouvez la liste des étapes du tour
                                  </div>
                                </div>
                                {/* section table header */}

                                {/* section content */}
                                {details?.steps?.map((step) => {
                                  return (
                                    <div
                                      key={`_step_${step.step_id}`}
                                      className="tw-self-stretch tw-flex tw-flex-col tw-items-end tw-justify-start tw-pt-4 tw-px-0 tw-pb-4 tw-gap-4 tw-shrink-0 tw-max-w-full tw-text-sm tw-border-solid tw-border-background tw-border-0 tw-border-b"
                                    >
                                      {/* main step row */}
                                      <div className="tw-self-stretch tw-flex tw-flex-row tw-items-start tw-justify-start [row-gap:20px] tw-max-w-full tw-text-right tw-text-smi tw-text-text-dark-high-importance">
                                        <div className="tw-flex-1 tw-overflow-hidden tw-flex tw-flex-col tw-items-start tw-justify-start tw-gap-[2.1px] tw-min-w-[150px] tw-max-w-full tw-text-left">
                                          <div className="tw-flex tw-flex-row tw-items-start tw-justify-start tw-gap-[16px] tw-text-sm">
                                            <div className="tw-flex tw-flex-row tw-items-start tw-justify-start">
                                              <div className="tw-h-4 tw-w-[50px] tw-relative">
                                                <div
                                                  className={`tw-w-[25px] tw-h-[25px] tw-font-bold tw-bg-[#079992] tw-text-white tw-flex tw-justify-center tw-items-center tw-rounded-full`}
                                                >
                                                  {step.order}
                                                </div>
                                                {/* <FontAwesomeIcon
                                            icon={faTruck}
                                            className="tw-text-icon-gray tw-absolute tw-top-0 tw-left-0 tw-w-6 tw-h-6 tw-text-2xl"
                                          /> */}
                                              </div>
                                              <div className="tw-flex tw-flex-col tw-items-start tw-justify-start">
                                                <div className="tw-relative tw-font-semibold tw-flex tw-gap-4 tw-text-sm">
                                                  <div className="">
                                                    {step?.step_key
                                                      ? `Étape ${step.step_key}`
                                                      : 'STEP_NAME'}
                                                  </div>
                                                </div>
                                                <div className="list-item-title">
                                                  {formatAddress(
                                                    step?.end_address
                                                  )}
                                                </div>

                                                <div className="tw-flex-1 tw-flex tw-flex-row tw-items-start tw-justify-start [row-gap:20px] tw-text-xs ">
                                                  <div className="tw-flex-1 tw-relative tw-text-smi tw-tracking-[-0.02em] tw-text-text-dark-high-importance tw-min-w-[129px] tw-shrink-0">
                                                    Kilomètres saisis :{' '}
                                                    <span className="tw-font-semibold">
                                                      {step?.inputted_kilometers
                                                        ? step.inputted_kilometers +
                                                          ' km'
                                                        : 'nc.'}
                                                    </span>
                                                  </div>
                                                </div>

                                                <div className="tw-flex-1 tw-flex tw-flex-col tw-items-start tw-justify-start">
                                                  <div className="tw-flex-1 tw-relative tw-text-smi tw-tracking-[-0.02em] tw-text-text-dark-high-importance tw-min-w-[129px] tw-shrink-0 tw-w-full">
                                                    Arrivée et Départ :{' '}
                                                    <span className="tw-font-semibold">
                                                      {step?.arrival_date
                                                        ? moment(
                                                            step?.arrival_date
                                                          ).format('HH[h]mm')
                                                        : 'nc.'}{' '}
                                                      -{' '}
                                                      {step?.departure_date
                                                        ? moment(
                                                            step?.departure_date
                                                          ).format('HH[h]mm')
                                                        : 'nc.'}
                                                    </span>
                                                  </div>
                                                </div>

                                                <div className="tw-flex-1 tw-flex tw-flex-col tw-items-end tw-justify-start tw-gap-[4px] tw-text-center tw-text-xs tw-text-text-dark-medium-importance">
                                                  <Status
                                                    status={
                                                      step_status.properties[
                                                        step.status
                                                      ].style
                                                    }
                                                    text={
                                                      step_status.properties[
                                                        step.status
                                                      ]?.display_name
                                                    }
                                                    style={'tw-my-1'}
                                                  />

                                                  {step?.need_to_disinfect ? (
                                                    <div className="tw-rounded-3xs tw-bg-goldenrod tw-flex tw-flex-row tw-items-start tw-justify-start tw-py-px tw-px-[7px] tw-whitespace-nowrap tw-text-black tw-border-[1px] tw-border-solid tw-border-dimgray-200">
                                                      <div className="tw-relative tw-font-medium tw-inline-block ">
                                                        Risque sanitaire
                                                      </div>
                                                    </div>
                                                  ) : null}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      {/* comments */}
                                      <div className="tw-flex tw-flex-col tw-pl-[50px] tw-items-start tw-justify-start tw-gap-3 tw-w-full">
                                        <div className="tw-relative tw-tracking-[-0.02em] tw-font-medium">
                                          Commentaire d’étape
                                        </div>
                                        <div className="tw-self-stretch tw-rounded-[3px] tw-bg-whitesmoke-100 tw-box-border tw-flex tw-flex-row tw-flex-wrap tw-items-center tw-justify-start tw-py-3.5 tw-pr-3.5 tw-pl-4 tw-gap-[16px] tw-max-w-full tw-text-smi tw-text-text-dark-medium-importance tw-border-[1px] tw-border-solid tw-border-border-primary-strong">
                                          <div className="tw-flex tw-flex-col tw-items-start tw-justify-start ">
                                            <FontAwesomeIcon
                                              icon={faComment}
                                              className="tw-text-icon-gray tw-w-4 tw-h-4"
                                            />
                                          </div>
                                          <p className="tw-flex-1 tw-relative tw-tracking-[-0.02em] tw-mb-0 tw-leÉing-4">
                                            {step?.comments
                                              ? step.comments
                                              : 'Pas de commentaire pour cette étape'}
                                          </p>
                                        </div>
                                      </div>
                                      <div className="tw-flex tw-flex-col tw-pl-[50px] tw-items-start tw-justify-start tw-gap-3 tw-w-full">
                                        <div className="tw-relative tw-tracking-[-0.02em] tw-font-medium">
                                          Numéros de commande
                                        </div>
                                        <div className="tw-w-full tw-flex tw-flex-col tw-gap-3">
                                          {step?.bls?.map((bl) => {
                                            const IconComponent =
                                              DELIVERY_DETAIL_TYPE.properties[
                                                bl?.delivery_type
                                              ].icon;

                                            return (
                                              <div
                                                key={`${bl.delivery_detail_id}`}
                                                className="tw-self-stretch tw-rounded-10xs tw-bg-whitesmoke-100 tw-box-border tw-flex tw-flex-row tw-flex-wrap tw-items-start tw-justify-between tw-py-2 tw-pr-[17px] tw-pl-[15px] tw-max-w-full tw-gap-[20px] tw-text-smi tw-text-text-dark-high-importance tw-border-[1px] tw-border-solid tw-border-border-primary-strong"
                                              >
                                                <div className="tw-flex tw-flex-row tw-items-start tw-justify-start tw-gap-2">
                                                  {/* <div className="tw-flex tw-flex-col tw-items-start tw-justify-start tw-pt-2 tw-px-0 tw-pb-0">
                                                <FontAwesomeIcon
                                                  icon={faTruck}
                                                  className="tw-text-icon-gray tw-w-6 tw-h-5"
                                                />
                                              </div> */}
                                                  <IconComponent className="tw-text-icon-gray tw-w-8 tw-h-8" />
                                                  <div className="tw-flex tw-flex-col tw-items-start tw-justify-start tw-gap-3">
                                                    <div className="tw-flex tw-flex-row tw-items-start">
                                                      <div className="tw-flex tw-flex-row tw-items-start tw-justify-start tw-gap-3">
                                                        <div className="tw-flex tw-flex-col tw-items-start tw-justify-start tw-gap-0.5">
                                                          <div className="tw-relative tw-font-medium tw-text-sm">
                                                            {bl?.delivery_detail_key
                                                              ? `Commande ${bl.delivery_detail_key}`
                                                              : ' nc.'}
                                                          </div>
                                                          <div className="tw-relative tw-text-text-dark-medium-importance list-item-title">
                                                            {step?.end_address
                                                              ?.name
                                                              ? step
                                                                  ?.end_address
                                                                  ?.name
                                                              : 'nc.'}
                                                          </div>
                                                        </div>
                                                        <div className="tw-h-[33px] tw-flex tw-flex-col tw-items-center tw-justify-start">
                                                          <img
                                                            className="tw-w-[13.6px] tw-h-[13.5px] tw-relative"
                                                            loading="lazy"
                                                            alt=""
                                                            src="/icon-akartrianglealertfill.svg"
                                                          />
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div className="tw-flex tw-flex-col tw-items-start tw-justify-start tw-gap-[1px] tw-text-right tw-text-xs tw-text-primary">
                                                      {bl?.documents?.map?.(
                                                        (el) => (
                                                          <div
                                                            key={`el_${el.id}`}
                                                            className="tw-relative [text-decoration:underline] tw-tracking-[0.02em]"
                                                          >
                                                            <a
                                                              href={el.url}
                                                              target="_blank"
                                                              rel="noreferrer"
                                                            >
                                                              Télécharger la{' '}
                                                              {el?.doc_type
                                                                ? DOCUMENT_TYPE
                                                                    .properties[
                                                                    el?.doc_type
                                                                  ]
                                                                    ?.display_name
                                                                : 'DOCUMENT_TYPE'}
                                                            </a>
                                                          </div>
                                                        )
                                                      )}
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="tw-flex tw-flex-row tw-items-center tw-justify-start tw-align-center  tw-w-full tw-gap-4">
                                                  <div className="tw-h-[53px] tw-w-[1px] tw-relative tw-bg-black tw-opacity-5" />
                                                  {/* ddd */}
                                                  <div className="tw-flex tw-flex-col tw-gap-1.5 tw-align-stretch tw-w-full">
                                                    {bl?.items?.map((item) => (
                                                      <>
                                                        {computeRowForOneDeliveryDetail(
                                                          bl,
                                                          item
                                                        )?.map?.((row) => (
                                                          <div
                                                            key={`${row.what}_${row.where}`}
                                                            className="tw-flex tw-flex-row tw-gap-[5px]  tw-text-black tw-items-center tw-justify-start tw-align-center tw-grow"
                                                          >
                                                            <AgrivitechCheckbox
                                                              isChecked={
                                                                row.checked
                                                              }
                                                              initialValue={
                                                                bl.status
                                                              }
                                                            />
                                                            <div className="tw-rounded-8xs tw-w-full tw-bg-gainsboro-200 tw-overflow-x-auto tw-flex tw-flex-row tw-items-center tw-justify-between tw-py-0.5 tw-px-8 tw-gap-[20px]">
                                                              <div className="tw-relative tw-tracking-[0.02em] tw-inline-block tw-min-w-[45px]">
                                                                {row.what}
                                                              </div>
                                                              <div
                                                                className="polygon"
                                                                style={{
                                                                  ...(row.invert_arrow && {
                                                                    transform:
                                                                      'rotate(270deg)',
                                                                  }),
                                                                }}
                                                              ></div>
                                                              <div className="tw-relative tw-tracking-[0.02em] tw-inline-block tw-min-w-[37px] tw-text-text-dark-high-importance">
                                                                {!row.where_substitute ? (
                                                                  <span className="tw-text-black">
                                                                    {row.where}
                                                                  </span>
                                                                ) : null}

                                                                {row.where_substitute && (
                                                                  <span className="tw-text-black">
                                                                    <span className="tw-line-through  tw-text-black/60">
                                                                      {
                                                                        row.where
                                                                      }
                                                                    </span>{' '}
                                                                    <span className=" tw-font-semibold">
                                                                      {
                                                                        row.where_substitute
                                                                      }
                                                                    </span>
                                                                  </span>
                                                                )}
                                                              </div>
                                                            </div>
                                                          </div>
                                                        ))}
                                                        {/* product row */}
                                                        <div className="tw-self-stretch tw-flex tw-flex-row tw-items-center tw-justify-between tw-text-text-dark-medium-importance">
                                                          <div className="tw-flex tw-flex-row tw-gap-2">
                                                            <AlimentTypeTag
                                                              item={
                                                                Object.values(
                                                                  ALIMENT_TYPE_ENUM
                                                                ).includes(
                                                                  item?.type?.toUpperCase?.()
                                                                )
                                                                  ? item.type.toUpperCase()
                                                                  : ALIMENT_TYPE_ENUM.AUTRES
                                                              }
                                                            />
                                                            {item?.name
                                                              ? item.name
                                                              : 'PRODUCT_NAME'}
                                                          </div>

                                                          <div className="h-[15px] w-[29px] relative text-right inline-block">
                                                            {item?.net_weight
                                                              ? `${item.net_weight} t.`
                                                              : 'nc.'}
                                                          </div>
                                                        </div>
                                                      </>
                                                    ))}
                                                  </div>
                                                </div>
                                              </div>
                                            );
                                          })}
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>

                              {/* tour comments block */}
                              <div className="tw-self-stretch tw-flex tw-flex-col tw-items-start tw-justify-start tw-gap-[33px] tw-shrink-0 [debug_commit:bf4bc93] tw-max-w-full tw-text-base mq750:tw-gap-[16px]">
                                {/* section header */}
                                <div className="tw-flex tw-flex-col tw-pb-0 tw-text-base tw-text-text-dark-high-importance">
                                  <div className="tw-relative tw-font-medium">
                                    Informations supplémentaires sur la tournée
                                  </div>
                                  <div className="tw-relative tw-text-smi tw-leading-[15px] tw-text-text-dark-medium-importance">
                                    Documents de fin de tournée et commentaires
                                    de par l'équipe de planification.
                                  </div>
                                </div>
                                {/* comments */}
                                <div className="tw-flex tw-flex-col tw-w-full tw-gap-2">
                                  <div className="tw-self-stretch tw-rounded-[3px] tw-bg-whitesmoke-100 tw-box-border tw-flex tw-flex-row tw-flex-wrap tw-items-start tw-justify-start tw-py-3.5 tw-pr-3.5 tw-pl-4 tw-gap-[16px] tw-max-w-full tw-text-smi tw-text-text-dark-medium-importance tw-border-[1px] tw-border-solid tw-border-border-primary-strong">
                                    <div className="tw-flex tw-flex-col tw-items-start tw-justify-start ">
                                      <FontAwesomeIcon
                                        icon={faComment}
                                        className="tw-text-icon-gray tw-w-4 tw-h-4"
                                      />
                                    </div>
                                    <p className="tw-flex-1 tw-relative tw-tracking-[-0.02em] tw-mb-0 tw-leading-4">
                                      {details?.tour_comments
                                        ? details.tour_comments
                                        : 'Pas de commentaire pour cette tournée'}
                                    </p>
                                  </div>
                                  {/* comments */}
                                  <div className="tw-self-stretch tw-rounded-[3px] tw-bg-whitesmoke-100 tw-box-border tw-flex tw-flex-row tw-flex-wrap tw-items-start tw-justify-start tw-py-3.5 tw-pr-3.5 tw-pl-4 tw-gap-[16px] tw-max-w-full tw-text-smi tw-text-text-dark-medium-importance tw-border-[1px] tw-border-solid tw-border-border-primary-strong">
                                    <div className="tw-flex tw-flex-col tw-items-start tw-justify-start ">
                                      <FontAwesomeIcon
                                        icon={faClipboard}
                                        className="tw-text-icon-gray tw-w-4 tw-h-4"
                                      />
                                    </div>
                                    <p className="tw-flex-1 tw-relative tw-tracking-[-0.02em] tw-mb-0 tw-leading-4">
                                      {details?.tour_documents.length
                                        ? details.tour_documents?.map((el) => (
                                            <div
                                              key={`el_${el.id}`}
                                              className="tw-relative [text-decoration:underline] tw-tracking-[0.02em]"
                                            >
                                              <a
                                                href={el.url}
                                                target="_blank"
                                                rel="noreferrer"
                                              >
                                                Télécharger la{' '}
                                                {el?.doc_type
                                                  ? DOCUMENT_TYPE.properties[
                                                      el.doc_type
                                                    ]?.display_name
                                                  : 'DOCUMENT_TYPE'}
                                              </a>
                                            </div>
                                          ))
                                        : 'Pas de documents pour cette tournée'}
                                    </p>
                                  </div>
                                </div>
                              </div>

                              {/* Localisation des étapes block */}
                              {false && (
                                <div className="tw-flex tw-flex-col tw-pb-0 tw-text-base tw-text-text-dark-high-importance">
                                  {/* section header */}
                                  <div className="tw-flex tw-flex-col tw-pb-6 tw-text-base tw-text-text-dark-high-importance">
                                    <div className="tw-relative tw-font-medium">
                                      Localisation des étapes
                                    </div>
                                    <div className="tw-relative tw-text-smi tw-leading-[15px] tw-text-text-dark-medium-importance">
                                      Localisation des étapes de la tournées sur
                                      la carte
                                    </div>
                                  </div>
                                  {/* map */}
                                  <div className="tw-self-stretch tw-h-[300px] tw-rounded-3xs tw-bg-white tw-box-border tw-overflow-x-auto tw-shrink-0 tw-flex tw-flex-row tw-items-start tw-justify-start tw-pt-[68.9px] tw-px-[387px] tw-pb-[61px] tw-gap-[98.9px] tw-text-smi tw-text-white tw-font-montserrat tw-border-[1px] tw-border-solid tw-border-border-primary-strong mq1150:tw-gap-[49px] mq1150:tw-pl-[193px] mq1150:tw-pr-[193px] tw-box-border mq450:tw-pl-5 mq450:tw-pr-5 tw-box-border mq750:tw-gap-[25px] mq750:tw-pl-24 mq750:tw-pr-24">
                                    <div className="tw-h-[300px] tw-w-[1250px] tw-relative tw-rounded-3xs tw-bg-white tw-box-border tw-shrink-0 tw-hidden tw-border-[1px] tw-border-solid tw-border-border-primary-strong" />
                                    <img
                                      className="tw-h-[272.9px] tw-w-[1226.5px] tw-relative tw-shrink-0 tw-object-contain tw-hidden"
                                      alt=""
                                      src="/groupe-de-masques-10@2x.png"
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                          </section>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  {/* Footer */}
                </DialogPanel>
              </div>
            </div>
          </TransitionChild>
        </Dialog>
      </Transition>
    </>
  );
};

export default HomeV3;
