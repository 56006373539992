import React, { useCallback, useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import './modal-config.scss';

const ModalConfig = (props) => {
  const [maxKmPerTour, setMaxKmPerTour] = useState(0);
  const [averageStopTime, setAverageStopTime] = useState(0);
  const [departTime, setDepartTime] = useState('08:00');
  const [maxDuration, setMaxDuration] = useState('08:00');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const getConfig = useCallback(async () => {
    setIsLoading(true);

    const url = new URL(props.BASE_URL + '/Config/client');
    const token = localStorage.getItem('fleetToken');

    let myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Authorization', token);
    let myInit = { method: 'GET', headers: myHeaders, cache: 'no-cache' };

    console.log('GET request @ ', props.BASE_URL + '/Config/client');

    fetch(url, myInit)
      .then((response) => {
        if (response.ok) {
          response.json().then((result) => {
            if (result.status === 'Success') {
              const config = result?.clientConfig ?? [];
              if (config) {
                let objectAverageStopTime =
                  config?.filter(
                    (object) =>
                      object['config_name'] === 'AVERAGE_TIME_PER_STOP'
                  )?.[0]?.config_value ?? 0;
                setAverageStopTime(objectAverageStopTime);

                let objectMaxKmPerTourValue =
                  config?.filter(
                    (object) => object['config_name'] === 'MAX_KM_FOR_A_TOUR'
                  )?.[0]?.config_value ?? 0;
                setMaxKmPerTour(objectMaxKmPerTourValue);

                let objectDepartTime =
                  config?.filter(
                    (object) => object['config_name'] === 'DEFAULT_DEPART_TIME'
                  )?.[0]?.config_value ?? '08:00';
                setDepartTime(objectDepartTime);

                let objectMaxDuration =
                  config?.filter(
                    (object) => object['config_name'] === 'TOUR_MAX_DURATION'
                  )?.[0]?.config_value ?? '08:00';
                setMaxDuration(objectMaxDuration);
              }
              setIsLoading(false);
              return;
            } else {
              throw new Error('Success == false'); // rejects the promise
            }
          });
        } else {
          throw new Error('Error from network'); // rejects the promise
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(
          "Il y a eu un problème avec l'opération fetch: " + error.message
        );
      });
  }, [props.BASE_URL]);

  const postConfig = () => {
    const url = new URL(props.BASE_URL + '/Config/client');
    const token = localStorage.getItem('fleetToken');

    let myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Authorization', token);
    let myInit = {
      method: 'POST',
      headers: myHeaders,
      cache: 'no-cache',
      body: JSON.stringify({
        configs: [
          {
            config_name: 'AVERAGE_TIME_PER_STOP',
            config_value: averageStopTime,
          },
          {
            config_name: 'MAX_KM_FOR_A_TOUR',
            config_value: maxKmPerTour,
          },
          {
            config_name: 'DEFAULT_DEPART_TIME',
            config_value: departTime,
          },
          {
            config_name: 'TOUR_MAX_DURATION',
            config_value: maxDuration,
          },
        ],
      }),
    };

    console.log('POST request @ ', props.BASE_URL + '/Config/client');

    fetch(url, myInit)
      .then((response) => {
        if (response.ok) {
          setIsLoading(false);
          props.onHide();
          setError(null);
        } else {
          setIsLoading(false);
          setError('Une erreur est survenue');
          response.json().then(function (result) {
            console.log('result', result);
          });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(
          "Il y a eu un problème avec l'opération fetch: " + error.message
        );
      });
  };
  return (
    <Modal size="lg" {...props} onShow={() => getConfig()}>
      <div className="background-modal-fleet-config" />
      <Modal.Header className="modal-config-header" closeButton>
        <div className="claim-header-inner-modal-config">
          <div>
            <h5>Configurations globales</h5>
            <p>Gerez les configurations globales de votre flotte</p>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body className="modal-config-body">
        <span className="modal-span-title">
          Heure du démarrage de la tournée
        </span>
        <br />
        <input
          type="time"
          className="modal-input"
          value={departTime}
          onChange={(e) => setDepartTime(e.target.value)}
          defaultValue="08:00"
          required
        />
        <br />
        <span className="modal-span-title">Durée maximale d'une tournée</span>
        <br />
        <input
          type="time"
          className="modal-input"
          value={maxDuration}
          onChange={(e) => setMaxDuration(e.target.value)}
          defaultValue="08:00"
          required
        />
        <br />
        <span className="modal-span-title">
          Temps moyen par stop (en minutes)
        </span>
        <br />
        <input
          placeholder="17"
          type="number"
          className="modal-input"
          min="0"
          value={averageStopTime}
          onChange={(e) => setAverageStopTime(e.target.value)}
        />
        <br />
        <span className="modal-span-title">
          Nombre de km max pour une tournée
        </span>
        <br />
        <input
          placeholder="30"
          type="number"
          className="modal-input"
          min="0"
          value={maxKmPerTour}
          onChange={(e) => setMaxKmPerTour(e.target.value)}
        />
        <br />
        <span className="modal-span-title">
          Mode de finalisation des tournées
        </span>
        <br />
        <Row>
          <Col lg={6} className="modal-col-left">
            <div className="modal-div-background-btn d-flex jusitfy-content-between">
              <Button variant="" className="modal-btn">
                Rapide
              </Button>
              <Button variant="" disabled className="modal-btn-desactive">
                Complet
              </Button>
            </div>
          </Col>
          <Col
            lg={1}
            className="modal-col-middle d-flex justify-content-center"
          >
            <div className="modal-separator" />
          </Col>

          <Col lg={5} className="modal-col-right">
            <p className="modal-small-text">
              L'algorithme génère la tournée pour tous les BLs, tout les jours
              même si les camions ne sont pas remplis.
            </p>
          </Col>
        </Row>
      </Modal.Body>
      <div>
        <p className="msg-error-modal-config text-center">{error}</p>
      </div>
      <Modal.Footer className="modal-config-footer mx-auto">
        <Button
          variant="secondary"
          className="modal-btn-footer"
          onClick={postConfig}
          disabled={!averageStopTime || !maxKmPerTour || isLoading}
        >
          {isLoading ? 'Chargement...' : 'Enregistrer'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalConfig;
