import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import GenericModal from '../../components/agv-generic-modal';
import InputCombobox from '../../components/agv-forms-combobox/input-combobox';
import InputRadio from '../../components/agv-forms-radio';
import InputText from '../../components/agv-forms-input';
import { ROLE_ENUM, ROLE_ENUM_DISPLAY } from '../../enums/role-enum.enum';
import isEmailValid from '../../utils/is-email-valid.util';
import isPhoneNumberValid from '../../utils/is-phone-number-valid.util';

export default function CreateUserModal({
  isOpen,
  setIsOpen,
  createUser,
  mainActionIsLoading,
  clientsData,
}) {
  const [clientId, setClientId] = useState(null);
  const [factory, setFactory] = useState(null);
  const [lastname, setLastname] = useState('');
  const [firstname, setFirstname] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [role, setRole] = useState('ADMIN');
  const [clientFactories, setClientFactories] = useState([]);

  const config = useSelector((state) => state.config);

  const DISABLED =
    !isEmailValid(email) ||
    !isPhoneNumberValid(phone) ||
    (clientFactories?.length > 0 && !factory) ||
    !lastname.length ||
    !firstname.length ||
    (config?.is_super_admin ? !clientId : false);

  useEffect(() => {
    if (!isOpen) {
      setClientId(null);
      setFactory(null);
      setLastname('');
      setFirstname('');
      setEmail('');
      setPhone('');
      setRole('ADMIN');
    }
  }, [isOpen]);

  useEffect(() => {
    const factories = clientsData?.filter(
      (clientData) => clientData?.client_id === clientId
    )?.[0]?.factories;
    setClientFactories(factories);
  }, [clientsData, clientId]);

  return (
    <GenericModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title={'Créer un utilisateur'}
      mainAction={() =>
        createUser(
          config?.is_super_admin ? clientId : null,
          firstname,
          lastname,
          email,
          phone,
          factory,
          role
        )
      }
      mainActionText={'Créer'}
      mainActionIsLoading={mainActionIsLoading}
      mainActionDisabled={DISABLED}
    >
      <div className="tw-flex tw-flex-col tw-pb-6">
        {config?.is_super_admin && (
          <InputCombobox
            value={clientId}
            setValue={setClientId}
            fieldTitle={'ID Client'}
            items={clientsData?.map((client) => ({
              name: `${client.client_id} - ${client.name}`,
              value: client.client_id,
            }))}
            isRequired
            emptyError={`Le champ "Client ID" ne peut être vide.`}
            style={'tw-mt-6'}
          />
        )}
        {clientFactories && clientFactories.length > 0 && (
          <InputCombobox
            value={factory}
            setValue={setFactory}
            fieldTitle={'Usine'}
            items={clientFactories?.map((element) => ({
              name: element.name,
              value: element.id,
            }))}
            isRequired
            emptyError={
              clientFactories && clientFactories.length > 0
                ? `Le champ "Usine" ne peut être vide.`
                : ''
            }
            style={'tw-mt-6'}
            isDisabled={
              config?.is_super_admin
                ? !clientId || !clientFactories || !clientFactories?.length
                : false
            }
          />
        )}
        <InputText
          value={lastname}
          setValue={setLastname}
          fieldTitle={'Nom'}
          isRequired
          emptyError={`Le champ "Nom" ne peut être vide.`}
          style={'tw-mt-6'}
          placeholder={'Leblanc'}
        />
        <InputText
          value={firstname}
          setValue={setFirstname}
          fieldTitle={'Prénom'}
          isRequired
          emptyError={`Le champ "Prénom" ne peut être vide.`}
          style={'tw-mt-6'}
          placeholder={'Juste'}
        />
        <InputText
          value={email}
          setValue={setEmail}
          fieldTitle={'E-mail'}
          isRequired
          emptyError={`Le champ "E-mail" ne peut être vide.`}
          style={'tw-mt-6'}
          placeholder={'exemple@exemple.com'}
          isBadFormat={!isEmailValid(email)}
          type="email"
        />
        <InputText
          value={phone}
          setValue={setPhone}
          fieldTitle={'Téléphone'}
          isRequired
          emptyError={`Le champ "Téléphone" ne peut être vide.`}
          style={'tw-mt-6'}
          placeholder={'0612345678'}
          isBadFormat={!isPhoneNumberValid(phone)}
          type="tel"
          maxLength={10}
        />
        <InputRadio
          fieldTitle={'Rôle'}
          value={role}
          setValue={setRole}
          style={'tw-mt-6'}
          items={[
            {
              value: ROLE_ENUM.ADMIN,
              name: ROLE_ENUM_DISPLAY[ROLE_ENUM.ADMIN],
            },
            {
              value: ROLE_ENUM.OPERATOR,
              name: ROLE_ENUM_DISPLAY[ROLE_ENUM.OPERATOR],
            },
          ]}
        />
      </div>
    </GenericModal>
  );
}
