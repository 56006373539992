import { STATUS_ENUM } from '../components/agv-status/status-enum';

export const tour_status = {
  ALL: 'ALL',
  DONE: 'DONE',
  LATE: 'LATE',
  ISSUE: 'ISSUE',
  CREATED: 'CREATED',
  STARTED: 'STARTED',
  IMPORTED: 'IMPORTED',
  properties: {
    ALL: { display_name: 'Toutes', color: '#079992' },
    DONE: { display_name: 'Terminée', color: STATUS_ENUM.POSITIVE },
    LATE: { display_name: 'En retard', color: STATUS_ENUM.WARN },
    ISSUE: { display_name: 'Problème', color: STATUS_ENUM.WARN },
    IMPORTED: { display_name: 'Non validé', color: STATUS_ENUM.WARN },
    STARTED: { display_name: 'Démarrée', color: STATUS_ENUM.INFO },
    CREATED: { display_name: 'Chargé', color: STATUS_ENUM.NEUTRAL },
  },
};
