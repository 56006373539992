import React from 'react';
import { Link, useLocation } from 'react-router-dom';

export default function Section({ title, items }) {
  const location = useLocation();

  return (
    <div className="tw-mt-8 tw-px-2">
      <div className="tw-border-solid tw-border-0 tw-border-b tw-border-white/50 tw-pb-3">
        <div className="tw-text-[11px] tw-leading-[13px] tw-text-text-light-high-importance tw-font-work tw-font-medium tw-mb-3 tw-ml-2">
          {title}
        </div>
        {items.map((item, index) => {
          return (
            <Link
              key={`${item.to}${index}`}
              className="tw-no-underline"
              to={item.to}
            >
              <div
                className={`tw-rounded-md tw-cursor-pointer tw-mb-[3px] tw-px-3 tw-py-2 ${location.pathname === item.to ? 'tw-bg-main-active' : 'hover:tw-bg-main-hover'}`}
              >
                <div className="tw-text-sm tw-font-work tw-text-text-light-high-importance">
                  {item.title}
                </div>
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
}
