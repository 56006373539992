import React from 'react';

import { faWarning } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';

import truck from '../../../assets/images/icons/__OLD__/truck.png';
import BadgeColor from '../platformColorBadge';
import './tour-item-fleet-management.scss';

const RenderTooltip = (props) => (
  <Tooltip id="button-tooltip" {...props}>
    Terminez la configuration du véhicule en cliquant ici pour pouvoir
    l'utiliser dans l'optimisation.
  </Tooltip>
);

const RenderTooltipDesactive = (props) => (
  <Tooltip id="button-tooltip" {...props}>
    Active/désactive le véhicule pour l'optimisation
  </Tooltip>
);

const RenderTooltipOpti = (props) => (
  <Tooltip id="button-tooltip" {...props}>
    Le véhicule est désactivé pour l'optimisation
  </Tooltip>
);

const TourItemFleetManagement = (props) => {
  return (
    <div
      className={`fleet-item-tour-management ${
        props.isFleetEditing ? 'animation-edition' : ''
      }`}
      onClick={() =>
        !props.isFleetEditing ? props.handleEditVehicle(props.vehicle) : null
      }
    >
      <div as={Card.Header} className={`fleet-first-line-tour-management`}>
        <div className={'hw'}>
          <img src={truck} className={'img-illustration-truck'} alt={'truck'} />
        </div>
        <div className={'fr'}>
          {props.vehicle.starting_platform_id ? (
            <BadgeColor
              color={props.vehicle.color || '#000000'}
              platform={props.vehicle.starting_platform_id}
            />
          ) : (
            <span className="fleet-item-title-id-none">PLATEFORME N/A</span>
          )}
          <span className={'fleet-item-title'}>
            Véhicule immatriculé {props.vehicle.imat || '{N/A}'}
          </span>
          <span className={'fleet-item-desc'}>
            Clé véhicule {props.vehicle.vehicule_key || '{N/A}'}
          </span>
        </div>
        <div className={'hwe-auto'}>
          {props.isFleetEditing ? (
            <OverlayTrigger
              placement="right"
              delay={{ show: 250, hide: 400 }}
              overlay={RenderTooltipDesactive}
            >
              <Form.Check
                type="switch"
                id="custom-switch"
                onChange={() =>
                  props.handleToggleVehicleForOptimization(
                    props.vehicle.vehicule_id
                  )
                }
                checked={props?.vehicle?.is_disabled === 0}
              />
            </OverlayTrigger>
          ) : null}
          {!props.vehicle.starting_platform_id && !props.isFleetEditing ? (
            <OverlayTrigger
              placement="right"
              delay={{ show: 250, hide: 400 }}
              overlay={RenderTooltip}
            >
              <span>
                <FontAwesomeIcon icon={faWarning} />
              </span>
            </OverlayTrigger>
          ) : null}
          {props.vehicle.is_disabled && !props.isFleetEditing ? (
            <OverlayTrigger
              placement="right"
              delay={{ show: 250, hide: 400 }}
              overlay={RenderTooltipOpti}
            >
              <span className="ms-2">
                <FontAwesomeIcon
                  opacity={0.6}
                  color="orange"
                  icon={faWarning}
                />
              </span>
            </OverlayTrigger>
          ) : null}

          {/* <span className="fleet-info-truck">
            <FontAwesomeIcon icon={faWarning} />
          </span>
          <span className="fleet-info-truck">
            <FontAwesomeIcon icon={faWeightHanging} /> Pliable
          </span>
          <span className="fleet-info-truck">
            <FontAwesomeIcon icon={faWeightHanging} /> Solide
          </span>
          <span className="fleet-info-truck">
            <FontAwesomeIcon icon={faArrowsAltH} /> 670m²
          </span> */}
        </div>
      </div>
    </div>
  );
};

export default TourItemFleetManagement;
