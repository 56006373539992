import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react';
import { BL_STATUS } from '../../enums/bl.enum';

const AgrivitechCheckbox = ({ initialValue, interactable, isChecked }) => {
  const [checked, seChecked] = useState(isChecked);

  const handleCheckboxChange = () => {
    if (!interactable) return;
    seChecked(!checked);
  };

  return (
    <label
      className={`tw-relative tw-inline-block tw-w-4 tw-h-4 tw-rounded-[5px] tw-transition tw-duration-300 ${checked ? '' : 'tw-border-[1px] tw-border-solid tw-border-text-dark-medium-importance '} ${interactable ? 'tw-cursor-pointer' : 'tw-cursor-auto'}`}
    >
      <input
        type="checkbox"
        checked={checked}
        onChange={handleCheckboxChange}
        className="tw-absolute tw-opacity-0 tw-w-0 tw-h-0"
      />
      <span
        className={`tw-flex tw-items-center tw-justify-center tw-absolute tw-top-0 tw-left-0 tw-w-full tw-h-full tw-rounded-[5px] tw-border-0 tw-transition tw-duration-300 ${checked ? initialValue === BL_STATUS.CANCELED ? 'tw-bg-red-500' : 'tw-bg-green-500' : ''}`}
      >
        {isChecked && (
          <FontAwesomeIcon
            icon={initialValue !== BL_STATUS.DELIVERED ? faTimes : faCheck}
            className="tw-text-white tw-w-3 tw-h-3"
          />
        )}
      </span>
    </label>
  );
};

export default AgrivitechCheckbox;
