import { faCheck, faSort } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
} from '@headlessui/react';
import React, { useState } from 'react';
import FieldTitle from '../agv-forms-title';

export default function InputCombobox({
  value,
  setValue,
  fieldTitle,
  isRequired,
  isMultiple,
  style,
  hasIcon,
  iconColor,
  items,
  placeholder,
  emptyError,
  isDisabled,
  // [] of {} with "value" (unique identifier), "name" (displayed and search value), "icon" (optionnal, icon to display)
  // ex: [{value: 1, name: "exemple", icon: faFile}]
}) {
  const [query, setQuery] = useState('');

  const filteredItems =
    query === ''
      ? items
      : items?.filter((item) => {
          return item.name.toLowerCase().includes(query.toLowerCase());
        });

  const isEmpty = isRequired && (isMultiple ? !value.length : !value);
  const isError = isEmpty;

  const displayMultipleValues = () => {
    const values_count = items?.filter((element) =>
      value?.includes(element?.value)
    )?.length;
    if (values_count < 1) {
      return '';
    }
    return `${items?.filter((element) => value?.includes(element?.value))[0]?.name}${
      values_count > 1
        ? ` +${values_count - 1} autre${values_count - 1 > 1 ? 's' : ''}`
        : ''
    }`;
  };

  return (
    <div className={`${style} tw-flex tw-flex-col`}>
      {fieldTitle && (
        <FieldTitle
          title={fieldTitle}
          isError={isError}
          isRequired={isRequired}
        />
      )}
      <Combobox
        multiple={isMultiple}
        value={value}
        disabled={isDisabled}
        onChange={setValue}
        onClose={() => setQuery('')}
      >
        <div className="tw-relative">
          <ComboboxInput
            aria-label="input-combobox"
            displayValue={(item) =>
              isMultiple
                ? displayMultipleValues()
                : items?.filter((element) => element.value === item)[0]?.name
            }
            onChange={(event) => setQuery(event.target.value)}
            placeholder={placeholder}
            className={`tw-border-input-border focus:tw-border-input-borderFocused tw-bg-input-background tw-w-full tw-border tw-border-solid tw-rounded-lg tw-py-2 tw-text-sm tw-pr-8 ${hasIcon ? 'tw-pl-10' : 'tw-pl-3'}`}
          />
          <ComboboxButton className="tw-group tw-bg-transparent tw-absolute tw-border-0 tw-inset-y-0 tw-right-0 tw-px-3 tw-text-[#6A6B6C]">
            <FontAwesomeIcon icon={faSort} className="tw-size-3" />
          </ComboboxButton>
          {hasIcon && (
            <FontAwesomeIcon
              icon={items.filter((element) => element.value === value)[0].icon}
              className={`tw-align-baseline tw-size-4 tw-absolute tw-left-0 tw-top-1/4 tw-inset-y-0 tw-px-3 ${iconColor}`}
            />
          )}
        </div>
        <ComboboxOptions
          anchor="bottom"
          className={`tw-w-[var(--input-width)] tw-border-input-border tw-border-solid tw-rounded-lg tw-border tw-bg-input-background tw-p-1 tw-empty:hidden`}
        >
          {filteredItems?.map((item) => (
            <ComboboxOption
              key={item.value}
              value={item.value}
              className="tw-group tw-flex tw-cursor-pointer tw-items-center tw-gap-2 tw-rounded-lg tw-py-1.5 tw-px-3 data-[focus]:tw-bg-input-border"
            >
              <FontAwesomeIcon
                icon={faCheck}
                className="tw-invisible tw-text-main tw-size-4 tw-fill-white group-data-[selected]:tw-visible"
              />
              {hasIcon && item?.icon && (
                <FontAwesomeIcon
                  className={`tw-size-4 ${iconColor}`}
                  icon={item.icon}
                />
              )}
              <div className="tw-text-sm">{item.name}</div>
            </ComboboxOption>
          ))}
        </ComboboxOptions>
      </Combobox>
      {isEmpty && (
        <div className="tw-text-sm tw-font-work tw-text-input-required tw-mt-3">
          {emptyError}
        </div>
      )}
    </div>
  );
}
