import { faMessage, faPlus } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import useCreateMessage from '../../api/messaging/useCreateMessage';
import useGetRequest from '../../api/useGetRequest';
import EmptyList from '../../components/agv-empty-list';
import GenericPageContainer from '../../components/agv-generic-page-container';
import GenericTable from '../../components/agv-generic-table';
import HeaderButton from '../../components/agv-header-button';
import SearchFilter from '../../components/agv-header-search';
import { STATUS_ENUM } from '../../components/agv-status/status-enum';
import MessagingCreateModal from './messaging-create-modal';
import MessagingDetailsModal from './messaging-details-modal';
import { useInfiniteScroll } from '../../hooks/useInfiniteScroll';
import InfiniteScroll from 'react-infinite-scroll-component';
import GenericTableSkeleton from '../../components/agv-generic-table/genericTableSkeleton';

export default function Messaging() {
  const [searchParams] = useSearchParams();

  const [isOpenCreate, setIsOpenCreate] = useState(false);
  const [isOpenDetails, setIsOpenDetails] = useState(false);
  const [message, setMessage] = useState(null);

  const initialFilters = {
    limit: 24,
    offset: 0,
    query: searchParams.get('query') || '',
  };

  const {
    items,
    hasMore,
    filters,
    fetchMoreData,
    handleUpdateParam,
    isLoading,
    mutate,
  } = useInfiniteScroll({
    initialFilters,
    debounceTime: 300,
    getRequest: useGetRequest,
    endpoint: '/messaging',
  });

  const isMessageSeenOneTime = (items) => {
    return items?.some((recipient) => recipient?.has_been_read === 1);
  };

  const isEveryMessageSeen = (items) => {
    return items?.every((recipient) => recipient?.has_been_read === 1);
  };

  const {
    createMessage,
    isLoading: isLoadingCreateMessage,
    isSuccess: isSuccessCreateMessage,
  } = useCreateMessage();

  useEffect(() => {
    if (isSuccessCreateMessage) {
      setIsOpenCreate(false);
      mutate();
    }
  }, [isSuccessCreateMessage, mutate]);

  const handleOpenDetails = (message) => {
    setMessage(message);
    setIsOpenDetails(true);
  };

  return (
    <>
      <GenericPageContainer
        title={'Messagerie chauffeurs'}
        subtitle={
          'Créez et consultez des messages à destination des chauffeurs'
        }
        filters={
          <>
            <SearchFilter
              value={filters.query}
              setValue={(value) => handleUpdateParam('query', value)}
              disabled={isLoading}
            />
            <HeaderButton
              onClick={() => setIsOpenCreate(true)}
              text={'Créer'}
              icon={faPlus}
            />
          </>
        }
      >
        {isLoading && filters.offset === 0 ? (
          <GenericTableSkeleton numberOfRows={10} hasTitle />
        ) : items?.length > 0 ? (
          <InfiniteScroll
            dataLength={items.length}
            next={fetchMoreData}
            hasMore={hasMore}
            loader={<GenericTableSkeleton numberOfRows={3} hasTitle />}
            scrollableTarget="page-content"
            hasChildren={items.length > 0}
            endMessage={
              <p className="agrivitech-pagination-text tw-mb-2 tw-mt-8 tw-text-center">
                Vous avez vu toutes les donneés pour cette recherche
              </p>
            }
          >
            <GenericTable
              isLoading={isLoading}
              key={`date_${message?.date}`}
              rows={items?.map((element) => ({
                title: `${element?.recipients?.[0]?.vehicle_key}${element?.recipients?.length > 1 ? ` +${element?.recipients?.length - 1} autre${element?.recipients?.length - 1 > 1 ? 's' : ''} destinataire${element?.recipients?.length - 1 > 1 ? 's' : ''}` : ''}`,
                subtitle: element?.message,
                onClick: () => handleOpenDetails(element),
                buttonText: 'Détails',
                status: isEveryMessageSeen(element?.recipients)
                  ? STATUS_ENUM.POSITIVE
                  : isMessageSeenOneTime(element?.recipients)
                    ? STATUS_ENUM.INFO
                    : STATUS_ENUM.NEUTRAL,
                icon: faMessage,
                statusText: isEveryMessageSeen(element?.recipients)
                  ? 'Vu'
                  : isMessageSeenOneTime(element?.recipients)
                    ? 'Partiellement vu'
                    : 'Envoyé',
                fields: [
                  {
                    value: moment(element?.created_at).format('DD MMMM YYYY'),
                  },
                ],
              }))}
              iconColor={'tw-text-checklist-common'}
            />
          </InfiniteScroll>
        ) : (
          <EmptyList />
        )}
      </GenericPageContainer>
      <MessagingDetailsModal
        isOpen={isOpenDetails}
        setIsOpen={setIsOpenDetails}
        message={message}
      />
      <MessagingCreateModal
        isOpen={isOpenCreate}
        setIsOpen={setIsOpenCreate}
        createMessage={createMessage}
        mainActionIsLoading={isLoadingCreateMessage}
      />
    </>
  );
}
