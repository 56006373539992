import {
  faClipboard,
  faComment,
  faGasPump,
  faRoute,
  faWater,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify'; // Assurez-vous d'avoir installé react-toastify
import useGetTourDetails from '../../../api/tours/useGetToursDetails';
import MainButton from '../../../components/agv-button';
import AgrivitechCheckbox from '../../../components/agv-checkbox/agv-checkbox';
import GenericModal from '../../../components/agv-generic-modal';
import Status from '../../../components/agv-status';
import AgvTextDropdown from '../../../components/agv-text-dropdown';
import AgvTimePickerDropdown from '../../../components/agv-time-picker-dropdown';
import AlimentTypeTag from '../../../components/fleet-aliment-type-tag/aliment-type-tag';
import { ALIMENT_TYPE_ENUM } from '../../../components/fleet-aliment-type-tag/aliment-type.enum';
import { PACKAGING_TYPE } from '../../../enums/bl.enum';
import { DELIVERY_DETAIL_TYPE } from '../../../enums/delivery-detail.enum';
import DOCUMENT_TYPE from '../../../enums/documents.enum';
import FUEL from '../../../enums/fuel.enum';
import { MODAL_SIZE, MODAL_SIZE_STYLE } from '../../../enums/modal-size.enum';
import { step_status } from '../../../enums/step-status.enum';
import { STEP_TYPE } from '../../../enums/step-type.enum';
import { tour_status } from '../../../enums/tour-status';
import fleetApi from '../../../services/api.service';
import { computeRowForOneDeliveryDetail } from '../../../utils/silo.util';
import computeTourSubtitle from '../tour-util';

function ModalTour({ tourSelected, show, onHide }) {
  const {
    details,
    error: detailsError,
    isLoading: isDetailsLoading,
    refetch, // Ajoutez refetch ici
  } = useGetTourDetails(tourSelected?.tour_id);

  const DEFAULT_DEPARTURE_TIME = '02:00';
  const DEFAULT_CLEANING = 'Non';

  const features = useSelector((state) => state.features);

  const [isLoading, setIsLoading] = useState(false);
  const [departureTime, setDepartureTime] = useState(null);
  const [cleaning, setCleaning] = useState(null);
  const [updateTourListOnClose, setUpdateTourListOnClose] = useState(false);

  // Modification de l'effet pour gérer correctement la valeur par défaut
  useEffect(() => {
    if (!show) {
      // Réinitialisation lors de la fermeture
      setDepartureTime(DEFAULT_DEPARTURE_TIME);
      setCleaning(DEFAULT_CLEANING);
    } else {
      // Initialisation à l'ouverture uniquement si departureTime est null
      setDepartureTime(DEFAULT_DEPARTURE_TIME);
      setCleaning(DEFAULT_CLEANING);
    }
  }, [show]);

  const isButtonDisabled = () => {
    if (details?.status === tour_status.IMPORTED) {
      return isLoading || !departureTime || !cleaning;
    }
    return isLoading;
  };

  const handleSendRequest = async () => {
    setIsLoading(true);

    try {
      const response = await fleetApi.post(
        `/tours/validate/${tourSelected.tour_id}`,
        {
          is_cleaning_mandatory: cleaning === 'Oui' ? 1 : 0,
          start_hour: departureTime,
        }
      );

      if (response.status === 200) {
        toast.success('La tournée a été validée avec succès !');
        await refetch(); // Rafraîchir les données de la modal ouverte
        setUpdateTourListOnClose(true);
      } else {
        throw new Error('Réponse inattendue du serveur');
      }
    } catch (error) {
      console.error('Erreur lors de la validation de la tournée:', error);
      toast.error(
        'Une erreur est survenue lors de la validation de la tournée. Veuillez réessayer.'
      );
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
  };

  const computeStepIcon = (step) => {
    const deliveryDetails = step?.bls;

    const types = deliveryDetails?.map((el) => el?.delivery_type);

    if (!types) {
      console.error('Types is undefined or empty');
      return null;
    }

    if (types.includes(DELIVERY_DETAIL_TYPE.TRANSVIDAGE)) {
      return STEP_TYPE.properties[STEP_TYPE.TRANSVIDAGE]?.icon;
    } else if (types.includes(DELIVERY_DETAIL_TYPE.GARDER)) {
      return STEP_TYPE.properties[STEP_TYPE.GARDER]?.icon;
    } else if (types.includes(DELIVERY_DETAIL_TYPE['TRANSFERT-SILO'])) {
      return STEP_TYPE.properties[STEP_TYPE['TRANSFERT-SILO']]?.icon;
    } else if (
      (types.includes(DELIVERY_DETAIL_TYPE.LIVRAISON) ||
        types.includes(DELIVERY_DETAIL_TYPE['LIVRAISON-VIA-TRANSFERT-SILO'])) &&
      types.includes(DELIVERY_DETAIL_TYPE.REPRISE)
    ) {
      return STEP_TYPE.properties[STEP_TYPE['LIVRAISON-REPRISE']]?.icon;
    } else if (
      types.includes(DELIVERY_DETAIL_TYPE.LIVRAISON) ||
      types.includes(DELIVERY_DETAIL_TYPE['LIVRAISON-VIA-TRANSFERT-SILO'])
    ) {
      return STEP_TYPE.properties[STEP_TYPE.LIVRAISON]?.icon;
    } else if (types.includes(DELIVERY_DETAIL_TYPE.REPRISE)) {
      return STEP_TYPE.properties[STEP_TYPE.REPRISE]?.icon;
    } else {
      console.error('No matching delivery type found');
      return STEP_TYPE.properties[STEP_TYPE.LIVRAISON]?.icon;
    }
  };

  const formatAddress = (address) => {
    if (!address) {
      return 'Adresse non disponible';
    }

    const { address_number, street, city, zipcode } = address;

    const formattedStreet = address_number
      ? `${address_number} ${street}`
      : street;
    const formattedAddress = [formattedStreet, city, zipcode]
      .filter(Boolean)
      .join(', ');

    return formattedAddress || 'Adresse non disponible';
  };

  const washingTime = details?.washings?.reduce((accumulator, currentValue) => {
    return accumulator + currentValue?.duration_in_seconds;
  }, 0);

  return (
    <GenericModal
      isOpen={show}
      setIsOpen={() => {
        if (updateTourListOnClose) {
          setUpdateTourListOnClose(false);
        }
        onHide(updateTourListOnClose);
      }}
      title={`Détails de la tournée ${tourSelected?.tour_key}`}
      size={MODAL_SIZE_STYLE[MODAL_SIZE.XXL]}
      padding={'tw-px-4'}
    >
      <section className="tw-self-stretch tw-flex tw-flex-col tw-items-stretch tw-justify-start tw-gap-6 tw-pt-8 tw-overflow-y-auto tw-flex-auto">
        <div className="tw-flex-1 tw-flex tw-flex-col tw-justify-start tw-max-w-full">
          <div className="tw-flex tw-flex-row tw-items-start tw-justify-between tw-w-full mq750:tw-flex-wrap">
            <div className="tw-flex tw-flex-col tw-items-start tw-justify-start tw-px-0 tw-pb-0 tw-text-text-dark-high-importance">
              <div className="tw-flex tw-flex-row tw-items-center tw-justify-start tw-shrink-0">
                <div className="tw-flex tw-flex-col tw-items-start tw-justify-start tw-px-0 tw-pb-0">
                  <div className="tw-w-6 tw-h-6 tw-relative tw-flex tw-items-center">
                    <FontAwesomeIcon
                      icon={faRoute}
                      className="tw-text-icon-gray"
                    />
                  </div>
                </div>
                <div className="tw-flex tw-flex-col tw-items-start tw-justify-start tw-gap-0.5">
                  <div className="tw-relative tw-font-medium tw-font-work tw-text-sm">
                    <span>Tour {details ? details?.tour_key : '-'}</span>
                  </div>
                  <div className="list-item-title tw-text-gray-600">
                    {computeTourSubtitle(features, details)}{' '}
                  </div>
                </div>
              </div>
            </div>
            <div className="tw-flex tw-flex-row tw-items-center tw-justify-start tw-gap-4 tw-shrink-0 tw-max-w-full tw-text-smi mq450:tw-flex-wrap">
              {details?.status === tour_status.IMPORTED && (
                <>
                  <div className="tw-flex tw-flex-col tw-items-start tw-justify-start tw-gap-0.5">
                    <div className="list-item-title text-text-dark-high-importance">
                      Heure départ
                    </div>
                    <div className="list-item-title tw-text-gray-600">
                      <AgvTimePickerDropdown
                        defaultValue={departureTime}
                        onChange={(value) => setDepartureTime(value)}
                      />
                    </div>
                  </div>
                  <div className="tw-w-[1px] tw-h-4 tw-bg-black tw-opacity-20" />
                  <div className="tw-flex tw-flex-col tw-items-start tw-justify-start tw-gap-0.5">
                    <div className="list-item-title text-text-dark-high-importance">
                      Nettoyage
                    </div>
                    <div className="list-item-title tw-text-gray-600">
                      <AgvTextDropdown
                        defaultValue={cleaning}
                        options={[
                          { value: 'Oui', name: 'Oui' },
                          { value: 'Non', name: 'Non' },
                        ]}
                        onChange={(value) => setCleaning(value)}
                      />
                    </div>
                  </div>
                  <div className="tw-w-[1px] tw-h-4 tw-bg-black tw-opacity-20" />
                </>
              )}
              <div className="tw-flex tw-flex-col tw-items-start tw-justify-start tw-gap-0.5">
                <div className="list-item-title text-text-dark-high-importance">
                  Chauffeur
                </div>
                <div className="list-item-title tw-text-gray-600">
                  {details?.fullname ? details.fullname : '-'}
                </div>
              </div>
              <div className="tw-w-[1px] tw-h-4 tw-bg-black tw-opacity-20" />
              <div className="tw-flex tw-flex-col tw-items-start tw-justify-start tw-gap-0.5">
                <div className="list-item-title tw-text-dark-high-importance">
                  Étapes
                </div>
                <div className="list-item-title tw-text-gray-600">
                  {details?.finalized_step_count
                    ? `${details?.finalized_step_count} sur ${details?.total_step_count}`
                    : '-'}
                </div>
              </div>
              <div className="tw-w-[1px] tw-h-4 tw-bg-black tw-opacity-20" />
              <div className="tw-flex tw-flex-col tw-items-start tw-justify-start tw-gap-0.5">
                <div className="list-item-title tw-text-text-dark-high-importance">
                  Kilomètres de début de tournée
                </div>
                <div className="list-item-title tw-text-gray-600">
                  {details?.last_kilometers ? details.last_kilometers : '0'} km
                </div>
              </div>
              <div className="tw-w-[1px] tw-h-4 tw-bg-black tw-opacity-20" />
              <div className="tw-flex tw-flex-col tw-items-start tw-justify-start tw-gap-0.5">
                <div className="list-item-title tw-text-text-dark-high-importance">
                  Distance
                </div>
                <div className="list-item-title tw-text-gray-600">
                  {details?.inputted_kilometers && details.last_kilometers
                    ? Number(details?.inputted_kilometers) -
                      Number(details.last_kilometers)
                    : '0'}{' '}
                  km
                </div>
              </div>
              <div className="tw-w-[1px] tw-h-4 tw-bg-black tw-opacity-20" />

              <div className="tw-flex tw-flex-col tw-items-start tw-justify-start tw-gap-0.5">
                <div className="list-item-title tw-text-text-dark-high-importance">
                  {'Impact CO₂'}
                </div>
                <div className="list-item-title tw-text-gray-600">
                  {details?.inputted_kilometers && details.last_kilometers
                    ? (
                        (Number(details?.inputted_kilometers) -
                          Number(details.last_kilometers)) *
                        Number(0.101)
                      ).toFixed(2)
                    : '0'}{' '}
                  kg
                </div>
              </div>

              <div className="tw-w-[1px] tw-h-4 tw-bg-black tw-opacity-20" />

              <div className="tw-flex tw-flex-col tw-items-start tw-justify-start tw-gap-0.5">
                <div className="list-item-title tw-text-text-dark-high-importance">
                  <Status
                    status={tour_status.properties[details?.status]?.color}
                    text={tour_status.properties[details?.status]?.display_name}
                  />
                </div>
              </div>
              <div className="tw-w-[1px] tw-h-4 tw-bg-black tw-opacity-20" />

              <div className="tw-flex tw-flex-col tw-items-start tw-justify-start tw-gap-0.5">
                <div className="list-item-title tw-text-gray-600">
                  <MainButton
                    style={''}
                    text={isLoading ? 'Envoi en cours...' : 'Envoyer'}
                    onClick={handleSendRequest}
                    disabled={
                      isButtonDisabled() ||
                      details?.status !== tour_status.IMPORTED
                    }
                  >
                    {isLoading ? 'Envoi en cours...' : 'Envoyer'}
                  </MainButton>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="tw-h-[1px] tw-w-full tw-bg-black tw-opacity-20" />

        <div className="tw-pb-16 tw-gap-6 tw-overflow-y-auto tw-flex tw-flex-col tw-items-stretch tw-justify-start tw-relative tw-max-w-full tw-text-smi tw-text-text-dark-high-importance">
          {/* tour steps block */}
          <div className="tw-flex tw-flex-col tw-items-start tw-justify-start tw-max-w-full tw-text-gray-600">
            {/* section header */}
            <div className="tw-flex tw-flex-col tw-pb-6 tw-text-base tw-text-text-dark-high-importance">
              <div className="tw-relative tw-font-medium">Liste des étapes</div>
              <div className="tw-relative tw-text-smi tw-leading-[15px] tw-text-text-dark-medium-importance">
                Retrouvez la liste des étapes du tour
              </div>
            </div>
            {/* section table header */}
            <div className="tw-self-stretch tw-flex tw-flex-row tw-items-start tw-justify-start tw-pt-0 tw-px-0 tw-pb-4 tw-box-border tw-max-w-full tw-shrink-0 tw-text-right">
              <div className="tw-flex-1 tw-bg-whitesmoke-400 tw-flex tw-flex-row tw-flex-wrap tw-items-start tw-justify-start tw-py-2 tw-px-2 tw-shrink-0 [row-gap:20px]">
                <div className="tw-flex-1 tw-relative tw-text-text-dark-medium-importance tw-text-left tw-inline-block tw-min-w-[450px] tw-max-w-full">
                  Étape
                </div>

                <div className="tw-flex-1 tw-relative tw-inline-block tw-min-w-[82px]">
                  Statut(s)
                </div>
              </div>
            </div>
            {/* section content */}
            {details?.steps.map((step) => {
              const IconComponentStep = computeStepIcon(step);
              return (
                <div
                  key={`${step.step_id}`}
                  className="tw-self-stretch tw-flex tw-flex-col tw-items-end tw-justify-start tw-pt-4 tw-px-0 tw-pb-4 tw-gap-4 tw-shrink-0 tw-max-w-full tw-text-sm tw-border-solid tw-border-background tw-border-0 tw-border-b"
                >
                  {/* main step row */}
                  <div className="tw-self-stretch tw-flex tw-flex-row tw-items-start tw-justify-start [row-gap:20px] tw-max-w-full tw-text-right tw-text-smi tw-text-text-dark-high-importance">
                    <div className="tw-flex-1 tw-overflow-hidden tw-flex tw-flex-col tw-items-start tw-justify-start tw-gap-[2.1px] tw-min-w-[450px] tw-max-w-full tw-text-left">
                      <div className="tw-flex tw-flex-row tw-items-start tw-justify-start tw-gap-[16px] tw-text-sm">
                        <div className="tw-flex tw-flex-row tw-items-start tw-justify-start">
                          <div className="tw-h-4 tw-w-[50px] tw-relative">
                            {/* <FontAwesomeIcon
                                icon={faTruck}
                                className="tw-text-icon-gray tw-absolute tw-top-0 tw-left-0 tw-w-6 tw-h-6 tw-text-2xl"
                              /> */}
                            <IconComponentStep />
                          </div>
                          <div className="tw-flex tw-flex-col tw-items-start tw-justify-start">
                            <div className="tw-relative tw-font-semibold tw-flex tw-gap-4 tw-text-sm">
                              <div className="">
                                {step?.step_key
                                  ? `Étape ${step.step_key}`
                                  : 'STEP_NAME'}
                              </div>
                            </div>
                            <div className="list-item-title">
                              {formatAddress(step?.end_address)}
                            </div>
                            <div className="tw-flex tw-flex-row tw-items-center tw-justify-start tw-gap-[8px]">
                              <div className="tw-relative list-item-title">
                                Attendu au plus tard
                              </div>
                              <div class="polygon"></div>
                              <div className="tw-relative list-item-title">
                                {step?.estimated_delivery
                                  ? moment(step?.estimated_delivery).format(
                                      'D MMMM YYYY, HH:mm'
                                    )
                                  : ''}
                              </div>
                            </div>

                            <div className="tw-flex tw-flex-row tw-items-center tw-justify-start tw-gap-[8px]">
                              <div className="tw-flex-1 tw-relative tw-text-smi tw-tracking-[-0.02em] tw-text-text-dark-high-importance tw-min-w-[129px] tw-shrink-0">
                                {`Kilométrage compteur : ${
                                  step?.inputted_kilometers
                                    ? step.inputted_kilometers + ' km'
                                    : 'Non communiqué'
                                }`}
                              </div>
                            </div>
                            {(step?.arrival_date || step?.departure_date) && (
                              <div className="tw-flex tw-flex-row tw-items-center tw-justify-start tw-gap-[8px]">
                                <div className="tw-relative list-item-title">
                                  Arrivée - Départ
                                </div>
                                <div class="polygon"></div>

                                <div className="tw-flex-1 tw-relative tw-text-smi tw-tracking-[-0.02em] tw-text-text-dark-high-importance tw-min-w-[129px] tw-shrink-0 tw-w-full">
                                  {`${
                                    step?.arrival_date
                                      ? moment(step?.arrival_date).format(
                                          'HH[h]mm'
                                        )
                                      : 'Non communiqué'
                                  } - ${
                                    step?.departure_date
                                      ? moment(step?.departure_date).format(
                                          'HH[h]mm'
                                        )
                                      : 'Non communiqué'
                                  }`}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="tw-flex-1 tw-flex tw-flex-col tw-items-end tw-justify-start tw-gap-[4px] tw-text-center tw-text-xs tw-text-text-dark-medium-importance">
                      <Status
                        status={step_status.properties[step.status]?.style}
                        text={step_status.properties[step.status]?.display_name}
                        style={'tw-my-1'}
                      />
                      {step?.need_to_disinfect ? (
                        <div className="tw-rounded-3xs tw-bg-goldenrod tw-flex tw-flex-row tw-items-start tw-justify-start tw-py-px tw-px-[7px] tw-whitespace-nowrap tw-text-black tw-border-[1px] tw-border-solid tw-border-dimgray-200">
                          <div className="tw-relative tw-font-medium tw-inline-block ">
                            Risque sanitaire
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>

                  {features?.step_end_process?.pallets && (
                    <div className="tw-flex tw-flex-col tw-pl-[50px] tw-items-start tw-justify-start tw-w-full">
                      <div className="tw-relative tw-tracking-[-0.02em] tw-font-medium">
                        {`Palettes reprises : ${step?.additional_datas?.nb_pallets_retaked || 0}`}
                      </div>
                      <div className="tw-relative tw-tracking-[-0.02em] tw-font-medium">
                        {`Palettes laissées : ${step?.additional_datas?.nb_pallets_left || 0}`}
                      </div>
                    </div>
                  )}

                  {/* comments */}
                  <div className="tw-flex tw-flex-col tw-pl-[50px] tw-items-start tw-justify-start tw-gap-3 tw-w-full">
                    <div className="tw-relative tw-tracking-[-0.02em] tw-font-medium">
                      Commentaire itinéraire
                    </div>
                    <div className="tw-self-stretch tw-rounded-[3px] tw-bg-whitesmoke-100 tw-box-border tw-flex tw-flex-row tw-flex-wrap tw-items-center tw-justify-start tw-py-3.5 tw-pr-3.5 tw-pl-4 tw-gap-[16px] tw-max-w-full tw-text-smi tw-text-text-dark-medium-importance tw-border-[1px] tw-border-solid tw-border-border-primary-strong">
                      <div className="tw-flex tw-flex-col tw-items-start tw-justify-start ">
                        <FontAwesomeIcon
                          icon={faComment}
                          className="tw-text-icon-gray tw-w-4 tw-h-4"
                        />
                      </div>
                      <p className="tw-flex-1 tw-relative tw-tracking-[-0.02em] tw-mb-0 tw-leÉing-4">
                        {step?.end_address?.address_comments
                          ? step?.end_address?.address_comments
                          : "Pas de commentaire d'itinéraire"}
                      </p>
                    </div>
                    <div className="tw-relative tw-tracking-[-0.02em] tw-font-medium">
                      Commentaire d'étape
                    </div>
                    <div className="tw-self-stretch tw-rounded-[3px] tw-bg-whitesmoke-100 tw-box-border tw-flex tw-flex-row tw-flex-wrap tw-items-center tw-justify-start tw-py-3.5 tw-pr-3.5 tw-pl-4 tw-gap-[16px] tw-max-w-full tw-text-smi tw-text-text-dark-medium-importance tw-border-[1px] tw-border-solid tw-border-border-primary-strong">
                      <div className="tw-flex tw-flex-col tw-items-start tw-justify-start ">
                        <FontAwesomeIcon
                          icon={faComment}
                          className="tw-text-icon-gray tw-w-4 tw-h-4"
                        />
                      </div>
                      <p className="tw-flex-1 tw-relative tw-tracking-[-0.02em] tw-mb-0 tw-leÉing-4">
                        {step?.comments
                          ? step?.comments
                          : 'Pas de commentaire pour cette étape'}
                      </p>
                    </div>
                  </div>
                  <div className="tw-flex tw-flex-col tw-pl-[50px] tw-items-start tw-justify-start tw-gap-3 tw-w-full">
                    <div className="tw-relative tw-tracking-[-0.02em] tw-font-medium">
                      Bons de livraison
                    </div>
                    <div className="tw-w-full tw-flex tw-flex-col tw-gap-3">
                      {step?.bls?.map?.((bl) => {
                        const IconComponent =
                          DELIVERY_DETAIL_TYPE.properties[bl?.delivery_type]
                            .icon;
                        return (
                          <div
                            key={`${bl?.delivery_detail_id}`}
                            className="tw-self-stretch tw-rounded-10xs tw-bg-whitesmoke-100 tw-box-border tw-flex tw-flex-row tw-flex-wrap tw-items-start tw-justify-between tw-py-2 tw-pr-[17px] tw-pl-[15px] tw-max-w-full tw-gap-[20px] tw-text-smi tw-text-text-dark-high-importance tw-border-[1px] tw-border-solid tw-border-border-primary-strong"
                          >
                            <div className="tw-flex tw-flex-row tw-items-start tw-justify-start tw-gap-2">
                              <div className="tw-flex tw-flex-col tw-items-start tw-justify-start tw-pt-2 tw-px-0 tw-pb-0">
                                <IconComponent className="tw-text-icon-gray tw-w-8 tw-h-8" />
                              </div>
                              <div className="tw-flex tw-flex-col tw-items-start tw-justify-start tw-gap-3">
                                <div className="tw-flex tw-flex-row tw-items-start">
                                  <div className="tw-flex tw-flex-row tw-items-start tw-justify-start tw-gap-3">
                                    <div className="tw-flex tw-flex-col tw-items-start tw-justify-start tw-gap-0.5">
                                      <div className="tw-relative tw-font-medium tw-text-sm">
                                        {bl?.delivery_detail_key
                                          ? `BL n° ${bl.delivery_detail_key}`
                                          : 'DELIVERY_DETAIL_KEY'}
                                      </div>
                                      <div className="tw-relative tw-text-text-dark-medium-importance list-item-title">
                                        {bl?.receiver_final ||
                                          step?.end_address?.name ||
                                          'STEP_ADDRESS_NAME'}
                                      </div>
                                    </div>
                                    <div className="tw-h-[33px] tw-flex tw-flex-col tw-items-center tw-justify-start">
                                      <img
                                        className="tw-w-[13.6px] tw-h-[13.5px] tw-relative"
                                        loading="lazy"
                                        alt=""
                                        src="/icon-akartrianglealertfill.svg"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="tw-flex tw-flex-col tw-items-start tw-justify-start tw-gap-[1px] tw-text-right tw-text-xs tw-text-primary">
                                  {bl?.documents?.map?.((el) => (
                                    <div
                                      key={`el_${el.id}`}
                                      className="tw-relative [text-decoration:underline] tw-tracking-[0.02em]"
                                    >
                                      <a
                                        href={el.url}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        Télécharger la{' '}
                                        {el?.doc_type
                                          ? DOCUMENT_TYPE.properties[
                                              el?.doc_type
                                            ]?.display_name
                                          : 'DOCUMENT_TYPE'}
                                      </a>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                            <div className="tw-flex tw-flex-row tw-items-center tw-justify-start tw-align-center  tw-min-w-[450px] tw-gap-4">
                              <div className="tw-h-[53px] tw-w-[1px] tw-relative tw-bg-black tw-opacity-5" />
                              {/* ddd */}
                              <div className="tw-flex tw-flex-col tw-gap-1.5 tw-align-stretch tw-w-full">
                                {bl?.items?.map((item) => {
                                  let quantityDelivered = item?.quantity;
                                  if (
                                    item?.packaging === PACKAGING_TYPE.VRAC &&
                                    item?.reserves?.[item?.reserves?.length - 1]
                                      ?.additional_datas?.nb_qty_delivered
                                  ) {
                                    quantityDelivered =
                                      item.reserves[item?.reserves?.length - 1]
                                        .additional_datas.nb_qty_delivered;
                                  }
                                  return (
                                    <>
                                      {/* ------------------------------------------------------ */}

                                      {computeRowForOneDeliveryDetail(
                                        bl,
                                        item
                                      )?.map?.((row) => (
                                        <div
                                          key={`${row.what}_${row.where}`}
                                          className="tw-flex tw-flex-row tw-gap-[5px]  tw-text-black tw-items-center tw-justify-start tw-align-center tw-grow"
                                        >
                                          <AgrivitechCheckbox
                                            isChecked={row.checked}
                                            initialValue={bl.status}
                                          />
                                          <div className="tw-rounded-8xs tw-w-full tw-bg-gainsboro-200 tw-overflow-x-auto tw-flex tw-flex-row tw-items-center tw-justify-between tw-py-0.5 tw-px-8 tw-gap-[20px]">
                                            <div className="tw-relative tw-tracking-[0.02em] tw-inline-block tw-min-w-[45px]">
                                              {row.what}
                                            </div>
                                            <div
                                              className="polygon"
                                              style={{
                                                ...(row.invert_arrow && {
                                                  transform: 'rotate(270deg)',
                                                }),
                                              }}
                                            ></div>
                                            <div className="tw-relative tw-tracking-[0.02em] tw-inline-block tw-min-w-[37px] tw-text-text-dark-high-importance">
                                              {!row.where_substitute ? (
                                                <span className="tw-text-black">
                                                  {row.where}
                                                </span>
                                              ) : null}

                                              {row.where_substitute && (
                                                <span className="tw-text-black">
                                                  <span className="tw-line-through  tw-text-black/60">
                                                    {row.where}
                                                  </span>{' '}
                                                  <span className=" tw-font-semibold">
                                                    {row.where_substitute}
                                                  </span>
                                                </span>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      ))}

                                      {/* ------------------------------------------ */}
                                      {/* product row */}
                                      <div className="tw-self-stretch tw-flex tw-flex-row tw-items-center tw-justify-between tw-text-text-dark-medium-importance">
                                        <div className="tw-flex tw-flex-row tw-gap-2">
                                          <AlimentTypeTag
                                            item={
                                              Object.values(
                                                ALIMENT_TYPE_ENUM
                                              ).includes(
                                                item?.type?.toUpperCase?.()
                                              )
                                                ? item?.type?.toUpperCase()
                                                : ALIMENT_TYPE_ENUM.AUTRES
                                            }
                                          />
                                          {item?.name
                                            ? item.name
                                            : 'PRODUCT_NAME'}
                                        </div>

                                        <div className="h-[15px] w-[29px] relative text-right inline-block tw-flex-row tw-flex">
                                          {quantityDelivered !==
                                            item?.quantity && (
                                            <div className="tw-mr-2 tw-line-through">
                                              {item?.quantity}
                                            </div>
                                          )}
                                          {quantityDelivered
                                            ? `${quantityDelivered} `
                                            : 'nc.'}
                                          {item?.weight_unit
                                            ? `${item.weight_unit}`
                                            : 'nc.'}
                                        </div>
                                      </div>
                                      {item?.sub_items?.map((sub_item) => {
                                        return (
                                          <div
                                            key={sub_item?.id}
                                            className="tw-self-stretch tw-flex tw-flex-row tw-items-center tw-italic tw-justify-between tw-text-text-dark-medium-importance"
                                          >
                                            <div className="tw-flex tw-flex-row tw-gap-2 tw-pl-5">
                                              <div className=" tw-text-wrap ">
                                                {sub_item?.name
                                                  ? sub_item?.name
                                                  : 'PRODUCT_NAME'}
                                              </div>
                                            </div>

                                            <div className="h-[15px] w-[29px] relative text-right inline-block">
                                              {sub_item?.net_weight
                                                ? `${sub_item?.net_weight} t.`
                                                : 'nc.'}
                                            </div>
                                          </div>
                                        );
                                      })}
                                    </>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          {/* tour comments block */}
          <div className="tw-self-stretch tw-flex tw-flex-col tw-items-start tw-justify-start tw-gap-[33px] tw-shrink-0 [debug_commit:bf4bc93] tw-max-w-full tw-text-base mq750:tw-gap-[16px]">
            {/* section header */}
            <div className="tw-flex tw-flex-col tw-pb-0 tw-text-base tw-text-text-dark-high-importance">
              <div className="tw-relative tw-font-medium">
                Informations supplémentaires sur la tournée
              </div>
              <div className="tw-relative tw-text-smi tw-leading-[15px] tw-text-text-dark-medium-importance">
                Détails et documents fournis par l'équipe de planification
              </div>
            </div>
            {/* comments container */}
            <div className="tw-flex tw-flex-col tw-w-full tw-gap-2">
              {/* tour_comments */}
              <div className="tw-self-stretch tw-rounded-[3px] tw-bg-whitesmoke-100 tw-box-border tw-flex tw-flex-row tw-flex-wrap tw-items-start tw-justify-start tw-py-3.5 tw-pr-3.5 tw-pl-4 tw-gap-[16px] tw-max-w-full tw-text-smi tw-text-text-dark-medium-importance tw-border-[1px] tw-border-solid tw-border-border-primary-strong">
                <div className="tw-flex tw-flex-col tw-items-start tw-justify-start ">
                  <FontAwesomeIcon
                    icon={faComment}
                    className="tw-text-icon-gray tw-w-4 tw-h-4"
                  />
                </div>
                <p className="tw-flex-1 tw-relative tw-tracking-[-0.02em] tw-mb-0 tw-leading-4">
                  {details?.tour_comments
                    ? details.tour_comments
                    : 'Pas de commentaire pour cette tournée'}
                </p>
              </div>
              {/* DOCUMENT_TYPE */}
              <div className="tw-self-stretch tw-rounded-[3px] tw-bg-whitesmoke-100 tw-box-border tw-flex tw-flex-row tw-flex-wrap tw-items-start tw-justify-start tw-py-3.5 tw-pr-3.5 tw-pl-4 tw-gap-[16px] tw-max-w-full tw-text-smi tw-text-text-dark-medium-importance tw-border-[1px] tw-border-solid tw-border-border-primary-strong">
                <div className="tw-flex tw-flex-col tw-items-start tw-justify-start ">
                  <FontAwesomeIcon
                    icon={faClipboard}
                    className="tw-text-icon-gray tw-w-4 tw-h-4"
                  />
                </div>
                <p className="tw-flex-1 tw-relative tw-tracking-[-0.02em] tw-mb-0 tw-leading-4">
                  {details?.tour_documents.length
                    ? details.tour_documents?.map((el) => (
                        <div
                          key={`el_${el.id}`}
                          className="tw-relative [text-decoration:underline] tw-tracking-[0.02em]"
                        >
                          <a href={el.url} target="_blank" rel="noreferrer">
                            Télécharger la{' '}
                            {el?.doc_type
                              ? DOCUMENT_TYPE.properties[el.doc_type]
                                  ?.display_name
                              : 'DOCUMENT_TYPE'}
                          </a>
                        </div>
                      ))
                    : 'Pas de documents pour cette tournée'}
                </p>
              </div>
              {/* NETTOYAGE */}
              <div className="tw-self-stretch tw-rounded-[3px] tw-bg-whitesmoke-100 tw-box-border tw-flex tw-flex-row tw-flex-wrap tw-items-start tw-justify-start tw-py-3.5 tw-pr-3.5 tw-pl-4 tw-gap-[16px] tw-max-w-full tw-text-smi tw-text-text-dark-medium-importance tw-border-[1px] tw-border-solid tw-border-border-primary-strong">
                <div className="tw-flex tw-flex-col tw-items-start tw-justify-start ">
                  <FontAwesomeIcon
                    icon={faWater}
                    className="tw-text-icon-gray tw-w-4 tw-h-4"
                  />
                </div>
                <p className="tw-flex-1 tw-relative tw-tracking-[-0.02em] tw-mb-0 tw-leading-4">
                  {`Temps total de nettoyage : ${Math.floor(washingTime / 60)} min`}
                </p>
              </div>

              {details?.tour_refueling && (
                <>
                  {/* ----- GAS */}
                  <div className="tw-self-stretch tw-rounded-[3px] tw-bg-whitesmoke-100 tw-box-border tw-flex tw-flex-row tw-flex-wrap tw-items-start tw-justify-start tw-py-3.5 tw-pr-3.5 tw-pl-4 tw-gap-[16px] tw-max-w-full tw-text-smi tw-text-text-dark-medium-importance tw-border-[1px] tw-border-solid tw-border-border-primary-strong">
                    <div className="tw-flex tw-flex-col tw-items-start tw-justify-start ">
                      <FontAwesomeIcon
                        icon={faGasPump}
                        className="tw-text-icon-gray tw-w-4 tw-h-4"
                      />
                    </div>
                    <div className="tw-flex tw-flex-col tw-items-start tw-justify-start ">
                      <p className="tw-flex-1 tw-relative tw-tracking-[-0.02em] tw-mb-0 tw-leading-4">
                        {`${FUEL.properties.DIESEL.display_name} total : ${
                          details?.tour_refueling?.find(
                            (el) => el.fuel === FUEL.DIESEL
                          )?.value ?? 0
                        } L`}
                      </p>
                      <p className="tw-flex-1 tw-relative tw-tracking-[-0.02em] tw-mb-0 tw-leading-4">
                        {`${FUEL.properties.GNC.display_name} total : ${
                          details?.tour_refueling?.find(
                            (el) => el.fuel === FUEL.GNC
                          )?.value ?? 0
                        } kg`}
                      </p>
                      <p className="tw-flex-1 tw-relative tw-tracking-[-0.02em] tw-mb-0 tw-leading-4">
                        {`${FUEL.properties.ADBLUE.display_name} : ${
                          details?.tour_refueling?.find(
                            (el) => el.fuel === FUEL.ADBLUE
                          )?.value ?? 'Non renseigné'
                        }`}
                      </p>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>

          {/* Localisation des étapes block */}
          {false && (
            <div className="tw-flex tw-flex-col tw-pb-0 tw-text-base tw-text-text-dark-high-importance">
              {/* section header */}
              <div className="tw-flex tw-flex-col tw-pb-6 tw-text-base tw-text-text-dark-high-importance">
                <div className="tw-relative tw-font-medium">
                  Localisation des étapes
                </div>
                <div className="tw-relative tw-text-smi tw-leading-[15px] tw-text-text-dark-medium-importance">
                  Localisation des étapes de la tournées sur la carte
                </div>
              </div>
              {/* map */}
              <div className="tw-self-stretch tw-h-[300px] tw-rounded-3xs tw-bg-white tw-box-border tw-overflow-x-auto tw-shrink-0 tw-flex tw-flex-row tw-items-start tw-justify-start tw-pt-[68.9px] tw-px-[387px] tw-pb-[61px] tw-gap-[98.9px] tw-text-smi tw-text-white tw-font-montserrat tw-border-[1px] tw-border-solid tw-border-border-primary-strong mq1150:tw-gap-[49px] mq1150:tw-pl-[193px] mq1150:tw-pr-[193px] tw-box-border mq450:tw-pl-5 mq450:tw-pr-5 tw-box-border mq750:tw-gap-[25px] mq750:tw-pl-24 mq750:tw-pr-24">
                <div className="tw-h-[300px] tw-w-[1250px] tw-relative tw-rounded-3xs tw-bg-white tw-box-border tw-shrink-0 tw-hidden tw-border-[1px] tw-border-solid tw-border-border-primary-strong" />
                <img
                  className="tw-h-[272.9px] tw-w-[1226.5px] tw-relative tw-shrink-0 tw-object-contain tw-hidden"
                  alt=""
                  src="/groupe-de-masques-10@2x.png"
                />
              </div>
            </div>
          )}
        </div>
      </section>
    </GenericModal>
  );
}

export default ModalTour;
