import { faRoute } from '@fortawesome/free-solid-svg-icons';
import { TabPanel } from '@headlessui/react';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router-dom';
import useGetKilometersHistory from '../../../api/vehicles/useGetKilometersHistory';
import GenericTableSkeleton from '../../../components/agv-generic-table/genericTableSkeleton';
import Row from '../../../components/agv-generic-table/row';
import computeTourSubtitle from './../../Tour/tour-util.js';

export default function KilometersHistory({ isFocused, vehicleId }) {
  const { data, isLoading, refetch, fetchNextPage, hasMore, filters } =
    useGetKilometersHistory({
      vehicleId,
    });

  const features = useSelector((state) => state.features);

  const navigate = useNavigate();

  useEffect(() => {
    if (!data && isFocused) {
      refetch();
    }
  }, [isFocused]);

  const endResult = Math.min(
    filters.offset + filters.limit,
    data?.pagination_info?.total_items ?? 0
  );

  return (
    <TabPanel
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div
        className="tw-pt-4 tw-flex tw-flex-col"
        style={{
          overflow: 'hidden',
          minHeight: 0,
          flexGrow: 1,
          position: 'relative',
          maxHeight: '500px',
        }}
      >
        {isLoading && !data ? (
          <GenericTableSkeleton />
        ) : data?.items?.length > 0 ? (
          <>
            <div className="tw-pt-6 tw-flex tw-justify-between tw-items-center">
              <div className="tw-text-base tw-leading-4 tw-font-medium tw-text-text-dark-high-importance">
                Historique des kilomètres
              </div>
              <div className="modal-label-description">
                {1}-{endResult} sur {data?.pagination_info?.total_items} au
                total
              </div>
            </div>
            <div
              id="scrollableDiv"
              style={{ height: '100%', overflow: 'auto' }}
            >
              <InfiniteScroll
                dataLength={data?.items?.length ?? 0}
                next={fetchNextPage}
                hasMore={hasMore}
                loader={<GenericTableSkeleton numberOfRows={1} />}
                scrollableTarget="scrollableDiv"
              >
                {data?.items?.length > 0 &&
                  data?.items?.map((element, index) => (
                    <Row
                      key={`${element?.tour_key}-${index}`}
                      icon={faRoute}
                      iconColor={'tw-text-checklist-common'}
                      onClick={() =>
                        navigate('/tours?query=' + element?.tour_key, {})
                      }
                      buttonText={'Détails'}
                      title={`Tour ${element?.tour_key}`}
                      subtitle={computeTourSubtitle(features, element)}
                      fields={[
                        { name: 'Chauffeur', value: element?.fullname },
                        {
                          name: 'Kilomètres départ',
                          value: `${element?.last_kilometers ? `${element.last_kilometers} km` : '-'}`,
                        },
                        {
                          name: 'Kilomètres arrivé',
                          value: `${element?.inputted_kilometers ? `${element.inputted_kilometers} km` : '-'}`,
                        },
                        {
                          name: 'Distance',
                          value: `${element?.inputted_kilometers && element?.last_kilometers ? `${element.inputted_kilometers - element?.last_kilometers} km` : '-'}`,
                        },
                      ]}
                    />
                  ))}
              </InfiniteScroll>
            </div>
          </>
        ) : (
          <div className="modal-label-title">
            Aucun kilométrage n'a été trouvé pour ce véhicule.
          </div>
        )}
      </div>
    </TabPanel>
  );
}
