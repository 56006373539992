import React from 'react';

export default function RadioButton({ isActive, text, onClick, style }) {
  return (
    <div
      onClick={onClick}
      className={`${style} ${isActive ? 'tw-bg-main tw-text-white tw-border-main-active' : 'tw-bg-input-background tw-border-input-border'} tw-border-solid tw-border tw-rounded-lg tw-text-sm tw-px-3 tw-py-1.5 tw-mr-2 tw-cursor-pointer`}
    >
      {text}
    </div>
  );
}
