import axios from 'axios';

const fleetApi = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    Authorization: `Bearer ${localStorage.getItem('fleetToken')}`,
    'Content-Type': 'application/json',
  },
});

export default fleetApi;
