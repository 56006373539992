import { ReactComponent as ChickenIcon } from './images/chicken.svg';
import { ReactComponent as CowIcon } from './images/cow.svg';
import { ReactComponent as OligoIcon } from './images/drop.svg';
import { ReactComponent as SeedIcon } from './images/oligo.svg';
import { ReactComponent as PhytoIcon } from './images/phyto.svg';
import { ReactComponent as PickingIcon } from './images/picking.svg';
import { ReactComponent as PigIcon } from './images/pig.svg';
import { ReactComponent as QuestionIcon } from './images/question.svg';
import { ReactComponent as SacIcon } from './images/sac.svg';
import { ReactComponent as VracIcon } from './images/vrac.svg';

export const ALIMENT_TYPE_ENUM = {
  PORC: 'PORC',
  BOVIN: 'BOVIN',
  VOLAILLE: 'VOLAILLE',
  PORC_1ST_AGE: 'PORC 1ER AGE',
  PHYTO: 'PH',
  SEMENCES: 'SE',
  MATERIEL: 'AE',
  OLIGO: 'OL',
  EV: 'EV',
  PICKING: 'PICKING',
  VRAC: 'VRAC',
  SAC: 'SAC',
  AUTRES: 'AUTRES',
};
export const ALIMENT_TYPE_PROPS = {
  //Général
  [ALIMENT_TYPE_ENUM.AUTRES]: {
    backgroundColor: 'tw-bg-aliment-green/10',
    borderColor: 'tw-border-aliment-green/15',
    textColor: 'tw-text-aliment-green',
    pictogram: QuestionIcon,
  },
  //Cooperl
  [ALIMENT_TYPE_ENUM.PORC]: {
    backgroundColor: 'tw-bg-aliment-rose/10',
    borderColor: 'tw-border-aliment-rose/15',
    textColor: 'tw-text-aliment-rose',
    pictogram: PigIcon,
  },
  [ALIMENT_TYPE_ENUM.BOVIN]: {
    backgroundColor: 'tw-bg-aliment-purple/10',
    borderColor: 'tw-border-aliment-purple/15',
    textColor: 'tw-text-aliment-purple',
    pictogram: CowIcon,
  },
  [ALIMENT_TYPE_ENUM.VOLAILLE]: {
    backgroundColor: 'tw-bg-aliment-yellow/10',
    borderColor: 'tw-border-aliment-yellow/15',
    textColor: 'tw-text-aliment-yellow',
    pictogram: ChickenIcon,
  },
  [ALIMENT_TYPE_ENUM.PORC_1ST_AGE]: {
    backgroundColor: 'tw-bg-aliment-cian/10',
    borderColor: 'tw-border-aliment-cian/15',
    textColor: 'tw-text-aliment-cian',
    pictogram: PigIcon,
  },
  //Sévéal
  [ALIMENT_TYPE_ENUM.PHYTO]: {
    backgroundColor: 'tw-bg-aliment-cian/10',
    borderColor: 'tw-border-aliment-cian/15',
    textColor: 'tw-text-aliment-cian',
    pictogram: PhytoIcon,
  },
  [ALIMENT_TYPE_ENUM.OLIGO]: {
    backgroundColor: 'tw-bg-aliment-yellow/10',
    borderColor: 'tw-border-aliment-yellow/15',
    textColor: 'tw-text-aliment-yellow',
    pictogram: OligoIcon,
  },
  [ALIMENT_TYPE_ENUM.SEMENCES]: {
    backgroundColor: 'tw-bg-aliment-green/10',
    borderColor: 'tw-border-aliment-green/15',
    textColor: 'tw-text-aliment-green',
    pictogram: SeedIcon,
  },
  [ALIMENT_TYPE_ENUM.MATERIEL]: {
    backgroundColor: 'tw-bg-aliment-blue/10',
    borderColor: 'tw-border-aliment-blue/15',
    textColor: 'tw-text-aliment-blue',
    pictogram: PickingIcon,
  },
  [ALIMENT_TYPE_ENUM.EV]: {
    backgroundColor: 'tw-bg-aliment-orange/10',
    borderColor: 'tw-border-aliment-orange/15',
    textColor: 'tw-text-aliment-orange',
    pictogram: QuestionIcon,
  },
  //Moulins Dumée
  [ALIMENT_TYPE_ENUM.VRAC]: {
    backgroundColor: 'tw-bg-aliment-blue/10',
    borderColor: 'tw-border-aliment-blue/15',
    textColor: 'tw-text-aliment-blue',
    pictogram: VracIcon,
  },
  [ALIMENT_TYPE_ENUM.SAC]: {
    backgroundColor: 'tw-bg-aliment-orange/10',
    borderColor: 'tw-border-aliment-orange/15',
    textColor: 'tw-text-aliment-orange',
    pictogram: SacIcon,
  },
  [ALIMENT_TYPE_ENUM.PICKING]: {
    backgroundColor: 'tw-bg-aliment-green/10',
    borderColor: 'tw-border-aliment-green/15',
    textColor: 'tw-text-aliment-green',
    pictogram: PickingIcon,
  },
};
