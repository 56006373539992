import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { Button, Col, Form, Row } from 'react-bootstrap';

import {
  faEnvelope,
  faExclamationCircle,
  faLocationDot,
  faMessageBot,
  faTruckFast,
} from '@fortawesome/pro-duotone-svg-icons';
import { jwtDecode } from 'jwt-decode';
import { useDispatch, useSelector } from 'react-redux';

import biBG from '../../assets/images/landing/illustration/heroFleet2.png';

import farmer from '../../assets/images/landing/illustration/driver.png';
import flotte from '../../assets/images/landing/illustration/Intersection 3_imac2013_left.png';
import dashboard from '../../assets/images/landing/illustration/web_dashboard.png';
import webHistory from '../../assets/images/landing/illustration/web_history.png';
import webImport from '../../assets/images/landing/illustration/web_import.png';
import webReserves from '../../assets/images/landing/illustration/web_reserves.png';
import tours from '../../assets/images/landing/illustration/web_tours.png';

import appCmr from '../../assets/images/landing/illustration/phone-cmr.png';
import appDesinfection from '../../assets/images/landing/illustration/phone-desinfection.png';
import appEtape from '../../assets/images/landing/illustration/phone-etape.png';
import appPhoto from '../../assets/images/landing/illustration/phone-photo.png';
import appScan from '../../assets/images/landing/illustration/phone-scnaok.png';
import appSign from '../../assets/images/landing/illustration/phone-sign.png';

import vracPhoto from '../../assets/images/landing/illustration/cooperl.jpg';
import delivPhoto from '../../assets/images/landing/illustration/livraisonSeveal.jpeg';
import citernePhoto from '../../assets/images/landing/illustration/talian.jpg';

import capvert from '../../assets/images/landing/clients/capvert.jpg';
import ceresia from '../../assets/images/landing/clients/ceresia.png';
import cooperl from '../../assets/images/landing/clients/cooperl.png';
import novagrain from '../../assets/images/landing/clients/novagrain.svg';
import seveal from '../../assets/images/landing/clients/seveal.png';
import talian from '../../assets/images/landing/clients/talian.jpg';

import agrivitech from '../../assets/images/landing/logos/logoAgrivitech.png';
import fleetBrand from '../../assets/images/logos/FleetWhite.png';

import { ROLE_ENUM } from '../../enums/role-enum.enum';

import { faImage } from '@fortawesome/free-regular-svg-icons';
import {
  faChartPie,
  faCheckDouble,
  faCloudArrowUp,
  faFileAlt,
  faLink,
  faMobileButton,
  faQrcode,
  faSignature,
  faWarning,
} from '@fortawesome/free-solid-svg-icons';
import useGetValidateRegistrationToken from '../../api/auth/useGetValidateRegistrationToken';
import usePostSetupPassword from '../../api/auth/usePostSetupPassword';
import { setConfiguration, setFeatures } from '../../reducer/actions';
import './login.scss';
import RegisterInviteModal from './register-invite-modal';

export default function Login() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const REGISTRATION_TOKEN = new URLSearchParams(location?.search).get(
    'invited_user'
  );

  const [isOpenRegisterInvite, setIsOpenRegisterInvite] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [errorStatus, setErrorStatus] = useState(false);
  const [imageBO, setImageBO] = useState(dashboard);
  const [imageApp, setImageApp] = useState(appEtape);

  const BASE_URL = useSelector((state) => state.BASE_URL);

  const { data, getValidateRegistrationToken, isSuccess } =
    useGetValidateRegistrationToken();

  const {
    isLoading,
    setupPassword,
    isSuccess: isSuccessSetupPassword,
  } = usePostSetupPassword();

  useEffect(() => {
    if (REGISTRATION_TOKEN) {
      getValidateRegistrationToken(REGISTRATION_TOKEN);
    }
  }, [REGISTRATION_TOKEN]);

  useEffect(() => {
    if (isSuccess) {
      setIsOpenRegisterInvite(true);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isSuccessSetupPassword) {
      setIsOpenRegisterInvite(false);
      setEmail(data?.tokenData?.email);
    }
  }, [isSuccessSetupPassword]);

  const handleMouseEnter = (item, position) => {
    if (position === 'BO') {
      setImageBO(item);
    } else if (position === 'APP') {
      setImageApp(item);
    }
  };

  const handleSuccessfulLogin = (token, clientId, decoded) => {
    localStorage.setItem('fleetToken', token);
    localStorage.setItem('fleetClientId', clientId);

    dispatch(
      setConfiguration({
        is_admin: decoded?.user?.roles?.includes(ROLE_ENUM.ADMIN),
        is_super_admin: decoded?.user?.roles?.includes(ROLE_ENUM.SUPER_ADMIN),
        can_upload_tour_with_excel:
          decoded?.client?.features?.can_upload_tour_with_excel,
        can_access_dashboard_v2:
          decoded?.client?.features?.can_access_dashboard_v2,
        fleet_optimization: decoded?.client?.features?.fleet_optimization,
        logo_url: decoded?.client?.logo_url,
        client_id: decoded?.user?.client_id,
      })
    );
    dispatch(setFeatures(decoded.client.features));

    // Redirection après que tout soit bien configuré
    window.location.href = decoded?.client?.features?.can_access_dashboard_v2
      ? '/'
      : '/vehicle';
  };

  const login = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`${BASE_URL}/auth/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          password,
        }),
      });

      const result = await response.json();

      if (response.ok && result.token) {
        const decoded = result.token ? jwtDecode(result.token) : null;
        if (decoded) {
          handleSuccessfulLogin(result.token, result.clientId, decoded);
        }
        setEmail('');
        setPassword('');
      } else if (response.status === 401 || response.status === 404) {
        setErrorMessage(result?.message || 'Mot de passe ou e-mail incorrect.');
        setErrorStatus(true);
      } else if (response.status === 400) {
        setErrorMessage("Une erreur s'est produite");
        setErrorStatus(true);
      } else {
        setErrorMessage(
          result?.message ||
            'Une erreur réseau est survenue, veuillez contacter le support client.'
        );
        setErrorStatus(true);
      }
    } catch (error) {
      console.error("Il y a eu un problème avec l'opération fetch:", error);
      setErrorMessage('Une erreur réseau est survenue, veuillez réessayer.');
      setErrorStatus(true);
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  // Vérification de l'auth au montage du composant
  useEffect(() => {
    const token = localStorage.getItem('fleetToken');
    if (token) {
      try {
        const decoded = jwtDecode(token);
        navigate(
          decoded?.client?.features?.can_access_dashboard_v2 ? '/' : '/vehicle'
        );
      } catch (error) {
        // Token invalide, on le supprime
        localStorage.removeItem('fleetToken');
        localStorage.removeItem('fleetClientId');
      }
    }
  }, [navigate]);

  return (
    <>
      <div className="mainContainer">
        <div className="bigHeader" style={{ backgroundImage: `url(${biBG})` }}>
          <div className="container">
            <img src={agrivitech} alt="logo" style={{ marginBottom: 30 }} />
          </div>
          <div className="container headerContainer">
            <Col sm={12} md={7}>
              <h1 className="mainTitle">
                {' '}
                TMS <span className="color">pensé pour le</span> monde agricole{' '}
              </h1>
              <p className="bold subMainTitle">
                Notre mission est d'accompagner la transition numérique,
                l’automatisation et la sécurisation du transport dans le secteur
                agricole.
              </p>
              {/* <img src={mapMarker} className="mapMarker" alt="logo" /> */}
            </Col>
            <Col></Col>
            <Col sm={12} md={4}>
              <Form>
                <div className={'error-message-div'}>
                  <div
                    style={{
                      display: errorStatus ? 'block' : 'none',
                    }}
                    className={'error-msg-login'}
                  >
                    <FontAwesomeIcon icon={faExclamationCircle} />{' '}
                    {errorMessage}
                  </div>
                </div>
                <Form.Label className="label-2">Adresse email</Form.Label>
                <Form.Control
                  className="custom-input-login"
                  type="email"
                  placeholder="Votre adresse email"
                  autoComplete="username"
                  onChange={handleEmailChange}
                  defaultValue={email}
                  aria-describedby="basic-addon1"
                />
                <Form.Label className="label-2">Mot de passe</Form.Label>
                <Form.Control
                  className="custom-input-login"
                  type="password"
                  placeholder="Votre mot de passe"
                  autoComplete="current-password"
                  onChange={handlePasswordChange}
                  defaultValue={password}
                  aria-describedby="basic-addon2"
                />

                <div
                  className="d-flex justify-content-between align-items-center"
                  style={{ width: '100%' }}
                >
                  <Link to="/forgot">
                    <p className="password-forget mt-4 mb-0">
                      Mot de passe oublié ?
                    </p>
                  </Link>
                  <Button
                    variant=""
                    type="submit"
                    onClick={login}
                    className={'btn-login mt-4 '}
                    block="true"
                    disabled={email === '' || password === ''}
                  >
                    Connexion
                  </Button>
                </div>
              </Form>

              <div className="d-flex justify-content-between">
                <Link to="https://www.agrivitech.com/contact" target="blank">
                  <p className="password-forget mt-4 mb-0 bold">
                    Nouveau client ? Cliquez ici pour demander un accès.
                  </p>
                </Link>
              </div>
            </Col>
          </div>
        </div>

        <div className="container">
          <div className="clientsHeader">
            <h1 className="text-center bigTitle mb-5">
              Les coopératives et négoces qui utilisent nos solutions
            </h1>

            <div className="text-center brandCloud">
              <img src={seveal} alt="logo" className="img" height={40} />
              <img src={cooperl} alt="logo" className="img" height={40} />
              <img src={novagrain} alt="logo" className="img" height={40} />
              <img src={capvert} alt="logo" className="img" height={40} />
              <img src={talian} alt="logo" className="img" height={40} />
              <img src={ceresia} alt="logo" className="img" height={40} />
            </div>

            <div className="mt-5 bigLine text-center">
              Plus de 150 000 livraisons effectuées et plus de 150 chauffeurs
              gagnent du temps avec Fleet
            </div>
          </div>

          <div className="useCases">
            <div className="hugeTitle">
              Trouvez la solution pensée pour vous <br />
            </div>
            <div className="subTitle">
              Fleet permet de répondre aux problématiques liées au transport
              dans différents cas d'usage du monde agricole
            </div>

            <Row>
              <Col>
                <div className="useCaseCard">
                  <img src={delivPhoto} className="useCasePhoto" alt="flotte" />
                  <h3 className="bigLine">
                    <strong>Livraison sécurisée et autonome</strong> de produits
                    phytosanitaires avec Fleet AgriVitech
                  </h3>
                  Avec notre système de QR Code Fleet, les produits sont livrés
                  en toute sécurité dans des locaux pré-identifiés. Le chauffeur
                  dépose les produits, scanne le QR Code pour confirmer la
                  livraison et prend une photo horodatée et géolocalisée. Cette
                  dernière est envoyée instantanément au destinataire qui peut
                  donc vérifier que ses produits ont bien été livrés, et ce,
                  même à distance.
                </div>
              </Col>
              <Col>
                <div className="useCaseCard">
                  <img
                    src={citernePhoto}
                    className="useCasePhoto"
                    alt="flotte"
                  />
                  <h3 className="bigLine">
                    <strong>
                      Calcul automatique du remplissage de camions citernes
                    </strong>{' '}
                    pour des tournées de livraisons et de chargement de matières
                    premières combinées.
                  </h3>
                  Livraison d'aliments liquides pour élevages : optimisez vos
                  tournées et chargements avec Fleet AgriVitech. Notre solution
                  TMS vous permet de gagner en efficacité et en flexibilité dans
                  le calcul des tournées et le remplissage des camions citernes.
                  Grâce à la capacité de recalculer les tournées à la volée et
                  de les mettre à jour, vous bénéficiez d'une logistique plus
                  performante et plus adaptable.
                </div>
              </Col>
              <Col>
                <div className="useCaseCard">
                  <img src={vracPhoto} className="useCasePhoto" alt="flotte" />
                  <h3 className="bigLine">
                    Livraison de vrac dans des silos : assure la{' '}
                    <strong>traçabilité</strong> et l'
                    <strong>efficacité</strong>
                  </h3>
                  Notre solution TMS permet au chauffeur de scanner le QR code
                  sur le silo pour une livraison précise, et d'enregistrer
                  toutes les étapes de la tournée (livraison, reprise,
                  transfert, nettoyage, désinfection, etc.). Bénéficiez d'une
                  logistique optimisée et d'une traçabilité totale pour vos
                  livraisons de vrac."
                </div>
              </Col>
            </Row>
          </div>

          <div className="mt-5 mb-5">
            <Row className="imgRow">
              <Col sm={12} md={7} className="">
                <div className="hugeTitle">Back Office</div>
                <div className="lines">
                  <div className="line">
                    Gagner du temps dans la planification des tournées
                  </div>
                  <div className="line">
                    Être réactif pendant l’exécution des livraisons
                  </div>
                  <div className="line">
                    Récupérer les documents complétés et signés
                  </div>
                  <div className="line">
                    Consulter l’historique des livraisons
                  </div>
                </div>
                <img src={imageBO} className="carou" alt="logo" />
              </Col>
              <Col sm={12} md={5}>
                <Row>
                  <div
                    className="featCard"
                    onMouseEnter={() => handleMouseEnter(dashboard, 'BO')}
                  >
                    <div className="d-flex">
                      <FontAwesomeIcon
                        className="iconLarge"
                        icon={faChartPie}
                      />
                      <div>
                        <div className="title">SUIVI DE VOS TRANSPORTS</div>
                        <div className="content">
                          Suivi des couts, des temps, des émissions et des
                          kilométrages pour chacun des véhicules internes ou
                          externes.
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="featCard"
                    onMouseEnter={() => handleMouseEnter(tours, 'BO')}
                  >
                    <div className="d-flex">
                      <FontAwesomeIcon
                        className="iconLarge"
                        icon={faTruckFast}
                      />
                      <div>
                        <div className="title">GESTION DES TOURNÉES</div>
                        <div className="content">
                          Modifiez en temps réel une tournée planifiée et gagnez
                          en réactivité sans perdre en traçabilité ni perturber
                          la tournée du chauffeur.
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="featCard"
                    onMouseEnter={() => handleMouseEnter(webReserves, 'BO')}
                  >
                    <div className="d-flex">
                      <FontAwesomeIcon className="iconLarge" icon={faWarning} />
                      <div>
                        <div className="title">GESTION DES ANOMALIES</div>
                        <div className="content">
                          Remontée en temps réelle des non-conformités
                          horodatées et géolocalisées.
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="featCard"
                    onMouseEnter={() => handleMouseEnter(webImport, 'BO')}
                  >
                    <div className="d-flex">
                      <FontAwesomeIcon className="iconLarge" icon={faLink} />
                      <div>
                        <div className="title">CONNECTEURS SI</div>
                        <div className="content">
                          Echanges de données par fichiers XLS, CSV, XML, JSON…
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="featCard"
                    onMouseEnter={() => handleMouseEnter(webHistory, 'BO')}
                  >
                    <div className="d-flex">
                      <FontAwesomeIcon
                        className="iconLarge"
                        icon={faCloudArrowUp}
                      />
                      <div>
                        <div className="title">HISTORIQUE ILLIMITÉ</div>
                        <div className="content">
                          Pas de limite d’archivage, ni en volume, ni en date
                        </div>
                      </div>
                    </div>
                  </div>
                </Row>
              </Col>
            </Row>
          </div>

          <div className="mt-5 mb-5">
            <Row className="imgRow">
              <Col sm={12} md={6}>
                <Row>
                  <div
                    className="featCard"
                    onMouseEnter={() => handleMouseEnter(appEtape, 'APP')}
                  >
                    <div className="d-flex">
                      <FontAwesomeIcon
                        className="iconLarge"
                        icon={faMobileButton}
                      />
                      <div>
                        <div className="title">Tout dans une seule app</div>
                        <div className="content">
                          Cartographie, documents de transport, commentaires de
                          livraison, réserves, saisie des kilomètres,
                          signature... Plus besoin de papier ni de re-saisie.
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="featCard"
                    onMouseEnter={() => handleMouseEnter(appPhoto, 'APP')}
                  >
                    <div className="d-flex">
                      <FontAwesomeIcon className="iconLarge" icon={faImage} />
                      <div>
                        <div className="title">LIVRAISON SANS PRESENCE</div>
                        <div className="content">
                          Validation des livraisons par prise de photo de la
                          marhandise. La photo est géolocalisée, horodatée et
                          instantanément partagée au back office et au
                          destinataire.
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="featCard"
                    onMouseEnter={() => handleMouseEnter(appScan, 'APP')}
                  >
                    <div className="d-flex">
                      <FontAwesomeIcon className="iconLarge" icon={faQrcode} />
                      <div>
                        <div className="title">SCAN DES LIEUX DE LIVRAISON</div>
                        <div className="content">
                          Contrôle des silos, locaux, compartiments, boxes,
                          station etc… lors des livraisons par un simple scan
                          couplé à la géolocalisation, pour ne plus se tromper
                          d'adresse ni de produit.
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="featCard"
                    onMouseEnter={() =>
                      handleMouseEnter(appDesinfection, 'APP')
                    }
                  >
                    <div className="d-flex">
                      <FontAwesomeIcon
                        className="iconLarge"
                        icon={faCheckDouble}
                      />
                      <div>
                        <div className="title">
                          Enregistrez tout ce qui se passe
                        </div>
                        <div className="content">
                          Suivi des actions de chargement, livraison, nettoyage,
                          désinfection etc. en temps réel et géolocalisé.
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="featCard"
                    onMouseEnter={() => handleMouseEnter(appCmr, 'APP')}
                  >
                    <div className="d-flex">
                      <FontAwesomeIcon className="iconLarge" icon={faFileAlt} />
                      <div>
                        <div className="title">DOCUMENTS DÉMATÉRIALISÉS</div>
                        <div className="content">
                          Documents de transports (eCMR, BLs, fiches de
                          sécurité, risque sanitaire, etc…) dématérialisés et
                          complétés automatiquement
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="featCard"
                    onMouseEnter={() => handleMouseEnter(appSign, 'APP')}
                  >
                    <div className="d-flex">
                      <FontAwesomeIcon
                        className="iconLarge"
                        icon={faSignature}
                      />
                      <div>
                        <div className="title">SIGNATURE ÉLECTRONIQUE</div>
                        <div className="content">
                          Signature électronique probante des documents de
                          transports. Tous les documents d’une tournée signés en
                          une seule fois.
                        </div>
                      </div>
                    </div>
                  </div>
                </Row>
              </Col>
              <Col sm={12} md={6} className="text-end">
                <div className="hugeTitle">Application chauffeur</div>
                <div className="lines left">
                  <div className="line">
                    Avoir toute sa tournée dans une seule application
                  </div>
                  <div className="line">
                    Visualiser les étapes avec photo des lieux de dépose
                  </div>
                  <div className="line">
                    Contacter le destinataire en un clic
                  </div>
                  <div className="line">
                    Remonter les anomalies en temps réel
                  </div>
                  <div className="line">Se passer des documents papier</div>
                </div>
                <img src={imageApp} className="carou" alt="logo" />
              </Col>
            </Row>
          </div>

          <div className="hugeTitle mt-100">
            Réduisez l'empreinte carbone graçe aux fonctionalités de
            l'application Fleet®
          </div>

          <div className="mt-5">
            <Row className="imgRow">
              <Col className="coloredBanner">
                <div className="bigTitle">
                  Optimisez et suivez automatiquement vos tournées en temps réel
                </div>
                <p>
                  Application mobile pour l’exécution de la tournée du livreur
                  Réduction de l’impact carbone des livraisons Notification en
                  temps réel par sms, email, notification push, etc... Preuve de
                  livraison par signature ou photo géolocalisée
                  Dématérialisation des documents de livraison UX co-développée
                  avec des chauffeurs pour une expérience optimale
                </p>
              </Col>
              <Col sm={12} md={6}>
                <div className="background-img d-flex align-items-center justify-content-center">
                  <img src={flotte} className="carou" alt="flotte" />
                </div>
              </Col>
            </Row>
          </div>

          <Row className="imgRow">
            <Col className="coloredBanner">
              <p className="bigTitle">
                Moins de kilomètres, moins de temps de livraison, moins de
                papier !
              </p>
              <ul>
                <li>
                  Moins de kilomètres : l'application réduit le nombre de
                  retours plateforme en permettant de livrer sans la présence de
                  l'agriculteur.
                </li>
                <li>
                  Moins de temps sur place : en prévenant les destinataires
                  avant l'arrivée vous réduisez le temps de livraison
                </li>
                <li>Moins de papier : fin des bons de livraison papier</li>
              </ul>
            </Col>
            <Col sm={12} md={6}>
              <img src={farmer} className="carou" alt="logo" />
            </Col>
          </Row>
        </div>

        <div className="footer">
          <div className="brandRow">
            <img src={fleetBrand} alt="logo" className="img" height={40} />{' '}
            {'  '} par {'  '}{' '}
            <img src={agrivitech} alt="logo" className="img" height={40} />{' '}
          </div>
          <div>
            <FontAwesomeIcon icon={faLocationDot} /> 17 rond-point de l'Europe{' '}
            <br />
            51430 Bezannes
          </div>

          <div>
            <FontAwesomeIcon icon={faEnvelope} /> contact@agrivitech.com
          </div>
          <div>
            <FontAwesomeIcon icon={faMessageBot} />{' '}
            <a href="https://discord.gg/t5GE2wTDSk">
              https://discord.gg/t5GE2wTDSk
            </a>{' '}
          </div>
        </div>
      </div>
      <RegisterInviteModal
        isOpen={isOpenRegisterInvite}
        setIsOpen={setIsOpenRegisterInvite}
        invitedUser={data?.tokenData}
        token={REGISTRATION_TOKEN}
        isLoading={isLoading}
        setupPassword={setupPassword}
      />
    </>
  );
}

const checkAuth = () => {
  return localStorage.getItem('fleetToken');
};
