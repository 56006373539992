import { faFileArrowDown } from '@fortawesome/free-solid-svg-icons';
import { TabPanel } from '@headlessui/react';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import SkeletonLoader from 'tiny-skeleton-loader-react';
import useGetVehicleDetails from '../../../api/vehicles/useGetVehicleDetails';
import useGetVehicleQrCode from '../../../api/vehicles/useGetVehicleQrCode';
import usePutVehiclePin from '../../../api/vehicles/usePutVehiclePin';
import MainButton from '../../../components/agv-button';
import Otp from '../../../components/agv-forms-otp';
import VehicleContainers from '../../../components/fleet-vehicle-truck-containers';
import OtpSkeleton from '../../../components/agv-forms-otp';

export default function Management({ isFocused, vehicleId, vehicleKey }) {
  const clientFeatures = useSelector((state) => state.features);

  const {
    data,
    isLoading,
    refetch,
    error: errorGetVehicleDetails,
  } = useGetVehicleDetails({
    vehicleId,
  });

  const {
    data: dataQrCode,
    isLoading: isLoadingQrCode,
    error: errorLoadingQrCode,
  } = useGetVehicleQrCode({
    vehicleId,
  });

  const { isLoading: isLoadingChangePin, putVehiclePin } = usePutVehiclePin();

  useEffect(() => {
    if (!data && isFocused) {
      refetch();
    }
  }, [isFocused]);

  const downloadQrCode = async () => {
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(dataQrCode);
    link.setAttribute('download', `qrcode_camion_${vehicleKey}.png`);
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <TabPanel>
      <div className="tw-mt-8">
        <div className="modal-label-group">
          <div className="modal-label-title">Code pin</div>
          <div className="modal-label-description">
            Consultez et modifiez le code pin pour ce camion
          </div>
        </div>

        <div className="tw-mt-4 tw-flex">
          {isLoading ? (
            <OtpSkeleton />
          ) : (
            <Otp
              value={data?.management?.pin}
              onPinComplete={(pin) => putVehiclePin(pin, vehicleId)}
              isLoading={isLoadingChangePin}
              isError={errorGetVehicleDetails ? true : false}
            />
          )}
        </div>
      </div>

      {clientFeatures?.vehicle_containers ? (
        <div className="tw-mt-8">
          <div className="modal-label-group">
            <div className="modal-label-title">Chargement du véhicule</div>
            <div className="modal-label-description">
              Retrouvez le remplissage des cases du camion
            </div>
          </div>

          <div className="tw-mt-4 tw-flex">
            <VehicleContainers containers={data?.vehicle_current_status} />
          </div>
        </div>
      ) : null}

      {clientFeatures?.can_vehicle_authenticate_with_qr_code ? (
        <div className="tw-mt-8">
          <div className="modal-label-group">
            <div className="modal-label-title">
              Authentification via QR code
            </div>
            <div className="modal-label-description">
              {`Dernière génération du QR code pour ce camion : ${data?.management?.created_at ? moment(data.management.created_at).format('DD MMMM YYYY [à] HH:mm') : '.nc'}`}
            </div>
          </div>

          <div className="tw-mt-4 tw-flex">
            {isLoadingQrCode ? (
              <SkeletonLoader
                width={200}
                height={25}
                style={{ borderRadius: 4 }}
              />
            ) : (
              <MainButton
                text={'Télécharger le QR code de camion'}
                icon={faFileArrowDown}
                disabled={!dataQrCode}
                onClick={downloadQrCode}
              />
            )}
          </div>
        </div>
      ) : null}
    </TabPanel>
  );
}
