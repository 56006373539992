import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { forwardRef } from 'react';
import classNames from 'classnames';

const CustomDatePickerDashboard = forwardRef(({ value = '', onClick }, ref) => {
  const dates = value.split(' - ').map((date) => date.trim());
  const hasValidDates =
    dates.length === 2 && dates.every((date) => date !== '');

  // Define labels
  const startDate = dates[0] || 'Sélectionnez une date';
  const endDate = hasValidDates ? dates[1] : "Aujourd'hui";

  // If no date is selected, display only "Select a date".
  const displayText =
    startDate === 'Sélectionnez une date'
      ? startDate
      : `${startDate} - ${endDate}`;

  return (
    <button
      ref={ref}
      className={classNames(
        'header-button-style',
        'tw-py-2',
        'tw-px-4',
        {
          'tw-pr-8': startDate !== 'Sélectionnez une date'
        }
      )}
      style={{
        boxShadow: '0px 4px 2px 0px rgba(255, 255, 255, 0.1) inset',
        lineHeight: '20px',
        borderRadius: '5px',
      }}
      onClick={onClick}
    >
      <FontAwesomeIcon
        className={classNames(
          'tw-size-2.5',
          'tw-text-text-dark-high-importance',
          'tw-mr-2'
        )}
        icon={faCalendarAlt}
      />
      <span>{displayText}</span>
    </button>
  );
});

CustomDatePickerDashboard.displayName = 'CustomDatePickerDashboard';

export default React.memo(CustomDatePickerDashboard);
