import { useEffect, useState } from 'react';
import fleetApi from '../../services/api.service';
import { buildUrlWithParams } from '../../utils/url.utils';
import moment from 'moment';

let timeout = null;
export default function useGetDashboard() {
  const [dashboardData, setDashboardData] = useState(null);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isFetched, setIsFetched] = useState(false);

  const [filters, setFilters] = useState({
    factory_name: '',
    date: new Date(),
  });

  useEffect(() => {
    if (isFetched || filters?.query?.length) {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        getData();
      }, 500);
    }
  }, [filters]);

  useEffect(() => {
    getData();
  }, []);

  const updateFilter = (key, value) => {
    setFilters((prevFilters) => {
      const newFilters = { ...prevFilters, [key]: value };
      // Reset offset to 0 if any filter other than offset changes
      if (key !== 'offset') {
        newFilters.offset = 0;
      }
      return newFilters;
    });
  };

  const getData = async () => {
    try {
      setIsLoading(true);

      const { date, ...restFilters } = filters;
      const params = {
        ...restFilters,
        ...(date && {
          date: moment(date).format('YYYY-MM-DD'),
        }),
      };

      const url = buildUrlWithParams('/dashboard', params);

      const response = await fleetApi.get(url);

      if (isFetched) {
        await new Promise((resolve) => setTimeout(resolve, 150));
      }

      if (response.status === 200) {
        setDashboardData(response.data);
      } else {
        throw new Error();
      }
    } catch (error) {
      setError(error?.message || 'Une erreur est survenue.');
    } finally {
      setIsLoading(false);
      setIsFetched(true);
    }
  };

  return {
    dashboardData,
    error,
    isLoading,
    isFirstLoading: isLoading && !isFetched,
    refetch: getData,
    filters,
    updateFilter,
  };
}
