import { BL_STATUS, PACKAGING_TYPE } from '../enums/bl.enum';
import { DELIVERY_DETAIL_TYPE } from '../enums/delivery-detail.enum';

// Compute rows for a delivery detail(bl) item
export function computeRowForOneDeliveryDetail(bl, item) {
  let deliveryArray = [];

  // ~ item is VRAC
  if (item.packaging === PACKAGING_TYPE.VRAC) {
    // ~ delivery_type is REPRISE
    if (bl.delivery_type == DELIVERY_DETAIL_TYPE.REPRISE) {
      // ~ BL is PLANNED
      if (bl.status == BL_STATUS.PLANNED) {
        deliveryArray.push({
          what: 'Case (?)',
          where: `Silo ${item?.silo_to_reclaim}`,
          where_substitute: null,
          checked: false,
          invert_arrow: true,
        });
      }
      // ~ BL is DELILVERED
      if (bl.status == BL_STATUS.DELIVERED) {
        item?.compartments?.split?.(',')?.forEach((comp) => {
          deliveryArray.push({
            what: `Case ${comp}`,
            where: `Silo ${item?.silo_to_reclaim}`,
            where_substitute: null,
            checked: true,
            invert_arrow: true,
          });
        });
      }
    }

    if (
      bl.delivery_type == DELIVERY_DETAIL_TYPE.LIVRAISON ||
      bl.delivery_type === DELIVERY_DETAIL_TYPE['LIVRAISON-VIA-TRANSFERT-SILO']
    ) {
      item?.compartments?.split?.(',')?.forEach((comp) => {
        deliveryArray.push({
          what: `Case ${comp}`,
          where: `Silo ${formatSilosNew(item)}`,
          where_substitute: checkHasPlanned(item)
            ? item?.silos_delivered
            : null,
          checked: bl.status !== BL_STATUS.PLANNED,
          invert_arrow: false,
        });
      });
    }

    if (bl.delivery_type == DELIVERY_DETAIL_TYPE['TRANSFERT-SILO']) {
      deliveryArray.push({
        what: `Silo ${item.silo_to_reclaim ? item.silo_to_reclaim : '?'}`,
        where: `Silo ${item?.silo_to_transfer ? item.silo_to_transfer : '?'}`,
        where_substitute: checkHasPlanned(item) ? item?.silos_delivered : null,
        checked: bl.status !== BL_STATUS.PLANNED,
        invert_arrow: false,
      });
    }

    if (bl.delivery_type == DELIVERY_DETAIL_TYPE.TRANSVIDAGE) {
      if (bl.status == BL_STATUS.PLANNED) {
        item?.compartments?.split?.(',')?.forEach((comp) => {
          deliveryArray.push({
            what: 'Case (?)',
            where: `Case ${comp}`,
            where_substitute: null,
            checked: false,
            invert_arrow: true,
          });
        });
      }

      // ~ BL is DELILVERED
      if (bl.status == BL_STATUS.DELIVERED) {
        item?.compartments?.split?.(',')?.forEach((comp) => {
          console.log(item);
          deliveryArray.push({
            what: `Case ${item.transferred_in_containers}`,
            where: `Case ${comp}`,
            where_substitute: null,
            checked: true,
            invert_arrow: true,
          });
        });
      }
    }

    if (bl.delivery_type == DELIVERY_DETAIL_TYPE.GARDER) {
      item?.compartments?.split?.(',')?.forEach((comp) => {
        deliveryArray.push({
          what: `Stocké en`,
          where: `Case ${comp}`,
          where_substitute: null,
          checked: bl.status !== BL_STATUS.PLANNED,
          invert_arrow: false,
        });
      });
    }
  }

  // ~ item is in Coffre à sacs
  if (item.packaging === PACKAGING_TYPE['SAC']) {
    item?.compartments?.split?.(',')?.forEach((comp) => {
      deliveryArray.push({
        what: `${item?.quantity ?? 0} sac${item?.quantity > 1 ? 's' : ''}`,
        where: PACKAGING_TYPE['COFFRE'],
        where_substitute: null,
        checked: bl.status !== BL_STATUS.PLANNED,
        invert_arrow: false,
      });
    });
  }

  return deliveryArray;
}

function formatSilosNew(item) {
  const silos = [
    item?.silo_1?.length ? item.silo_1 : '?',
    item?.silo_2?.length ? item.silo_2 : '',
    item?.silo_3?.length ? item.silo_3 : '',
  ].filter(Boolean);

  return silos.join(', ');
}

function checkHasPlanned(item) {
  return (
    item?.silos_delivered &&
    item?.silos_delivered !== item.silo_1 &&
    item?.silos_delivered !== item.silo_2 &&
    item?.silos_delivered !== item.silo_3
  );
}
