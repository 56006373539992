import 'moment/locale/fr';

import {
  reserve_status,
  reserves_types,
} from '../../enums/reserve-status.enum';

import { faClipboard } from '@fortawesome/free-regular-svg-icons';

import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
  faArrowDownShortWide,
  faArrowDownWideShort,
  faFilter,
} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { useNavigate, useSearchParams } from 'react-router-dom';

import useGetRequest from '../../api/useGetRequest';
import { ORDER_BY_ENUM } from '../../enums/orderby.enum';
import { buildUrlWithParams } from '../../utils/url.utils';

import { useSelector } from 'react-redux';
import EmptyList from '../../components/agv-empty-list';
import GenericPageContainer from '../../components/agv-generic-page-container';
import GenericTable from '../../components/agv-generic-table';
import GenericTableSkeleton from '../../components/agv-generic-table/genericTableSkeleton';
import SearchFilter from '../../components/agv-header-search';
import SelectFilter from '../../components/agv-header-select';
import AgrivitechPagination from '../../components/agv-pagination';
import { STATUS_ENUM } from '../../components/agv-status/status-enum';
import ModalReserve from './ModalReserve';
import { useInfiniteScroll } from '../../hooks/useInfiniteScroll';
import InfiniteScroll from 'react-infinite-scroll-component';
import './reserves.css';

export default function Reserves() {
  const [searchParams] = useSearchParams();

  const features = useSelector((state) => state.features);

  const [modalHandleReserve, setModalHandleReserve] = useState(false);
  const [selectedReserve, setSelectedReserve] = useState(null);
  const initialFilters = {
    limit: 24,
    offset: 0,
    query: searchParams.get('query') || '',
    orderBy:
      searchParams.get('orderBy') === ORDER_BY_ENUM.ASC ||
      searchParams.get('orderBy') === ORDER_BY_ENUM.DESC
        ? searchParams.get('orderBy')
        : ORDER_BY_ENUM.DESC,
    ...(searchParams.get('status') &&
      (searchParams.get('status') === reserve_status.CREATED ||
        searchParams.get('status') === reserve_status.SOLVED) && {
        status: searchParams.get('status'),
      }),
  };

  const {
    items,
    hasMore,
    filters,
    fetchMoreData,
    handleUpdateParam,
    isLoading,
    mutate,
  } = useInfiniteScroll({
    initialFilters,
    debounceTime: 300,
    getRequest: useGetRequest,
    endpoint: '/reserves',
  });

  const onOpenModal = (element) => {
    setSelectedReserve(element);
    setModalHandleReserve(true);
  };

  const returnReserveSubtitle = (reserve) => {
    const stringParts = [];
    if (reserve?.tour_key) {
      const tourKey = reserve.tour_key;
      if (features?.tour_number_only_last_digit) {
        stringParts.push(`Tour ${tourKey.slice(-1)}`);
      } else {
        stringParts.push(`Tour ${tourKey}`);
      }
    }

    if (reserve?.vehicle_key) {
      stringParts.push(`Camion ${reserve.vehicle_key}`);
    }

    if (reserve?.receiver_name) {
      stringParts.push(`Client ${reserve?.receiver_name}`);
    }

    return stringParts.join(' - ');
  };

  return (
    <>
      <GenericPageContainer
        title={'Gestion des réserves'}
        subtitle={"Retrouvez l'historique des réserves et leurs détails."}
        filters={
          <>
            <SearchFilter
              value={filters.query}
              setValue={(value) => handleUpdateParam('query', value)}
              disabled={isLoading}
            />
            <SelectFilter
              value={filters.orderBy}
              onChange={(value) => handleUpdateParam('orderBy', value)}
              options={[
                { value: ORDER_BY_ENUM.DESC, name: 'Desc.' },
                { value: ORDER_BY_ENUM.ASC, name: 'Asc.' },
              ]}
              icon={
                filters.orderBy === ORDER_BY_ENUM.DESC
                  ? faArrowDownWideShort
                  : faArrowDownShortWide
              }
            />
            <SelectFilter
              value={filters.status}
              onChange={(value) => handleUpdateParam('status', value)}
              options={[
                {
                  value: '',
                  name: reserve_status?.properties?.all?.display_name,
                },
                {
                  value: reserve_status.CREATED,
                  name: reserve_status?.properties?.created?.display_name,
                },
                {
                  value: reserve_status.SOLVED,
                  name: reserve_status?.properties?.solved?.display_name,
                },
              ]}
              icon={faFilter}
            />
            <SelectFilter
              value={filters.type}
              onChange={(value) => handleUpdateParam('type', value)}
              options={[
                {
                  value: '',
                  name: reserves_types?.properties?.all?.display_name,
                },
                {
                  value: reserves_types.RESERVE,
                  name: reserves_types?.properties?.RESERVE?.display_name,
                },
                {
                  value: reserves_types.RESERVE_CLIENT,
                  name: reserves_types?.properties?.RESERVE_CLIENT
                    ?.display_name,
                },
                {
                  value: reserves_types['UNABLE-TO-DELIVER'],
                  name: reserves_types?.properties['UNABLE-TO-DELIVER']
                    ?.display_name,
                },
                {
                  value: reserves_types.CHECKLIST,
                  name: reserves_types?.properties?.CHECKLIST?.display_name,
                },
                {
                  value: reserves_types.TOUR,
                  name: reserves_types?.properties?.TOUR?.display_name,
                },
              ]}
              icon={faFilter}
            />
          </>
        }
      >
        {isLoading && filters.offset === 0 ? (
          <GenericTableSkeleton numberOfRows={10} hasTitle />
        ) : items?.length > 0 ? (
          <InfiniteScroll
            dataLength={items.length}
            next={fetchMoreData}
            hasMore={hasMore}
            loader={<GenericTableSkeleton numberOfRows={3} hasTitle />}
            scrollableTarget="page-content"
            hasChildren={items.length > 0}
            endMessage={
              <p className="agrivitech-pagination-text tw-mb-2 tw-mt-8 tw-text-center">
                Vous avez vu toutes les donneés pour cette recherche
              </p>
            }
          >
            {items.map((date) => (
              <GenericTable
                title={
                  new Date(date.date).toLocaleDateString('fr-FR', {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                  }) || "Pas d'information de date"
                }
                isLoading={isLoading}
                key={`date_${date?.date}`}
                rows={date.items.map((element) => ({
                  title: element.message || 'Aucun message à afficher.',
                  subtitle: returnReserveSubtitle(element),
                  onClick: () => onOpenModal(element),
                  buttonText: 'Détails',
                  status:
                    element?.status == 'CREATED'
                      ? STATUS_ENUM.WARN
                      : STATUS_ENUM.POSITIVE,
                  icon: faClipboard,
                  statusText:
                    element?.status == 'CREATED' ? 'A traiter' : 'Résolue',
                  fields: [
                    {
                      name: 'Client',
                      value: element?.receiver_name
                        ? `${element?.receiver_name}`
                        : '-',
                    },
                    {
                      name: 'Provenance',
                      value:
                        reserves_types?.properties[element?.process_linked]
                          ?.display_name,
                    },
                  ],
                }))}
                iconColor={'tw-text-checklist-common'}
              />
            ))}
          </InfiniteScroll>
        ) : (
          <EmptyList />
        )}
      </GenericPageContainer>
      {/* Modals */}
      <ModalReserve
        returnReserveSubtitle={returnReserveSubtitle}
        show={modalHandleReserve}
        onHide={(isReserveUpdated) => {
          setModalHandleReserve(false);
          setSelectedReserve(null);
          if (isReserveUpdated) {
            mutate();
          }
        }}
        reserve_id={selectedReserve?.id}
      />
    </>
  );
}
