import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import './modal-onboarding.scss';

const ModalOnBoarding = (props) => {
  return (
    <Modal dialogClassName="remove-padding" {...props}>
      <Modal.Header className="modal-help-header" closeButton>
        <iframe
          className="video-background"
          src="https://www.youtube.com/embed/gtJAR77cZ_c"
          title="Tutoriel fleet optimisation"
          frameborder="0"
          autoplay="1"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        ></iframe>
      </Modal.Header>
      <Modal.Body className="modal-help-body">
        <div className="badge-onboarding">
          <span className="badge-title">Démo</span>
        </div>
        <h4 className="title mt-3">Tutoriel fleet optimisation</h4>
        <p className="text mb-0">
          Notre tutoriel Youtube vous accompagnera dans la découverte de
          l'ensemble des outils proposés par l'optimisation Fleet. Suivez le
          guide !
        </p>
      </Modal.Body>
      <Modal.Footer className="modal-help-footer ml-auto">
        <Button
          variant="secondary"
          className="modal-btn-footer"
          onClick={props.onHide}
        >
          Retour
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalOnBoarding;
