import { faTruck } from '@fortawesome/free-solid-svg-icons';
import { Tab, TabGroup, TabList, TabPanels } from '@headlessui/react';
import React, { useEffect, useState } from 'react';
import GenericModal from '../../components/agv-generic-modal';
import Row from '../../components/agv-generic-table/row';
import { MODAL_SIZE, MODAL_SIZE_STYLE } from '../../enums/modal-size.enum';
import { STATUS_ENUM } from '../../components/agv-status/status-enum';
import KilometersHistory from './panels/kilometers-history';
import Management from './panels/management';

export default function VehicleModal({ isOpen, setIsOpen, vehicle }) {
  const [focusedTabIndex, setFocusedTabIndex] = useState(0);

  return (
    <GenericModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title={`Détails du véhicule ${vehicle?.vehicule_key}`}
      mainAction={() => setIsOpen(false)}
      mainActionText={'Retour'}
      size={MODAL_SIZE_STYLE[MODAL_SIZE.XXL]}
    >
      <div className="tw-flex tw-flex-col tw-border-solid tw-border-0 tw-border-b tw-border-background tw-pb-2">
        <Row
          title={`Camion ${vehicle?.vehicule_key}`}
          subtitle={`${vehicle?.vehicle_transporter_type || '.nc'} - Immat. ${vehicle?.imat || '.nc'}`}
          iconColor={'tw-text-checklist-common'}
          icon={faTruck}
          fields={[
            { name: 'Livraisons', value: vehicle?.delivered_steps },
            {
              name: 'Distance totale',
              value: vehicle?.last_kilometers_inputted
                ? `${vehicle?.last_kilometers_inputted} km`
                : '-',
            },
          ]}
          status={
            vehicle?.has_tour_today ? STATUS_ENUM.POSITIVE : STATUS_ENUM.NEUTRAL
          }
          statusText={vehicle?.has_tour_today ? 'En tournée' : 'Stationné'}
        />
      </div>

      <TabGroup
        onChange={setFocusedTabIndex}
        defaultIndex={0}
        style={{ minHeight: 0, display: 'flex', flexDirection: 'column' }}
      >
        <TabList
          className={
            'tw-w-full tw-rounded-md tw-py-2 tw-px-2 tw-bg-slategray-default tw-border tw-border-solid tw-space-x-4 tw-border-[#dfe3e6]'
          }
        >
          <Tab className="tw-rounded-md tw-border-0 tw-text-sm tw-py-1.5 tw-px-3 tw-bg-transparent data-[selected]:tw-bg-main data-[selected]:tw-text-white">
            Historique des kilomètres
          </Tab>
          <Tab className="tw-rounded-md tw-border-0 tw-text-sm tw-py-1.5 tw-px-3 tw-bg-transparent data-[selected]:tw-bg-main data-[selected]:tw-text-white">
            Gestion du véhicule
          </Tab>
        </TabList>
        <TabPanels
          style={{
            minHeight: 0,
            overflow: 'hidden',
          }}
        >
          <KilometersHistory
            vehicleId={vehicle?.vehicule_id}
            isFocused={focusedTabIndex === 0}
          />
          <Management
            vehicleId={vehicle?.vehicule_id}
            vehicleKey={vehicle?.vehicule_key}
            isFocused={focusedTabIndex === 1}
          />
        </TabPanels>
      </TabGroup>
    </GenericModal>
  );
}
