import { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { buildUrlWithParams } from '../utils/url.utils';
import moment from 'moment';

export function useInfiniteScroll({
  initialFilters,
  debounceTime = 300,
  getRequest,
  endpoint,
}) {
  const timeoutRef = useRef(null); // Ref pour le timeout
  const navigate = useNavigate();
  const [items, setItems] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [filters, setFilters] = useState(initialFilters);
  const [url, setUrl] = useState(() =>
    buildUrlWithParams(endpoint, initialFilters)
  );

  /**
   * Build the URL with the current filters and navigate to the new URL
   * @returns {void}
   */
  useEffect(() => {
    const params = new URLSearchParams(filters);
    navigate({ search: params.toString() }, { replace: true });
    setUrl(buildUrlWithParams(endpoint, filters));
  }, [filters, endpoint]);

  /**
   * Update the URL with the current filters
   * @param {string} key - The key to update
   * @param {string} value - The value to update
   * @returns {void}
   */
  const handleUpdateParam = useCallback((key, value) => {
    setFilters((prevFilters) => {
      const newFilters = { ...prevFilters, [key]: value };

      if (key !== 'offset') {
        newFilters.offset = 0;
      }

      if (key === 'start_date' || key === 'end_date') {
        if (value) {
          newFilters[key] = moment(value).format('YYYY-MM-DD');
        } else {
          delete newFilters[key];
        }
      }

      if (
        key === 'status' &&
        (value === null || value === undefined || value === '')
      ) {
        delete newFilters.status;
      }

      return newFilters;
    });
  }, []);

  /**
   * Fetch the data from the API
   * @returns {void}
   */
  const { data, isLoading, mutate } = getRequest(url, debounceTime);

  /**
   * Fetch more data when the user scrolls to the bottom of the list
   * Only if not already loading
   * @returns {void}
   */
  const fetchMoreData = () => {
    if (!isLoading && hasMore) {
      handleUpdateParam('offset', filters.offset + filters.limit);
    }
  };

  /**
   * Detect the scroll and fetch more data
   * @returns {void}
   */
  useEffect(() => {
    const container = document.getElementById('page-content');
    if (!container || !hasMore || isLoading) return;

    // Clear le timeout existant avant d'en créer un nouveau
    if (timeoutRef.current) clearTimeout(timeoutRef.current);

    timeoutRef.current = setTimeout(() => {
      const containerHeight = container.clientHeight;
      const contentHeight = container.scrollHeight;

      if (contentHeight <= containerHeight) {
        fetchMoreData();
      }
    }, 500);

    return () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, [hasMore, isLoading]);

  /**
   * Set the items when the data is fetched and avoid duplicates
   * @returns {void}
   */
  useEffect(() => {
    if (data?.items) {
      if (filters.offset === 0) {
        document.getElementById('page-content')?.scrollTo({
          top: 0,
          behavior: 'instant',
        });
        setItems(data.items);
      } else {
        setItems((prevItems) => {
          const newItems = data.items;
          return [...prevItems, ...newItems];
        });
      }

      setHasMore(
        data.pagination_info?.current_page < data.pagination_info?.total_pages
      );
    }
  }, [data]);

  return {
    items,
    hasMore,
    filters,
    isLoading,
    handleUpdateParam,
    fetchMoreData,
    url,
    data,
    mutate,
  };
}
