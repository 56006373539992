import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Select } from '@headlessui/react';
import React from 'react';
import classNames from 'classnames';

const themes = {
  default: {
    select: '',
    boxShadow: '0px 4px 2px 0px rgba(255, 255, 255, 0.1) inset',
    icon: 'tw-text-text-dark-high-importance',
    option: '',
  },
  alternative: {
    select: 'tw-text-text-light-high-importance tw-border-white/20',
    boxShadow: 'none',
    icon: 'tw-text-text-light-high-importance',
    option: 'tw-text-text-dark-high-importance',
  },
};

export default function SelectFilter({
  options,
  onChange,
  value,
  icon,
  theme = 'default',
  width, // Suppression de la valeur par défaut
}) {
  const currentTheme = themes[theme] || themes.default;

  const selectClassName = classNames(
    'tw-pr-10 header-button-style',
    { 'tw-pl-10': icon },
    { 'tw-pl-4': !icon },
    currentTheme.select
  );

  const iconClassName = classNames(
    'tw-group tw-pointer-events-none tw-absolute tw-top-1/3 tw-right-4 tw-size-2.5',
    currentTheme.icon
  );

  return (
    <div className="tw-relative">
      <Select
        className={selectClassName}
        name="validation-steps-filters"
        aria-label="Validation steps filters"
        value={value}
        onChange={(event) => onChange(event.nativeEvent.target.value)}
        style={{
          boxShadow: currentTheme.boxShadow,
          lineHeight: '20px',
          borderRadius: '5px',
          ...(width && { width }),
        }}
      >
        {options.map((option) => (
          <option
            key={option.value}
            value={option.value}
            className={currentTheme.option}
          >
            {option.name}
          </option>
        ))}
      </Select>
      <FontAwesomeIcon className={iconClassName} icon={faChevronDown} />
      {icon && (
        <FontAwesomeIcon
          className={classNames(
            'tw-group tw-pointer-events-none tw-absolute tw-top-1/3 tw-left-4 tw-size-2.5',
            currentTheme.icon
          )}
          icon={icon}
        />
      )}
    </div>
  );
}
