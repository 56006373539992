import { faCircleXmark } from '@fortawesome/free-regular-svg-icons';
import {
  faAnglesDown,
  faCircleExclamation,
  faWarning,
} from '@fortawesome/free-solid-svg-icons';

export const reserve_status = {
  ALL: '',
  CREATED: 'CREATED',
  SOLVED: 'SOLVED',
  properties: {
    all: {
      display_name: 'Statut',
    },
    created: {
      display_name: 'À traiter',
      color: '#FA983A',
    },
    solved: {
      display_name: 'Résolue',
      color: '#6B6B6B',
    },
  },
};

export const reserves_types = {
  ALL: '',
  RESERVE: 'RESERVE',
  RESERVE_CLIENT: 'RESERVE_CLIENT',
  'UNABLE-TO-DELIVER': 'UNABLE-TO-DELIVER',
  CHECKLIST: 'CHECKLIST',
  TOUR: 'TOUR',
  null: null,
  properties: {
    all: {
      display_name: 'Type',
    },
    RESERVE: {
      display_name: 'Réserves',
      color: 'orange',
      icon: faCircleExclamation,
    },
    RESERVE_CLIENT: {
      display_name: 'Réserve client',
      color: 'orange',
      icon: faCircleExclamation,
    },
    'UNABLE-TO-DELIVER': {
      display_name: 'Livraison impossible',
      color: 'red',
      icon: faCircleXmark,
    },
    CHECKLIST: {
      display_name: 'Check list',
      color: '#6B6B6B',
      icon: faWarning,
    },
    TOUR: {
      display_name: 'Signalement tournée',
      color: '#C2C2C2',
      icon: faAnglesDown,
    },
    null: {
      display_name: 'Réserve',
      color: '#C2C2C2',
      icon: faAnglesDown,
    },
  },
};
