import { faCheck } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment/moment';
import React, { useState } from 'react';
import GenericModal from '../../components/agv-generic-modal';
import Row from '../../components/agv-generic-table/row';
import InputCombobox from '../../components/agv-forms-combobox/input-combobox';
import InputRadio from '../../components/agv-forms-radio';
import InputText from '../../components/agv-forms-input';
import {
  CHECKLIST_ICONS_ARRAY,
  CHECKLIST_ICONS_DISPLAY,
  CHECKLIST_ICONS_DISPLAY_NAME,
  CHECKLIST_TYPE,
} from '../../enums/checklist-enum.enum';
import { STATUS_ENUM } from '../../components/agv-status/status-enum';
import TextArea from '../../components/agv-forms-textarea';

export default function ValidationStepModal({
  isOpen,
  setIsOpen,
  validationStep,
  putValidationStep,
  mainActionIsLoading,
}) {
  const [stepTitle, setStepTitle] = useState(validationStep?.title);
  const [stepDescription, setStepDescription] = useState(
    validationStep?.description
  );
  const [stepIcon, setStepIcon] = useState(validationStep?.icon);
  const [stepType, setStepType] = useState(validationStep?.type);
  const [stepIsArchived, setStepIsArchived] = useState(
    validationStep?.is_archived === 1
  );

  const ICON_COLOR =
    validationStep?.type === CHECKLIST_TYPE.COMMON
      ? 'tw-text-checklist-common'
      : 'tw-text-checklist-specific';

  return (
    <GenericModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title={'Étape de validation'}
      mainAction={() =>
        putValidationStep(
          validationStep?.id,
          stepTitle,
          stepDescription,
          stepType,
          stepIcon,
          stepIsArchived
        )
      }
      mainActionText={'Enregistrer'}
      mainActionIsLoading={mainActionIsLoading}
      mainActionDisabled={
        !stepTitle?.length || !stepDescription?.length || !stepIcon
      }
    >
      <Row
        title={validationStep?.title}
        subtitle={moment(new Date(validationStep?.created_at)).format(
          '[Modifié le] DD MMM YYYY'
        )}
        icon={CHECKLIST_ICONS_DISPLAY[validationStep?.icon] ?? faCheck}
        iconColor={ICON_COLOR}
        status={
          validationStep?.is_archived
            ? STATUS_ENUM.NEGATIVE
            : STATUS_ENUM.POSITIVE
        }
        statusText={validationStep?.is_archived ? 'Inactif' : 'Actif'}
        style={
          'tw-px-0 tw-border-solid tw-border-0 tw-border-b tw-border-background tw-py-4'
        }
      />

      <div className="tw-flex tw-flex-col tw-pb-6">
        <InputText
          value={stepTitle}
          setValue={setStepTitle}
          fieldTitle={"Titre de l'étape"}
          isRequired
          emptyError={`Le champ "Titre de l'étape" ne peut être vide.`}
          style={'tw-mt-6'}
        />
        <TextArea
          value={stepDescription}
          setValue={setStepDescription}
          fieldTitle={'Message à afficher'}
          isRequired
          emptyError={`Le champ "Message à afficher" ne peut être vide.`}
          style={'tw-mt-6'}
        />
        <InputCombobox
          value={stepIcon}
          setValue={setStepIcon}
          fieldTitle={'Icône'}
          items={CHECKLIST_ICONS_ARRAY.map((element) => ({
            name: CHECKLIST_ICONS_DISPLAY_NAME[element],
            icon: CHECKLIST_ICONS_DISPLAY[element],
            value: element,
          }))}
          hasIcon
          iconColor={ICON_COLOR}
          isRequired
          emptyError={`Le champ "Icône" ne peut être vide.`}
          style={'tw-mt-6'}
        />
        <InputRadio
          fieldTitle={"Catégorie de l'étape"}
          value={stepType}
          setValue={setStepType}
          style={'tw-mt-6'}
          items={[
            { value: 'COMMON', name: 'Commune' },
            { value: 'SPECIFIC', name: 'Spécifique' },
          ]}
        />
        <InputRadio
          fieldTitle={'État'}
          value={stepIsArchived}
          setValue={setStepIsArchived}
          style={'tw-mt-6'}
          items={[
            { value: false, name: 'Activé' },
            { value: true, name: 'Désactivé' },
          ]}
        />
        {/* <InputText
          value={validationStep?.filed_name}
          fieldTitle={'Nom de la colonne du fichier'}
          disabled
          style={'tw-mt-6'}
        />
        <InputText
          value={validationStep?.filed_value}
          fieldTitle={'Valeur de la colonne pour afficher le message'}
          disabled
          style={'tw-mt-6'}
        /> */}
      </div>
    </GenericModal>
  );
}
