import React from 'react';
import './kpi.scss';
const Kpi = ({ kpi_data }) => {
  const { value, text, sub_text } = kpi_data || {};
  return (
    <div className="kpiHeader tw-box-border tw-h-32 tw-p-5 tw-rounded tw-bg-white">
      <div className="bigValue tw-mb-2">{value != undefined ? value : ''}</div>
      <div className="strongValue tw-mb-2">
        {text ? text : 'Aucune donnée trouvé'}
      </div>
      <div className="lightValue">{sub_text ? sub_text : ''}</div>
    </div>
  );
};

export default React.memo(Kpi);
