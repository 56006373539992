import React from 'react';
import './bar-chart.scss';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

const BarChartComponent = ({ bar_chart_data }) => {
  const { text, fields, datas } = bar_chart_data || {};

  const Bars = fields?.map((fieldObject, index) => (
    <Bar
      key={index}
      dataKey={fieldObject.key}
      stackId="a"
      fill={fieldObject.color}
    />
  ));

  return (
    <>
      <div className="tw-p-5 tw-flex tw-justify-between ">
        <div className=" tw-font-semibold tw-text-xs">{text ? text : ''}</div>
      </div>

      <ResponsiveContainer width="100%" height="80%" debounce={2}>
        <BarChart
          data={datas}
          margin={{
            top: 20,
            right: 30,
            left: 0,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" vertical={false} />
          <XAxis dataKey="name" />
          <YAxis
            type="number"
            domain={['0', 'dataMax']}
            allowDecimals={false}
          />
          <Tooltip />
          <Legend
            layout="horizontal"
            align="center"
            verticalAlign="bottom"
            iconSize={10}
            formatter={(value, entry, index) => (
              <span className="tw-text-xs tw-font-semibold">{value}</span>
            )}
          />
          {Bars}
        </BarChart>
      </ResponsiveContainer>
    </>
  );
};

export default React.memo(BarChartComponent);
