import {
  faArrowDownShortWide,
  faArrowDownWideShort,
  faFilter,
  faRoute,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState, useCallback, useMemo } from 'react';
import './tour.css';

import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { tour_status } from '../../enums/tour-status';

import moment from 'moment';
import ReactDatePicker from 'react-datepicker';
import useGetRequest from '../../api/useGetRequest';
import EmptyList from '../../components/agv-empty-list';
import GenericPageContainer from '../../components/agv-generic-page-container';
import GenericTable from '../../components/agv-generic-table';
import GenericTableSkeleton from '../../components/agv-generic-table/genericTableSkeleton';
import CustomDatePickerDashboard from '../../components/agv-header-date-picker';
import SearchFilter from '../../components/agv-header-search';
import SelectFilter from '../../components/agv-header-select';
import { ORDER_BY_ENUM } from '../../enums/orderby.enum';
import isDateValid from '../../utils/is-date-valid.util';
import { buildUrlWithParams } from '../../utils/url.utils';
import ModalTour from './ModalTour';
import computeTourSubtitle from './tour-util';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useInfiniteScroll } from '../../hooks/useInfiniteScroll';
moment.locale('fr');

export default function Tour() {
  const [searchParams] = useSearchParams();
  const features = useSelector((state) => state.features);
  const [isOpen, setIsOpen] = useState(false);
  const [tourSelected, setTourSelected] = useState(null);

  const initialFilters = {
    limit: 24,
    offset: 0,
    query: searchParams.get('query') || '',
    orderBy:
      searchParams.get('orderBy') === ORDER_BY_ENUM.ASC ||
      searchParams.get('orderBy') === ORDER_BY_ENUM.DESC
        ? searchParams.get('orderBy')
        : ORDER_BY_ENUM.DESC,
    ...(searchParams.get('start_date') &&
      isDateValid(searchParams.get('start_date')) && {
        start_date: moment(new Date(searchParams.get('start_date'))).format(
          'YYYY-MM-DD'
        ),
      }),
    ...(searchParams.get('end_date') &&
      isDateValid(searchParams.get('end_date')) && {
        end_date: moment(new Date(searchParams.get('end_date'))).format(
          'YYYY-MM-DD'
        ),
      }),
    ...(searchParams.get('status') &&
      (searchParams.get('status') === tour_status.CREATED ||
        searchParams.get('status') === tour_status.STARTED ||
        searchParams.get('status') === tour_status.ISSUE ||
        searchParams.get('status') === tour_status.DONE ||
        searchParams.get('status') === tour_status.IMPORTED) && {
        status: searchParams.get('status'),
      }),
  };

  const {
    items,
    hasMore,
    filters,
    fetchMoreData,
    handleUpdateParam,
    isLoading,
    mutate,
  } = useInfiniteScroll({
    initialFilters,
    debounceTime: 300,
    getRequest: useGetRequest,
    endpoint: '/tours',
  });

  /**
   * Open the modal with the selected element
   * @param {object} element - The selected element
   * @returns {void}
   */
  const onOpenModal = (element) => {
    setTourSelected(element);
    setIsOpen(true);
  };

  /**
   * Handle the change of the date filter
   * @param {array} dates - The selected dates
   * @returns {void}
   */
  const onChangeDate = (dates) => {
    const [start, end] = dates;
    handleUpdateParam('start_date', start);
    handleUpdateParam('end_date', end);
  };

  /**
   * Refresh the tour list when the modal is closed
   * @returns {void}
   */
  const refreshTourList = useCallback(() => {
    mutate();
  }, [mutate]);

  return (
    <>
      <GenericPageContainer
        title={'Liste des tournées'}
        subtitle={'Retrouvez la liste de vos tournées et leurs détails.'}
        filters={
          <>
            <SearchFilter
              value={filters.query}
              setValue={(value) => handleUpdateParam('query', value)}
              disabled={isLoading}
            />
            <div className="tw-relative">
              <ReactDatePicker
                selected={filters.start_date}
                onChange={onChangeDate}
                customInput={<CustomDatePickerDashboard />}
                startDate={
                  filters.start_date ? new Date(filters.start_date) : null
                }
                endDate={filters.end_date ? new Date(filters.end_date) : null}
                selectsRange
                locale="fr"
                dateFormat="dd MMM yyyy"
                isClearable
                className="custom-datepicker"
                dayClassName={date =>
                  (filters.start_date && date.toDateString() === new Date(filters.start_date).toDateString()) ||
                  (filters.end_date && date.toDateString() === new Date(filters.end_date).toDateString())
                    ? "highlighted-date"
                    : undefined
                }
              />
            </div>
            <SelectFilter
              value={filters.orderBy}
              onChange={(value) => handleUpdateParam('orderBy', value)}
              options={[
                { value: ORDER_BY_ENUM.DESC, name: 'Desc.' },
                { value: ORDER_BY_ENUM.ASC, name: 'Asc.' },
              ]}
              icon={
                filters.orderBy === ORDER_BY_ENUM.DESC
                  ? faArrowDownWideShort
                  : faArrowDownShortWide
              }
            />
            <SelectFilter
              value={filters.status}
              onChange={(value) => handleUpdateParam('status', value)}
              options={[
                {
                  value: '',
                  name: tour_status?.properties[tour_status.ALL]?.display_name,
                },
                {
                  value: tour_status.CREATED,
                  name: tour_status?.properties[tour_status.CREATED]
                    ?.display_name,
                },
                {
                  value: tour_status.STARTED,
                  name: tour_status?.properties[tour_status.STARTED]
                    ?.display_name,
                },
                {
                  value: tour_status.ISSUE,
                  name: tour_status?.properties[tour_status.ISSUE]
                    ?.display_name,
                },
                {
                  value: tour_status.DONE,
                  name: tour_status?.properties[tour_status.DONE]?.display_name,
                },
                {
                  value: tour_status.IMPORTED,
                  name: tour_status?.properties[tour_status.IMPORTED]
                    ?.display_name,
                },
              ]}
              icon={faFilter}
            />
          </>
        }
      >
        {isLoading && filters.offset === 0 ? (
          <GenericTableSkeleton numberOfRows={10} hasTitle />
        ) : items?.length > 0 ? (
          <InfiniteScroll
            dataLength={items.length}
            next={fetchMoreData}
            hasMore={hasMore}
            loader={<GenericTableSkeleton numberOfRows={3} hasTitle />}
            scrollableTarget="page-content"
            hasChildren={items.length > 0}
            endMessage={
              <p className="agrivitech-pagination-text tw-mb-2 tw-mt-8 tw-text-center">
                Vous avez vu toutes les donneés pour cette recherche
              </p>
            }
          >
            {items.map((element) => (
              <GenericTable
                key={`tour-${element.date}-${element.items
                  .map((i) => i.tour_id)
                  .sort()
                  .join('-')}`}
                isLoading={isLoading}
                title={moment(element.date)?.format('D MMMM YYYY')}
                rows={element?.items.map((element) => ({
                  title: `Tour ${element?.tour_key}`,
                  subtitle: computeTourSubtitle(features, element),
                  onClick: () => onOpenModal(element),
                  buttonText: 'Détails',
                  status: tour_status.properties[element.status]?.color,
                  icon: faRoute,
                  statusText: element?.status
                    ? tour_status.properties[element.status]?.display_name
                    : '-',
                  fields: [
                    {
                      name: 'Chauffeur',
                      value: element?.fullname ? `${element?.fullname}` : '-',
                    },
                    {
                      name: 'Poids total',
                      value: element?.vehicle_total_loaded_weight
                        ? `${element?.vehicle_total_loaded_weight}`
                        : '-',
                    },
                    {
                      name: 'Étapes',
                      value: element?.finalized_step_count
                        ? `${element?.finalized_step_count} sur ${element?.total_step_count}`
                        : '-',
                    },
                    {
                      name: 'Km parcourus',
                      value:
                        element?.inputted_kilometers && element?.last_kilometers
                          ? `${Number(element?.inputted_kilometers) - Number(element?.last_kilometers)} km`
                          : '-',
                    },
                    {
                      name: 'Impact CO₂e',
                      value:
                        element?.inputted_kilometers && element?.last_kilometers
                          ? `${((Number(element?.inputted_kilometers) - Number(element?.last_kilometers)) * Number(0.101)).toFixed(2)} kg`
                          : '-',
                    },
                  ],
                }))}
                iconColor={'tw-text-checklist-common'}
              />
            ))}
          </InfiniteScroll>
        ) : (
          <EmptyList />
        )}

        <ModalTour
          tourSelected={tourSelected}
          show={isOpen}
          onHide={(updateTourListOnClose) => {
            setTourSelected(null);
            setIsOpen(false);
            if (updateTourListOnClose) {
              refreshTourList();
            }
          }}
        />
      </GenericPageContainer>
    </>
  );
}
