import { STATUS_ENUM } from '../components/agv-status/status-enum';

export const ROLE_ENUM = {
  OPERATOR: 'OPERATOR',
  ADMIN: 'ADMIN',
  SUPER_ADMIN: 'SUPER-ADMIN',
};

export const ROLE_ENUM_DISPLAY = {
  [ROLE_ENUM.OPERATOR]: 'Opérateur',
  [ROLE_ENUM.ADMIN]: 'Administrateur',
  [ROLE_ENUM.SUPER_ADMIN]: 'Super-Admin',
};

export const ROLE_ENUM_COLOR = {
  [ROLE_ENUM.OPERATOR]: STATUS_ENUM.INFO,
  [ROLE_ENUM.ADMIN]: STATUS_ENUM.WARN,
  [ROLE_ENUM.SUPER_ADMIN]: STATUS_ENUM.NEGATIVE,
};
