import React from 'react';
import SkeletonLoader from 'tiny-skeleton-loader-react';

const Head = () => {
  return (
    <div className="tw-flex tw-bg-white/50 tw-h-[30px]">
      <div className="tw-flex tw-flex-1 tw-border-solid tw-border-background tw-border-0 tw-border-x" />
      <div className="tw-flex tw-flex-1 tw-border-solid tw-border-background tw-border-0 tw-border-x" />
      <div className="tw-flex tw-flex-1 tw-border-solid tw-border-background tw-border-0 tw-border-x" />
      <div className="tw-flex tw-flex-1 tw-border-solid tw-border-background tw-border-0 tw-border-x" />
      <div className="tw-flex tw-flex-1 tw-border-solid tw-border-background tw-border-0 tw-border-x" />
      <div className="tw-flex tw-flex-1 tw-border-solid tw-border-background tw-border-0 tw-border-x" />
      <div className="tw-flex tw-flex-1 tw-border-solid tw-border-background tw-border-0 tw-border-x" />
      <div className="tw-flex tw-flex-1 tw-border-solid tw-border-background tw-border-0 tw-border-x" />
    </div>
  );
};

const Row = () => {
  return (
    <div className="tw-flex tw-bg-white">
      <div className="tw-flex tw-flex-1 tw-px-3 tw-py-1.5 tw-border-solid tw-border-background tw-border-0 tw-border-x">
        <SkeletonLoader width={'100%'} height={30} />
      </div>
      <div className="tw-flex tw-flex-1 tw-px-3 tw-py-1.5 tw-border-solid tw-border-background tw-border-0 tw-border-x">
        <SkeletonLoader width={'100%'} height={30} />
      </div>
      <div className="tw-flex tw-flex-1 tw-px-3 tw-py-1.5 tw-border-solid tw-border-background tw-border-0 tw-border-x">
        <SkeletonLoader width={'100%'} height={30} />
      </div>
      <div className="tw-flex tw-flex-1 tw-px-3 tw-py-1.5 tw-border-solid tw-border-background tw-border-0 tw-border-x">
        <SkeletonLoader width={'100%'} height={30} />
      </div>
      <div className="tw-flex tw-flex-1 tw-px-3 tw-py-1.5 tw-border-solid tw-border-background tw-border-0 tw-border-x">
        <SkeletonLoader width={'100%'} height={30} />
      </div>
      <div className="tw-flex tw-flex-1 tw-px-3 tw-py-1.5 tw-border-solid tw-border-background tw-border-0 tw-border-x">
        <SkeletonLoader width={'100%'} height={30} />
      </div>
      <div className="tw-flex tw-flex-1 tw-px-3 tw-py-1.5 tw-border-solid tw-border-background tw-border-0 tw-border-x">
        <SkeletonLoader width={'100%'} height={30} />
      </div>
      <div className="tw-flex tw-flex-1 tw-px-3 tw-py-1.5 tw-border-solid tw-border-background tw-border-0 tw-border-x">
        <SkeletonLoader width={'100%'} height={30} />
      </div>
    </div>
  );
};

export default function DatatableSkeleton() {
  return (
    <div className="tw-w-full tw-divide-solid tw-divide-x-0 tw-divide-y tw-divide-background">
      <Head />
      <Row />
      <Row />
      <Row />
      <Row />
      <Row />
    </div>
  );
}
