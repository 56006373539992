import { faCheck } from '@fortawesome/free-solid-svg-icons';
import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import usePutValidationStep from '../../api/checklist/usePutValidationStep';
import useGetRequest from '../../api/useGetRequest';
import GenericPageContainer from '../../components/agv-generic-page-container';
import GenericTable from '../../components/agv-generic-table';
import GenericTableSkeleton from '../../components/agv-generic-table/genericTableSkeleton';
import SelectFilter from '../../components/agv-header-select';
import { STATUS_ENUM } from '../../components/agv-status/status-enum';
import {
  CHECKLIST_ICONS_DISPLAY,
  CHECKLIST_TYPE,
} from '../../enums/checklist-enum.enum';
import ValidationStepModal from './validation-step-modal';
import { buildUrlWithParams } from '../../utils/url.utils';

export default function Validation() {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);
  const [validationStep, setValidationStep] = useState(null);
  const [filter, setFilter] = useState('');
  const [filteredData, setFilteredData] = useState(null);

  const {
    data,
    isLoading,
    error: errorGetChecklist,
    mutate,
  } = useGetRequest('/checklist');

  const {
    isSuccess,
    error: errorPutValidationStep,
    isLoading: isLoadingPutValidationStep,
    putValidationStep,
  } = usePutValidationStep();

  // Effet pour charger les données initiales
  // useEffect(() => {
  //   mutate('/checklist');
  // }, [mutate]);

  useEffect(() => {
    if (data) {
      setFilteredData(
        data?.checklist?.filter((element) =>
          filter ? element.type === filter : true
        )
      );
    }
  }, [filter, data]);

  useEffect(() => {
    if (
      searchParams.get('type')?.toUpperCase() === CHECKLIST_TYPE.COMMON ||
      searchParams.get('type')?.toUpperCase() === CHECKLIST_TYPE.SPECIFIC
    ) {
      setFilter(searchParams.get('type').toUpperCase());
    }
  }, [searchParams]);

  useEffect(() => {
    if (isSuccess) {
      setIsOpen(false);
      mutate('/checklist');
    }
  }, [isSuccess, mutate]);

  useEffect(() => {
    if (errorGetChecklist) {
      toast.error(errorGetChecklist);
    }
  }, [errorGetChecklist]);

  useEffect(() => {
    if (errorPutValidationStep) {
      toast.error(errorPutValidationStep);
    }
  }, [errorPutValidationStep]);

  const onOpenModal = (value) => {
    setValidationStep(value);
    setIsOpen(true);
  };

  return (
    <>
      <GenericPageContainer
        title={'Gestion des étapes de validation'}
        subtitle={'Visualisez et configurez le détail des étapes de validation'}
        filters={
          <SelectFilter
            value={filter}
            onChange={setFilter}
            options={[
              { value: '', name: 'Toutes' },
              { value: CHECKLIST_TYPE.COMMON, name: 'Communes' },
              { value: CHECKLIST_TYPE.SPECIFIC, name: 'Spécifiques' },
            ]}
          />
        }
      >
        {isLoading ? (
          <GenericTableSkeleton hasTitle />
        ) : (
          <GenericTable
            title={'Communes'}
            isLoading={isLoading}
            rows={filteredData
              ?.filter((element) => element.type === CHECKLIST_TYPE.COMMON)
              ?.map((element) => ({
                title: element.title,
                subtitle: element.description,
                icon: CHECKLIST_ICONS_DISPLAY[element?.icon] ?? faCheck,
                onClick: () => onOpenModal(element),
                buttonText: 'Détails',
                status: element?.is_archived
                  ? STATUS_ENUM.NEGATIVE
                  : STATUS_ENUM.POSITIVE,
                statusText: element?.is_archived ? 'Inactif' : 'Actif',
              }))}
            iconColor={'tw-text-checklist-common'}
          />
        )}
        {isLoading ? (
          <GenericTableSkeleton hasTitle />
        ) : (
          <GenericTable
            title={'Spécifiques'}
            isLoading={isLoading}
            rows={filteredData
              ?.filter((element) => element.type === CHECKLIST_TYPE.SPECIFIC)
              ?.map((element) => ({
                title: element.title,
                subtitle: element.description,
                icon: CHECKLIST_ICONS_DISPLAY[element?.icon] ?? faCheck,
                onClick: () => onOpenModal(element),
                buttonText: 'Détails',
                status: element?.is_archived
                  ? STATUS_ENUM.NEGATIVE
                  : STATUS_ENUM.POSITIVE,
                statusText: element?.is_archived ? 'Inactif' : 'Actif',
              }))}
            iconColor={'tw-text-checklist-specific'}
          />
        )}
      </GenericPageContainer>
      {isOpen && (
        <ValidationStepModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          validationStep={validationStep}
          putValidationStep={putValidationStep}
          mainActionIsLoading={isLoadingPutValidationStep}
        />
      )}
    </>
  );
}
