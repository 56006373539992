import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import fleetApi from '../../services/api.service';

export default function useDeleteUser() {
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(null);

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error]);

  const deleteUser = async (user_id, client_id) => {
    resetStates();
    try {
      setIsLoading(true);
      const response = await fleetApi.delete(`/users/${user_id}`, {
        data: { client_id },
      });
      await new Promise((resolve) => setTimeout(resolve, 500));
      if (response.status === 200) {
        setIsSuccess(true);
        toast.success(
          response.data?.message || 'Utilisateur supprimé avec succès.'
        );
      } else {
        setIsSuccess(false);
        throw new Error(response.data?.message || 'Une erreur est survenue.');
      }
    } catch (error) {
      setIsSuccess(false);
      toast.error(error?.response.data?.message || error?.message);
    } finally {
      setIsLoading(false);
    }
  };

  const resetStates = () => {
    setError('');
    setIsSuccess(false);
    setIsLoading(false);
  };

  return {
    error,
    isLoading,
    isSuccess,
    deleteUser,
  };
}
