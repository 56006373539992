import React from 'react'
import "./product-reserve.scss"

export default function ProductReserve({product}) {
  
  return (
    <div className='product-container'>
      <div className='left-part'>
        <div></div>
        <div>
          <div className='product-title'>{product.item_name}</div>
          <div className='text'>{product.item_ean}</div>
        </div>
      </div>
      <div className='right-part'>
        <div className='text'>{product.reserve_quantity} sur {product.item_quantity}</div>
        <div className='text'>commandé{product.item_quantity > 1 ? "s" : ""}</div>
      </div>
    </div>
  )
}
