import React from 'react';
import RadioButton from './radio-button';
import FieldTitle from '../agv-forms-title';

export default function InputRadio({
  value,
  setValue,
  fieldTitle,
  isRequired,
  style,
  items,
}) {
  return (
    <div className={`${style} tw-flex tw-flex-col`}>
      {fieldTitle && <FieldTitle title={fieldTitle} isRequired={isRequired} />}
      <div className={`tw-flex tw-flex-wrap`}>
        {items.map((item) => (
          <RadioButton
            isActive={value === item.value}
            key={item.value}
            text={item.name}
            onClick={() => setValue(item.value)}
            style={'tw-mb-1'}
          />
        ))}
      </div>
    </div>
  );
}
