import {
  faArrowDownShortWide,
  faArrowDownWideShort,
  faFilter,
  faTruck,
} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import useGetRequest from '../../api/useGetRequest';
import EmptyList from '../../components/agv-empty-list';
import GenericPageContainer from '../../components/agv-generic-page-container';
import GenericTable from '../../components/agv-generic-table';
import GenericTableSkeleton from '../../components/agv-generic-table/genericTableSkeleton';
import SearchFilter from '../../components/agv-header-search';
import SelectFilter from '../../components/agv-header-select';
import { STATUS_ENUM } from '../../components/agv-status/status-enum';
import { ORDER_BY_ENUM } from '../../enums/orderby.enum';
import VehicleModal from './vehicle-modal';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useInfiniteScroll } from '../../hooks/useInfiniteScroll';

export default function Vehicles() {
  const [searchParams] = useSearchParams();

  const [isOpen, setIsOpen] = useState(false);
  const [vehicleSelected, setVehicleSelected] = useState(null);

  const initialFilters = {
    limit: 24,
    offset: 0,
    query: searchParams.get('query') || '',
    order:
      searchParams.get('order') === ORDER_BY_ENUM.ASC ||
      searchParams.get('order') === ORDER_BY_ENUM.DESC
        ? searchParams.get('order')
        : ORDER_BY_ENUM.DESC,
    with_tour_today_only:
      searchParams.get('with_tour_today_only') &&
      (searchParams.get('with_tour_today_only') === 'true' ||
        searchParams.get('with_tour_today_only') === 'false')
        ? searchParams.get('with_tour_today_only')
        : false,
  };

  const {
    items,
    hasMore,
    filters,
    fetchMoreData,
    handleUpdateParam,
    isLoading,
  } = useInfiniteScroll({
    initialFilters,
    debounceTime: 300,
    getRequest: useGetRequest,
    endpoint: '/vehicles',
  });

  const onOpenModal = (element) => {
    setVehicleSelected(element);
    setIsOpen(true);
  };

  return (
    <>
      <GenericPageContainer
        title={'Flotte de véhicules'}
        subtitle={
          'Retrouvez la liste de vos véhicules et modifiez leur code pin'
        }
        filters={
          <>
            <SearchFilter
              value={filters.query}
              setValue={(value) => handleUpdateParam('query', value)}
            />
            <SelectFilter
              value={filters.order}
              onChange={(value) => handleUpdateParam('order', value)}
              options={[
                { value: ORDER_BY_ENUM.DESC, name: 'Desc.' },
                { value: 'ASC', name: 'Asc.' },
              ]}
              icon={
                filters.order === ORDER_BY_ENUM.DESC
                  ? faArrowDownWideShort
                  : faArrowDownShortWide
              }
            />
            <SelectFilter
              value={filters.with_tour_today_only}
              onChange={(value) =>
                handleUpdateParam('with_tour_today_only', value)
              }
              options={[
                { value: false, name: 'Tous' },
                { value: true, name: "En tournée aujourd'hui" },
              ]}
              icon={faFilter}
            />
          </>
        }
      >
        {isLoading && filters.offset === 0 ? (
          <GenericTableSkeleton numberOfRows={8} />
        ) : items?.length > 0 ? (
          <InfiniteScroll
            dataLength={items.length}
            next={fetchMoreData}
            hasMore={hasMore}
            loader={<GenericTableSkeleton numberOfRows={2} />}
            scrollableTarget="page-content"
            endMessage={
              <p className="agrivitech-pagination-text tw-mb-2 tw-mt-8 tw-text-center">
                Vous avez vu toutes les données pour cette recherche
              </p>
            }
          >
            <GenericTable
              isLoading={isLoading}
              rows={items?.map((element) => ({
                title: `Camion ${element?.vehicule_key}`,
                subtitle: `${element?.vehicle_transporter_type || '.nc'} - Immat. ${element?.imat || '.nc'} - ${element?.label}`,
                onClick: () => onOpenModal(element),
                buttonText: 'Détails',
                status: element?.has_tour_today
                  ? STATUS_ENUM.POSITIVE
                  : STATUS_ENUM.NEUTRAL,
                icon: faTruck,
                statusText: element?.has_tour_today
                  ? 'En tournée'
                  : 'Stationné',
                fields: [
                  { name: 'Livraisons', value: element?.delivered_steps },
                  {
                    name: 'km compteur',
                    value: element?.last_kilometers_inputted
                      ? `${element?.last_kilometers_inputted} km`
                      : '-',
                  },
                  {
                    name: 'Ponctualité',
                    value: element?.delivered_steps
                      ? `${Math.round((element?.ontime_steps / element?.total_steps) * 100)}%`
                      : '-',
                  },
                  {
                    name: 'Dernière tournée',
                    value: element?.last_tour_date
                      ? moment(new Date(element?.last_tour_date)).format(
                          'DD MMM YYYY'
                        )
                      : '-',
                  },
                ],
              }))}
              iconColor={'tw-text-checklist-common'}
            />
          </InfiniteScroll>
        ) : (
          <EmptyList />
        )}
      </GenericPageContainer>
      <VehicleModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        vehicle={vehicleSelected}
      />
    </>
  );
}
