import {
  faAngleLeft,
  faCircleInfo,
  faCirclePlus,
  faDiagramProject,
  faGear,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import fr from 'date-fns/locale/fr';
import React, {
  Suspense,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Button } from 'react-bootstrap';
import { registerLocale } from 'react-datepicker';
import { Link } from 'react-router-dom';
import SimpleBarReact from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import { tour_status } from '../../enums/tour-status';
import TourItemDashboardLoader from '../HomeV3/Dashboard/tourItemDashboardLoader';
import ModalAddPlatform from './modalManagement/modalAddPlatform';
import ModalAddVehicule from './modalManagement/modalAddTruck';
import ModalConfig from './modalManagement/modalConfig';
import TourItemFleetManagement from './tourItemFleetManagement';

import 'react-circular-progressbar/dist/styles.css';

import SkeletonLoader from 'tiny-skeleton-loader-react';
import './fleet-management.scss';

import { useSelector } from 'react-redux';
import ModalOnBoarding from './modalManagement/modalOnboarding';
import PlatformList from './platformManagement';

registerLocale('fr', fr);

const MapFleetDashboard = React.lazy(() => import('./mapFleetManagement'));

const FleetManagement = () => {
  /******
   *
   * States
   *
   ******/

  const [vehicles, setVehicles] = useState([]);
  const [vehiclesConfig, setVehiclesConfig] = useState([]);
  const [platforms, setPlatforms] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [isVehiclesLoading, setIsVehiclesLoading] = useState(false);

  const [selectedTourID, setSelectedTourID] = useState(null);
  const [tourStatus, setTourStatus] = useState('all');
  const [modalShow, setModalShow] = useState(false);
  const [modalShowTwo, setModalShowTwo] = useState(false);
  const [modalHelp, setModalHelp] = useState(false);
  const [modalShowAddPlatform, setModalShowAddPlatform] = useState(false);
  const [isMapReady, setIsMapReady] = useState(false);
  const [isFleetEditing, setIsFleetEditing] = useState(false);

  const [selectedVehicle, setSelectedVehicle] = useState(null);

  const BASE_URL = useSelector((state) => state.BASE_URL);
  const ref = useRef();

  /******
   *
   * functions
   *
   ******/

  const isFiltering = useCallback(() => {
    return tourStatus !== 'all';
  }, [tourStatus]);

  // filter all the tours by the status
  const filteringFunction = useCallback(() => {
    if (!vehicles) {
      return [];
    }

    if (!isFiltering()) {
      return vehicles;
    }

    return vehicles.filter((x) => {
      return x.status?.toUpperCase() === tourStatus?.toUpperCase();
    });
  }, [vehicles, tourStatus, isFiltering]);

  const getPlatforms = useCallback(() => {
    const url = new URL(BASE_URL + '/starting-platform');
    const token = localStorage.getItem('fleetToken');

    let myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Authorization', token);
    let myInit = { method: 'GET', headers: myHeaders, cache: 'no-cache' };

    console.log('GET request @ ', BASE_URL + '/starting-platform');

    fetch(url, myInit)
      .then((response) => {
        if (response.ok) {
          response.json().then((result) => {
            if (result.status === 'Success') {
              setPlatforms(result.startingPlatforms);
            }
          });
        } else {
          console.log('Mauvaise réponse du réseau');
          response.json().then(function (result) {
            console.log('result', result);
          });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(
          "Il y a eu un problème avec l'opération fetch: " + error.message
        );
      });
  }, [BASE_URL]);

  const getVehicles = useCallback(() => {
    const url = new URL(BASE_URL + '/vehicles');
    const token = localStorage.getItem('fleetToken');

    setIsVehiclesLoading(true);

    let myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Authorization', token);
    let myInit = { method: 'GET', headers: myHeaders, cache: 'no-cache' };

    console.log('GET request @ ', BASE_URL + '/vehicles');

    fetch(url, myInit)
      .then((response) => {
        if (response.ok) {
          response.json().then((result) => {
            if (result?.vehicles?.length > 0) {
              setIsVehiclesLoading(false);
              setVehicles(result.vehicles);
              setVehiclesConfig({
                can_create_tanker_trucks: result?.canCreateTankerTrucks,
                can_create_trucks: result?.canCreateTrucks,
              });
            }
          });
        } else {
          setIsVehiclesLoading(false);
          console.log('Mauvaise réponse du réseau');
          response.json().then(function (result) {
            console.log('result', result);
          });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setIsVehiclesLoading(false);
        console.log(
          "Il y a eu un problème avec l'opération fetch: " + error.message
        );
      });
  }, [BASE_URL]);

  const toggleActivation = async (_id) => {
    if (!_id) return;
    const url = new URL(BASE_URL + '/vehicles/toggle-for-optimization/' + _id);
    const token = localStorage.getItem('fleetToken');

    setIsLoading(true);

    let myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Authorization', token);
    let myInit = {
      method: 'PUT',
      headers: myHeaders,
      cache: 'no-cache',
    };

    return fetch(url, myInit)
      .then((response) => response.json())
      .catch((error) => {
        console.log(
          "Il y a eu un problème avec l'opération fetch: " + error.message
        );
        return false;
      });
  };

  /******
   *
   * handlers
   *
   ******/

  function switchStatut(_id) {
    const newVehicles = vehicles.map((vehicle) => {
      if (vehicle.vehicule_id === _id) {
        vehicle.is_disabled = vehicle.is_disabled === 0 ? 1 : 0;
      }
      return vehicle;
    });
    setVehicles(newVehicles);
  }

  const handleToggleVehicleForOptimization = (_id) => {
    toggleActivation(_id).then((result) => {
      if (result) {
        if (result.isVehicleUpdated === true) {
          switchStatut(_id);
        }
      } else {
        fetch(result)
          .then((response) => response.json())
          .catch((error) => {
            console.log(
              "Il y a eu un problème avec l'opération fetch: " + error.message
            );
          });
        switchStatut(_id);
      }
      setIsLoading(false);
    });
  };

  const handleEditVehicle = (vehicle) => {
    if (!selectedVehicle) {
      setSelectedVehicle(vehicle);
      setModalShow(true);
    } else {
      setSelectedVehicle(null);
      setModalShow(false);
    }
  };

  /******
   *
   * Effects
   *
   ******/

  /*****
   * will set the selected tour in order to use in the map detail when the user click on a tour
   */

  // simple-bar recalculate width and height
  useEffect(() => {
    ref.current.recalculate();
  });

  // Did mount effect
  useEffect(() => {
    getPlatforms();
    getVehicles();
    setSelectedTourID(null);
    setTourStatus('all');
  }, [BASE_URL, getPlatforms, getVehicles]);

  /******
   *
   * Return
   *
   ******/

  return (
    <>
      <div className={'page-wrapper'}>
        <div className={'header-fleet'}>
          <div className="header-fleet-inner">
            <div className="pe-2">
              <h3>Définition de votre flotte</h3>
              <h4>
                Créez, modifiez ou désactivez vos camions pour l'optimisation.
              </h4>
            </div>
            <div className="fleet-management-tour-header">
              <Button
                variant=""
                className="btn-fleet-header"
                style={{ marginRight: '15px' }}
                onClick={() => setModalHelp(true)}
              >
                <FontAwesomeIcon
                  icon={faCircleInfo}
                  style={{ fontSize: 14 }}
                  color="#757476"
                />
              </Button>
              <Button
                variant=""
                className="btn-fleet-header"
                style={{ marginRight: '15px' }}
                onClick={() => setModalShowTwo(true)}
                disabled={isLoading || vehicles?.length === 0 || isFleetEditing}
              >
                <FontAwesomeIcon
                  icon={faGear}
                  style={{ fontSize: 14 }}
                  color="#757476"
                />
              </Button>
              <Link to="/optimization">
                <span className={'btn-fleet-wrapper'}>
                  <Button variant="" className="btn-fleet-header">
                    <FontAwesomeIcon icon={faAngleLeft} className="btn-icon" />
                    Retour à l'optimisation
                  </Button>
                </span>
              </Link>
            </div>
          </div>
          {/* <img
            src={bgdeco2}
            alt="background_decoration_fleet"
            className="bg-deco-fleet"
          /> */}
        </div>
        <div className="fleet-management-content-container">
          <div className="fleet-management-content-left-part">
            <div className="fleet-management-tour-header">
              <div className="tour-header-left">
                <span className="tour-header-title">
                  Liste de vos véhicules
                </span>
                <span className="tour-header-number badge">
                  {vehicles?.length ?? 0}
                </span>
              </div>
              <div className="tour-header-right">
                <Button
                  variant=""
                  onClick={() => setModalShow(true)}
                  className="btn-add-truck"
                  disabled={isLoading || isFleetEditing}
                >
                  <FontAwesomeIcon
                    icon={faCirclePlus}
                    className="icon-rounded-plus"
                  />
                  Ajouter un véhicule
                </Button>
                <Button
                  variant=""
                  onClick={() => setIsFleetEditing(!isFleetEditing)}
                  className="btn-add-truck ms-2"
                  disabled={isLoading}
                >
                  <FontAwesomeIcon
                    icon={faDiagramProject}
                    className="icon-rounded-plus"
                  />
                  Gestion de flotte
                </Button>
              </div>
            </div>
            <div className="tours-container">
              <div className="wrapper">
                <SimpleBarReact
                  forceVisible="y"
                  ref={ref}
                  style={{
                    maxHeight: '100%',
                    width: '100%',
                    padding: '10px 20px 10px 0px',
                  }}
                >
                  {vehicles?.length > 0 ? (
                    <>
                      {filteringFunction()?.length > 0 ? (
                        filteringFunction()?.map((vehicle) => {
                          return (
                            <TourItemFleetManagement
                              key={`tour_${vehicle?.vehicule_id}`}
                              selectedTourID={selectedTourID}
                              handleEditVehicle={handleEditVehicle}
                              vehicle={vehicle}
                              isMapReady={isMapReady}
                              isFleetEditing={isFleetEditing}
                              handleToggleVehicleForOptimization={
                                handleToggleVehicleForOptimization
                              }
                            />
                          );
                        })
                      ) : (
                        <div className="no-tour-container">
                          {/* <img
                            src={noDataIllu}
                            alt="background_decoration_fleet"
                            className="no-tour-img"
                          /> */}
                          <span className="no-tour-title">
                            Aucun véhicule en tournée avec
                            <br /> le filtre sélectionné
                          </span>
                          <span className="no-tour-text">
                            Aucun véhicule n'est en tournée pour la date du{' '}
                            {new Date().toLocaleDateString('fr-FR', {
                              weekday: 'long',
                              year: 'numeric',
                              month: 'long',
                              day: 'numeric',
                            })}{' '}
                            avec le statut{' '}
                            <strong>
                              {
                                tour_status?.properties[tourStatus]
                                  ?.display_name
                              }
                            </strong>
                            .
                          </span>
                        </div>
                      )}
                    </>
                  ) : isVehiclesLoading ? (
                    <>
                      <TourItemDashboardLoader />
                      <TourItemDashboardLoader />
                      <TourItemDashboardLoader />
                      <TourItemDashboardLoader />
                      <TourItemDashboardLoader />
                    </>
                  ) : (
                    <div className="no-tour-container">
                      {/* <img
                        src={noDataIllu}
                        alt="background_decoration_fleet"
                        className="no-tour-img"
                      /> */}
                      {/* <div
                        style={{ margin: "0 auto" }}
                        className="no-tour-img"
                        ref={animBox}
                      ></div> */}
                      <span className="no-tour-title">
                        Aucun véhicule défini.
                      </span>
                      <span className="no-tour-text">
                        Vous devez définir un véhicule pour le voir ici.
                      </span>
                    </div>
                  )}
                </SimpleBarReact>
              </div>
            </div>
          </div>
          <div className="fleet-management-content-right-part">
            <div className="fleet-management-map-part">
              <div className="map-div-container">
                <div className="map-div-header d-flex justify-content-between align-items-center">
                  <div className="map-header-div-one">
                    <span className="map-header-title">
                      Plateformes de départ
                    </span>
                    <p className="map-header-subtitle">
                      Définissez simplement vos différentes plateformes de
                      départ
                    </p>
                  </div>
                  <span className="map-header-number badge">
                    {platforms?.length ?? 0}
                  </span>
                </div>
                <div className="map-dashboard-container">
                  <Suspense
                    fallback={
                      <div className="map-wrapper">
                        <SkeletonLoader height={'100%'} width={'100%'} />
                      </div>
                    }
                  >
                    <MapFleetDashboard
                      tours={platforms}
                      setIsMapReady={setIsMapReady}
                    />
                  </Suspense>
                </div>
                <div className="platform-container">
                  <PlatformList
                    platforms={platforms}
                    setModalShowAddPlatform={setModalShowAddPlatform}
                    isFleetEditing={isFleetEditing}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalAddVehicule
        show={modalShow}
        onHide={(vehicle_added) => {
          setModalShow(false);
          setSelectedVehicle(null);
          if (vehicle_added) {
            getVehicles();
          }
        }}
        platforms={platforms}
        selectedVehicle={selectedVehicle}
        BASE_URL={BASE_URL}
        can_create_trucks={vehiclesConfig?.can_create_trucks}
        can_create_tanker_trucks={vehiclesConfig?.can_create_tanker_trucks}
      />
      <ModalConfig
        show={modalShowTwo}
        onHide={() => setModalShowTwo(false)}
        BASE_URL={BASE_URL}
      />
      <ModalOnBoarding show={modalHelp} onHide={() => setModalHelp(false)} />
      <ModalAddPlatform
        show={modalShowAddPlatform}
        setModalShowAddPlatform={setModalShowAddPlatform}
        onHide={(plateform_added) => {
          setModalShowAddPlatform(false);
          if (plateform_added) {
            getPlatforms();
          }
        }}
      />
    </>
  );
};

export default FleetManagement;
