import React, { Fragment, useEffect } from 'react';
import {
  Link,
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';

import { faQuestion, faSignInAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import fleetBrand from './assets/images/logos/FleetWhite.png';

import { jwtDecode } from 'jwt-decode';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.scss';
import Menu from './components/fleet-menu/index.js';
import Section from './components/fleet-menu/section.js';
import { ROLE_ENUM } from './enums/role-enum.enum.js';
import AddVehicle from './pages/AddVehicle/index.js';
import Admin from './pages/Admin/index.js';
import Documents from './pages/Documents/index.js';
import FleetManagement from './pages/FleetManagement/index.js';
import Forgot from './pages/Forgot/index.js';
import HomeV3 from './pages/HomeV3/index.js';
import Login from './pages/Login/index.js';
import Messaging from './pages/Messaging/index.js';
import Reserves from './pages/Reserves/index.js';
import ResetPassword from './pages/ResetPassword/index.js';
import Tour from './pages/Tour/index.js';
import Users from './pages/Users/index.js';
import Validation from './pages/Validation/index.js';
import Vehicles from './pages/Vehicles/index.js';
import AddFile from './pages/__OLD__/AddFile/index.js'; // Tell webpack this JS file uses this image
import Optimization from './pages/__OLD__/Optimization/index.js'; // Tell webpack this JS file uses this image
import { logout, setConfiguration, setFeatures } from './reducer/actions.js';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const checkAuth = () => {
  return localStorage.getItem('fleetToken');
};

export default function App() {
  const config = useSelector((state) => state.config);
  const nav_width = useSelector((state) => state.nav_width);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const query = useQuery();

  const isAuthenticated = checkAuth();

  useEffect(() => {
    if (dispatch) {
      const token = localStorage.getItem('fleetToken');
      //decode the token and store it in state
      let decoded = token ? jwtDecode(token) : null;

      if (decoded) {
        if (decoded.user?.version !== '1.0.1') {
          logoutAction();
        }
        dispatch(
          setConfiguration({
            is_admin: decoded?.user?.roles?.includes(ROLE_ENUM.ADMIN),
            is_super_admin: decoded?.user?.roles?.includes(
              ROLE_ENUM.SUPER_ADMIN
            ),
            can_upload_tour_with_excel:
              decoded?.client?.features?.can_upload_tour_with_excel,
            can_access_dashboard_v2:
              decoded?.client?.features?.can_access_dashboard_v2,
            fleet_optimization: decoded?.client?.features?.fleet_optimization,
            logo_url: decoded?.client?.logo_url,
            client_id: decoded?.user?.client_id,
          })
        );
        dispatch(setFeatures(decoded.client.features));
      }
    }
  }, [dispatch]);

  // const clientId = parseInt(localStorage.getItem('fleetClientId'));

  const logoutAction = () => {
    localStorage.removeItem('fleetToken');
    localStorage.removeItem('fleetClientId');
    dispatch(logout());

    navigate('/login');
  };

  const handleConditionalItems = () => {
    const items = [
      { title: 'Validation', to: '/validation' },
      { title: 'Utilisateurs', to: '/users' },
      { title: 'Documents', to: '/documents' },
      { title: 'Factures', to: '/invoices' },
    ];

    if (config?.can_upload_tour_with_excel) {
      items.push({
        title: 'Import Excel',
        to: '/addFile',
      });
    }

    return items;
  };

  return (
    <div className={'App-root'}>
      <div className="App-status-bar "></div>
      <div className="App">
        {checkAuth() ? (
          <nav className={`App-nav`}>
            <Menu>
              <Section
                title={'PILOTAGE'}
                items={[
                  { title: 'Tableau de bord', to: '/' },
                  { title: 'Tournées', to: '/tours' },
                  { title: 'Véhicules', to: '/vehicles' },
                  { title: 'Réserves', to: '/reserves' },
                  { title: 'Messagerie', to: '/messaging' },
                ]}
              />
              {config?.is_admin && (
                <Section title={'GESTION'} items={handleConditionalItems()} />
              )}
            </Menu>
            {/* <div className="nav-list">
                <ul>

                  {config?.fleet_optimization && (
                    <li
                      className={`${
                        location?.pathname === '/optimization' ? 'active' : ''
                      }`}
                    >
                      <Link to="/optimization">
                        <FontAwesomeIcon icon={faProjectDiagram} />{' '}
                        {nav_width === 'expanded' ? (
                          <>
                            <span>Optimisation</span>
                            <span className="beta-tag">BETA</span>
                          </>
                        ) : null}
                      </Link>
                    </li>
                  )}

                  {!config?.fleet_optimization ? (
                    <li
                      className={`${
                        location?.pathname === '/vehicle' ? 'active' : ''
                      }`}
                    >
                      <Link to="/vehicle">
                        <FontAwesomeIcon icon={faTruck} />{' '}
                        {nav_width === 'expanded' ? 'Véhicules' : null}
                      </Link>
                    </li>
                  ) : (
                    <li
                      className={`${
                        location?.pathname === '/vehicle' ? 'active' : ''
                      }`}
                    >
                      <Link to="/fleet-management">
                        <FontAwesomeIcon icon={faTruck} />{' '}
                        {nav_width === 'expanded'
                          ? 'Véhicules & plateformes'
                          : null}
                      </Link>
                    </li>
                  )}
                  
                  <li
                    className={`${
                      location?.pathname === '/tour' ? 'active' : ''
                    }`}
                  >
                    <Link to="/tour">
                      <FontAwesomeIcon icon={faRoute} />{' '}
                      {nav_width === 'expanded' ? 'Tournées' : null}
                    </Link>
                  </li>

                  {config?.can_upload_tour_with_excel && (
                    <li
                      className={`${
                        location?.pathname === '/addfile' ? 'active' : ''
                      }`}
                    >
                      <Link to="/addfile">
                        <FontAwesomeIcon icon={faFileImport} />{' '}
                        {nav_width === 'expanded' ? 'Import' : null}
                      </Link>
                    </li>
                  )}
                  
                  <li
                    className={`${
                      location?.pathname === '/reserves' ? 'active' : ''
                    }`}
                  >
                    <Link to="/reserves">
                      <FontAwesomeIcon icon={faClipboard} />{' '}
                      {nav_width === 'expanded' ? 'Réserves' : null}
                    </Link>
                  </li>

                  <li
                    className={`${
                      location?.pathname === '/admin' ? 'active' : ''
                    }`}
                  >
                    {config?.is_admin && (
                      <Link to="/admin">
                        <FontAwesomeIcon icon={faFileInvoiceDollar} />{' '}
                        {nav_width === 'expanded' ? 'Factures' : null}
                      </Link>
                    )}
                  </li>

                  <li
                    className={`${
                      location?.pathname === '/help' ? 'active' : ''
                    }`}
                  >
                    <Link to="/help">
                      <FontAwesomeIcon icon={faQuestion} />{' '}
                      {nav_width === 'expanded' ? 'Aide' : null}
                    </Link>
                  </li>

                  <li>
                    <div
                      className={'menu-wrapper'}
                      onClick={() => {
                        logoutAction();
                      }}
                    >
                      <FontAwesomeIcon icon={faSignInAlt} />{' '}
                      {nav_width === 'expanded' ? 'Déconnexion' : null}
                    </div>
                  </li>

                  <li>
                    <a
                      className={'menu-wrapper border'}
                      href="https://form.asana.com/?k=6AqMwzRaIrtDxdvvnZbuhw&d=1204569106693047"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FontAwesomeIcon icon={faHeadset} />{' '}
                      {nav_width === 'expanded' ? 'Nous contacter' : null}
                    </a>
                  </li>

                </ul>
              </div> */}
          </nav>
        ) : (
          <nav
            className={`App-nav ${nav_width !== 'expanded' ? 'collapsed' : ''}`}
          >
            <div className="App-nav-wrapper">
              <div className="nav-list">
                <ul>
                  <li
                    className={`${
                      location?.pathname === '/login' ? 'active' : ''
                    }`}
                  >
                    <Link to="/login">
                      <FontAwesomeIcon icon={faSignInAlt} />{' '}
                      {nav_width === 'expanded' ? 'Login' : null}
                    </Link>
                  </li>
                  <li
                    className={`${
                      location?.pathname === '/help' ? 'active' : ''
                    }`}
                  >
                    <Link to="/help">
                      <FontAwesomeIcon icon={faQuestion} />{' '}
                      {nav_width === 'expanded' ? 'Aide' : null}
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="brand-logo-fleet">
                <Link to={config?.can_access_dashboard_v2 ? '/' : '/vehicle'}>
                  <img
                    src={config?.logo_url || fleetBrand}
                    alt={'fleet-logo'}
                  />
                </Link>
              </div>
            </div>
          </nav>
        )}

        <section
          className={`App-frame ${nav_width === 'expanded' ? '' : 'collapsed'}`}
          id="App-frame"
        >
          <div className="tw-h-full tw-w-full">
            <Routes>
              {isAuthenticated ? (
                <Fragment>
                  {config?.can_access_dashboard_v2 ? (
                    <Route path="/" element={<HomeV3 />} />
                  ) : (
                    <Route path="/vehicle" element={<Vehicles />} />
                  )}

                  {config?.can_upload_tour_with_excel && (
                    <Route path="/addfile" element={<AddFile />} />
                  )}
                  {config?.fleet_optimization && (
                    <Route path="/optimization" element={<Optimization />} />
                  )}
                  {config?.is_admin && (
                    <Route path="/invoices" element={<Admin />} />
                  )}
                  <Route path="/reserves" element={<Reserves />} />
                  <Route
                    path="/fleet-management"
                    element={<FleetManagement />}
                  />

                  {/* <Route path="/tour/:id" element={<Tour />} /> */}
                  <Route
                    path="/tours"
                    element={
                      <Tour location={location} status={query.get('status')} />
                    }
                  />
                  <Route path="/validation" element={<Validation />} />
                  <Route path="/vehicles" element={<Vehicles />} />
                  <Route path="/users" element={<Users />} />
                  <Route path="/documents" element={<Documents />} />
                  <Route path="/messaging" element={<Messaging />} />
                </Fragment>
              ) : (
                <Fragment>
                  <Route path="/" element={<Navigate to="/login" />} />
                </Fragment>
              )}
              <Route path="/help" element={<AddVehicle />} />
              <Route path="/login" element={<Login />} />
              <Route path="/forgot" element={<Forgot />} />
              <Route
                path="/reset-password/:token"
                element={<ResetPassword />}
              />
            </Routes>
          </div>
        </section>
      </div>
      <ToastContainer position="bottom-right" />
    </div>
  );
}
