import { useState } from 'react';
import fleetApi from '../../services/api.service';

export default function useGetValidateRegistrationToken() {
  const [data, setData] = useState(null);
  const [error, setError] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const getValidateRegistrationToken = async (token) => {
    try {
      setIsLoading(true);
      const response = await fleetApi.get(
        `/auth/validate-registration-token/${token}`
      );
      if (response.status === 200) {
        setIsSuccess(true);
        setData(response.data);
      } else {
        setIsSuccess(false);
        throw new Error();
      }
    } catch (error) {
      setIsSuccess(false);
      setError(error?.message || 'Une erreur est survenue.');
    } finally {
      setIsLoading(false);
    }
  };

  return {
    data,
    error,
    isSuccess,
    isLoading,
    getValidateRegistrationToken,
  };
}
