import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment } from 'react';
import MainButton from '../agv-button';
import Status from '../agv-status/index';

export default function Row({
  title,
  subtitle,
  iconColor,
  icon,
  onClick,
  buttonText,
  status,
  statusText,
  statusStyle,
  fields,
  style,
}) {
  return (
    <div
      className={`tw-flex tw-justify-between tw-items-center tw-p-3 ${style}`}
      onClick={buttonText ? null : onClick}
      key={title}
    >
      <div className="tw-flex tw-items-center tw-max-w-fit">
        {icon && (
          <FontAwesomeIcon
            className={`tw-mr-2 tw-size-4 ${iconColor}`}
            icon={icon}
          />
        )}
        <div className="tw-max-w-xl">
          <div className="tw-text-smi tw-font-work tw-leading-4 tw-mb-1">
            {title}
          </div>
          <div className="tw-text-smi tw-font-work tw-text-text-dark-medium-importance tw-leading-4 tw-line-clamp-1">
            {subtitle}
          </div>
        </div>
      </div>

      <div className="tw-flex tw-items-center tw-min-w-fit">
        {fields?.map((field, index) => (
          <Fragment key={`${field.name}-${index}`}>
            <div className="tw-flex tw-flex-col tw-items-end">
              <div className="tw-text-smi tw-font-work tw-leading-4 tw-mb-1">
                {field.name}
              </div>
              <div className="tw-text-smi tw-font-work tw-text-text-dark-medium-importance tw-leading-4 tw-line-clamp-1">
                {field.icon && (
                  <FontAwesomeIcon
                    className={`tw-mr-1 tw-size-4`}
                    icon={field.icon}
                    color={field.color}
                  />
                )}{' '}
                {field.value}
              </div>
            </div>
            {index !== fields.length - 1 && (
              <div className="tw-h-4 tw-w-px tw-bg-background tw-mx-4" />
            )}
          </Fragment>
        ))}
        {fields?.length > 0 && status && statusText && (
          <div className="tw-h-4 tw-w-px tw-bg-background tw-mx-4" />
        )}
        {status && statusText && (
          <Status style={statusStyle} status={status} text={statusText} />
        )}
        {onClick && buttonText && (
          <>
            <div className="tw-h-4 tw-w-px tw-bg-background tw-mx-4" />
            <MainButton style={'tw-ml-4'} text={buttonText} onClick={onClick} />
          </>
        )}
      </div>
    </div>
  );
}
