import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button, Card } from 'react-bootstrap';
import SimpleBarReact from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import BadgeColor from '../platformColorBadge';
import './platform-management.scss';

const PlatformList = (props) => {
  return (
    <>
      <div className="platform-list-container">
        <SimpleBarReact
          forceVisible="y"
          style={{
            maxHeight: '100%',
            width: '100%',
            padding: '10px 10px 10px 0px',
            flexGrow: 1,
          }}
        >
          {props.platforms?.length > 0 ? (
            <>
              {props.platforms.map((platform, index) => (
                <div key={index} className={'fleet-platform-list'}>
                  <div
                    as={Card.Header}
                    className={'fleet-first-line-tour-management'}
                  >
                    <div className={'hw'}>
                      {/* <img
                        src={boxImg}
                        className={"img-illustration-box-list"}
                        alt={"box"}
                      /> */}
                      <div
                        className="rounded-background d-flex justify-content-center align-items-center"
                        style={{ backgroundColor: platform.color }}
                      >
                        <div className="span-color">{platform.id}</div>
                      </div>
                    </div>
                    <div className={'fr'}>
                      <BadgeColor
                        color={platform.color}
                        platform={platform.id}
                      />
                      <span className={'fleet-item-address'}>
                        {platform.full_address}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </>
          ) : (
            <div className="no-tour-container">
              <span className="no-tour-title">Aucune plateforme définie</span>
              <span className="no-tour-text-platform">
                Ajouter une plateforme avec le bouton ci-dessous.
              </span>
            </div>
          )}
        </SimpleBarReact>
        <div className="fleet-item-tour-compartiment-horizontal-divider"></div>
      </div>

      <div className="fleet-more-platform">
        <div className="fleet-more-platform-header">
          <Button
            variant=""
            className="fleet-button-add"
            onClick={() => {
              props.setModalShowAddPlatform(true);
            }}
            disabled={props.isFleetEditing}
          >
            <FontAwesomeIcon
              icon={faPlusCircle}
              className="btn-icon-more-platform"
            />
            <span className="fleet-span-text">Ajouter une plateforme</span>
          </Button>
        </div>
      </div>
    </>
  );
};

export default PlatformList;
