import React from 'react';
import { CONTAINER_NAME } from '../../enums/container.enum';
import {
  ALIMENT_TYPE_ENUM,
  ALIMENT_TYPE_PROPS,
} from '../fleet-aliment-type-tag/aliment-type.enum';

const Container = ({ weight, maxWeigth, order, item_type }) => {
  const BASE_HEIGHT = 50;
  const BASE_WIDTH = 40;

  let type_aliment = item_type?.toUpperCase();

  return (
    <div
      className={`tw-border-input-border tw-border tw-border-dashed tw-border-containers-border tw-rounded-lg tw-p-1 tw-relative`}
    >
      <div
        style={{
          height: BASE_HEIGHT,
          width: BASE_WIDTH,
        }}
        className="tw-flex tw-items-end"
      >
        <div
          style={{
            height: BASE_HEIGHT * (weight / maxWeigth),
            width: BASE_WIDTH,
          }}
          className={` tw-border-solid tw-border ${ALIMENT_TYPE_PROPS[type_aliment]?.backgroundColor} ${ALIMENT_TYPE_PROPS[type_aliment]?.borderColor} tw-rounded tw-text-sm`}
        ></div>
      </div>
      <div
        style={{ height: BASE_HEIGHT, width: BASE_WIDTH }}
        className={`tw-absolute tw-left-0 tw-top-0 tw-ml-1 tw-mt-1 tw-text-center tw-flex tw-flex-col tw-justify-evenly`}
      >
        <div className="tw-text-sm tw-font-semibold">
          {CONTAINER_NAME[order]}
        </div>
        <div className="tw-text-xs">{weight} t.</div>
      </div>
    </div>
  );
};

export default function VehicleContainers(containers) {
  return (
    <div className="tw-space-x-2 tw-flex tw-items-center">
      {containers && containers?.containers?.length > 0 ? (
        containers?.containers?.map((container) => {
          return (
            <Container
              key={container.id}
              weight={container.current_capacity}
              maxWeigth={container.capacity}
              order={container.container_key}
              item_type={container?.item_type ? container?.item_type : 'AUTRES'}
            />
          );
        })
      ) : (
        <div> Le véhicule est actuellement vide </div>
      )}
    </div>
  );
}
