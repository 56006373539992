import React from 'react';
import SkeletonLoader from 'tiny-skeleton-loader-react';

const Row = () => {
  return (
    <div className="tw-flex tw-justify-between tw-items-center tw-p-3">
      <div className="tw-flex tw-items-center">
        <SkeletonLoader width={20} height={20} />
        <div className="tw-ml-2">
          <SkeletonLoader width={150} height={20} />
          <SkeletonLoader style={{ marginTop: 2 }} width={300} height={20} />
        </div>
      </div>

      <div className="tw-flex tw-items-center">
        <SkeletonLoader width={80} height={20} />
        <div className="tw-h-4 tw-w-px tw-bg-background tw-mx-4" />
        <SkeletonLoader style={{ borderRadius: 25 }} width={50} height={25} />
        <div className="tw-h-4 tw-w-px tw-bg-background tw-mx-4" />
        <SkeletonLoader width={80} height={30} />
      </div>
    </div>
  );
};

export default function GenericTableSkeleton({ numberOfRows = 4, hasTitle }) {
  return (
    <div className="tw-mt-8">
      {hasTitle && (
        <div className="tw-mb-4">
          <SkeletonLoader height={24} width={150} />
        </div>
      )}
      <div className="tw-rounded-md tw-bg-white tw-divide-solid tw-divide-x-0 tw-divide-y tw-divide-background tw-overflow-x-auto">
        <div className="tw-w-full tw-divide-solid tw-divide-x-0 tw-divide-y tw-divide-background">
          {[...Array(numberOfRows).keys()].map((_, index) => (
            <Row key={index} />
          ))}
        </div>
      </div>
    </div>
  );
}
