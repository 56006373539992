import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import GenericModal from '../../components/agv-generic-modal';
import InputCombobox from '../../components/agv-forms-combobox/input-combobox';
import InputRadio from '../../components/agv-forms-radio';
import InputText from '../../components/agv-forms-input';
import { ROLE_ENUM, ROLE_ENUM_DISPLAY } from '../../enums/role-enum.enum';
import isEmailValid from '../../utils/is-email-valid.util';
import isPhoneNumberValid from '../../utils/is-phone-number-valid.util';

export default function EditUserModal({
  isOpen,
  setIsOpen,
  user,
  editUser,
  deleteUser,
  mainActionIsLoading,
  secondaryActionIsLoading,
  clientsData,
}) {
  const config = useSelector((state) => state.config);

  const [clientId, setClientId] = useState(
    config?.is_super_admin ? config?.client_id?.toString() : null
  );
  const [factory, setFactory] = useState(user?.factory_name);
  const [lastname, setLastname] = useState(user?.last_name);
  const [firstname, setFirstname] = useState(user?.first_name);
  const [email, setEmail] = useState(user?.email);
  const [phone, setPhone] = useState(user?.phone_number?.replace('+33', '0'));
  const [role, setRole] = useState(user?.roles[0]);
  const [clientFactories, setClientFactories] = useState([]);

  const isEmpty =
    !isEmailValid(email) ||
    !isPhoneNumberValid(phone) ||
    (clientFactories?.length > 0 && !factory) ||
    !lastname?.length ||
    !firstname?.length ||
    (config?.is_super_admin ? !clientId : false);

  const isSameValues =
    (config?.is_super_admin
      ? clientId === config?.client_id?.toString()
      : true) &&
    email === user?.email &&
    factory === user?.factory_name &&
    lastname === user?.last_name &&
    firstname === user?.first_name &&
    role === user?.roles[0] &&
    phone === user?.phone_number?.replace('+33', '0');

  const DISABLED = isEmpty || isSameValues;

  useEffect(() => {
    const factories = clientsData?.filter(
      (clientData) => clientData?.client_id === clientId
    )?.[0]?.factories;
    setClientFactories(factories);
  }, [clientsData, clientId]);

  useEffect(() => {
    if (isOpen) {
      setClientId(config?.is_super_admin ? user?.client_id : null);
      setFactory(user?.factory_id);
      setLastname(user?.last_name);
      setFirstname(user?.first_name);
      setEmail(user?.email);
      setPhone(user?.phone_number?.replace('+33', '0'));
      setRole(user?.roles[0]);
    }
  }, [isOpen]);

  const handleDeleteUser = () => {
    if (
      window.confirm(
        "Êtes-vous sûr de vouloir procéder à la suppression de l'utilisateur ? Cette action est irréversible."
      )
    ) {
      deleteUser(user?.id, clientId);
    }
  };

  return (
    <GenericModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title={'Modifier un utilisateur'}
      mainAction={() =>
        editUser(
          user?.id,
          config?.is_super_admin ? clientId : null,
          firstname,
          lastname,
          email,
          phone,
          factory,
          role
        )
      }
      mainActionText={'Modifier'}
      mainActionIsLoading={mainActionIsLoading}
      mainActionDisabled={DISABLED}
      secondaryAction={handleDeleteUser}
      secondaryActionIsLoading={secondaryActionIsLoading}
      secondaryActionText={"Supprimer l'utilisateur"}
    >
      <div className="tw-flex tw-flex-col tw-my-4">
        {config?.is_super_admin && (
          <InputCombobox
            value={clientId}
            setValue={setClientId}
            fieldTitle={'ID Client'}
            items={clientsData?.map((client) => ({
              name: `${client.client_id} - ${client.name}`,
              value: client.client_id,
            }))}
            isRequired
            emptyError={`Le champ "Client ID" ne peut être vide.`}
            style={''}
          />
        )}
        {clientFactories && clientFactories.length > 0 && (
          <InputCombobox
            value={factory}
            setValue={setFactory}
            fieldTitle={'Usine'}
            items={clientFactories?.map((element) => ({
              name: element.name,
              value: element.id,
            }))}
            isRequired
            emptyError={
              clientFactories && clientFactories.length > 0
                ? `Le champ "Usine" ne peut être vide.`
                : ''
            }
            style={'tw-mt-6'}
            isDisabled={
              config?.is_super_admin
                ? !clientId || !clientFactories || !clientFactories?.length
                : false
            }
          />
        )}
        <InputText
          value={lastname}
          setValue={setLastname}
          fieldTitle={'Nom'}
          isRequired
          emptyError={`Le champ "Nom" ne peut être vide.`}
          style={'tw-mt-6'}
          placeholder={'Leblanc'}
        />
        <InputText
          value={firstname}
          setValue={setFirstname}
          fieldTitle={'Prénom'}
          isRequired
          emptyError={`Le champ "Prénom" ne peut être vide.`}
          style={'tw-mt-6'}
          placeholder={'Juste'}
        />
        <InputText
          value={email}
          setValue={setEmail}
          fieldTitle={'E-mail'}
          isRequired
          emptyError={`Le champ "E-mail" ne peut être vide.`}
          style={'tw-mt-6'}
          placeholder={'exemple@exemple.com'}
          isBadFormat={!isEmailValid(email)}
          type="email"
        />
        <InputText
          value={phone}
          setValue={setPhone}
          fieldTitle={'Téléphone'}
          isRequired
          emptyError={`Le champ "Téléphone" ne peut être vide.`}
          style={'tw-mt-6'}
          placeholder={'0612345678'}
          isBadFormat={!isPhoneNumberValid(phone)}
          type="tel"
          maxLength={10}
        />
        <InputRadio
          fieldTitle={'Rôle'}
          value={role}
          setValue={setRole}
          style={'tw-mt-6'}
          items={[
            {
              value: ROLE_ENUM.ADMIN,
              name: ROLE_ENUM_DISPLAY[ROLE_ENUM.ADMIN],
            },
            {
              value: ROLE_ENUM.OPERATOR,
              name: ROLE_ENUM_DISPLAY[ROLE_ENUM.OPERATOR],
            },
          ]}
        />
      </div>
    </GenericModal>
  );
}
