import { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import fleetApi from '../services/api.service';

export default function useGetRequest(url, delay = 300) {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [error, setError] = useState(null);
  const debounceTimeout = useRef(null);
  const abortController = useRef(null);

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    if (abortController.current) {
      abortController.current.abort();
    }
    abortController.current = new AbortController();
    const signal = abortController.current.signal;

    try {
      const response = await fleetApi.get(url, { signal });
      setData(response.data);
      setIsFirstLoad(false);
    } catch (err) {
      if (err.name !== 'AbortError') {
        setError(err.message || 'Une erreur est survenue');
      }
    } finally {
      setIsLoading(false);
    }
  }, [url]);

  useEffect(() => {
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }
    debounceTimeout.current = setTimeout(() => {
      fetchData();
    }, delay);

    return () => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
      if (abortController.current) {
        abortController.current.abort();
      }
    };
  }, [fetchData, delay]);

  const mutate = useMemo(() => {
    return () => {
      fetchData();
    };
  }, [fetchData]);

  return {
    data,
    isLoading,
    isFirstLoad,
    error,
    mutate,
  };
}
