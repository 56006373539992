import React, { useState } from 'react';
import logo from '../../assets/images/logos/FleetMain.png';
import GenericModal from '../../components/agv-generic-modal';
import InputText from '../../components/agv-forms-input';

export default function RegisterInviteModal({
  isOpen,
  setIsOpen,
  invitedUser,
  token,
  isLoading,
  setupPassword,
}) {
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');

  return (
    <GenericModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title={'Invitation au tableau de bord Fleet'}
      mainAction={() => setupPassword(token, password)}
      mainActionText={'Confirmer le mot de passe'}
      mainActionIsLoading={isLoading}
      mainActionDisabled={
        !invitedUser?.email.length ||
        password !== passwordConfirmation ||
        password.length < 6
      }
    >
      <div className="tw-flex tw-flex-col tw-items-center">
        {/* TODO : Mettre l'image du client_id */}
        <img className="tw-h-10" src={logo} />
        <div className="tw-mt-8">
          <div className="tw-text-xl tw-font-semibold">{`Bonjour, ${invitedUser?.first_name} !`}</div>
          <div className="tw-mt-2 tw-text-sm tw-font-medium">{`Une personne de votre organisation vous a invité à utiliser le tableau de bord fleet.`}</div>
          <div className="tw-mt-4 tw-text-sm tw-font-medium">{`Pour commencer à utiliser l'application, saisissez un mot de passe pour terminer la configuration de votre compte.`}</div>
        </div>
      </div>
      <div className="tw-flex tw-flex-col tw-py-6">
        <InputText
          value={invitedUser?.email}
          fieldTitle={'Email'}
          disabled
          isRequired
          tooltip="prérempli par la personne de votre organisation"
          style={'tw-mt-5'}
        />
        <InputText
          value={password}
          setValue={setPassword}
          fieldTitle={'Mot de passe'}
          isRequired
          emptyError={`Le champ "Mot de passe" ne peut être vide.`}
          style={'tw-mt-5'}
          placeholder={'********'}
          type="password"
        />
        <InputText
          value={passwordConfirmation}
          setValue={setPasswordConfirmation}
          fieldTitle={'Confirmation de mot de passe'}
          isRequired
          emptyError={`Le champ "Confirmation de mot de passe" ne peut être vide.`}
          style={'tw-mt-5'}
          placeholder={'********'}
          type="password"
        />

        {password !== passwordConfirmation &&
          password.length > 0 &&
          passwordConfirmation.length > 0 && (
            <div className="tw-text-sm tw-text-input-required tw-mt-2">
              Les deux mots de passe ne sont pas identiques.
            </div>
          )}

        {password.length < 6 && (
          <div className="tw-text-sm tw-text-input-required tw-mt-2">
            Le mot de passe doit faire au moins 6 caractères.
          </div>
        )}
      </div>
    </GenericModal>
  );
}
