import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Input } from '@headlessui/react';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

const InputOtp = forwardRef(
  (
    { value, setValue, handleFocusPrevRef, handleFocusNextRef, disabled },
    ref
  ) => {
    const inputRef = useRef();

    useImperativeHandle(
      ref,
      () => {
        return {
          focus() {
            inputRef.current.focus();
          },
          blur() {
            inputRef.current.blur();
          },
        };
      },
      []
    );

    const allowedKeys = [
      '0',
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
      'Backspace',
      'ArrowLeft',
      'ArrowRight',
    ];

    const handleOnChangePin = (event) => {
      if (event.target.value.length <= 1) {
        setValue(event.target.value);
      }
      if (event.target.value.length > 1 && handleFocusNextRef) {
        setValue(event.nativeEvent.data);
        handleFocusNextRef();
      }
      if (event.target.value.length === 1 && handleFocusNextRef) {
        handleFocusNextRef();
      }
    };

    const handleOnKeyDown = (event) => {
      if (!allowedKeys.includes(event.key)) {
        event.preventDefault();
      }
      if (value === '' && event.key === 'Backspace' && handleFocusPrevRef) {
        handleFocusPrevRef();
      }
    };

    return (
      <Input
        className={`tw-border-input-border tw-border-solid tw-border-1 focus:tw-border-input-borderFocused tw-w-10 tw-text-center tw-rounded-xl tw-text-2xl tw-p-2 tw-margin-0 tw-appearance-none`}
        value={value}
        onKeyDown={handleOnKeyDown}
        onChange={handleOnChangePin}
        maxLength={1}
        disabled={disabled}
        type="tel"
        ref={inputRef}
      />
    );
  }
);
InputOtp.displayName = 'InputOtp';

export default function Otp({ value, onPinComplete, isLoading, isError }) {
  const [pinOne, setPinOne] = useState(value?.substring(0, 1));
  const [pinTwo, setPinTwo] = useState(value?.substring(1, 2));
  const [pinThree, setPinThree] = useState(value?.substring(2, 3));
  const [pinFour, setPinFour] = useState(value?.substring(3, 4));
  const [pinFive, setPinFive] = useState(value?.substring(4, 5));
  const [pinSix, setPinSix] = useState(value?.substring(5, 6));
  const [isRendered, setIsRendered] = useState(false);

  const refPinOne = useRef();
  const refPinTwo = useRef();
  const refPinThree = useRef();
  const refPinFour = useRef();
  const refPinFive = useRef();
  const refPinSix = useRef();

  useEffect(() => {
    if (
      isRendered &&
      pinOne?.length === 1 &&
      pinTwo?.length === 1 &&
      pinThree?.length === 1 &&
      pinFour?.length === 1 &&
      pinFive?.length === 1 &&
      pinSix?.length === 1
    ) {
      onPinComplete(pinOne + pinTwo + pinThree + pinFour + pinFive + pinSix);
    } else {
      setIsRendered(true);
    }
  }, [pinOne, pinTwo, pinThree, pinFour, pinFive, pinSix]);

  const handleFocusRef = (ref) => {
    ref.current.focus();
  };

  return (
    <div className="tw-space-x-2 tw-flex tw-items-center">
      <InputOtp
        value={pinOne}
        setValue={setPinOne}
        ref={refPinOne}
        disabled={isError}
        handleFocusPrevRef={null}
        handleFocusNextRef={() => handleFocusRef(refPinTwo)}
      />
      <InputOtp
        value={pinTwo}
        setValue={setPinTwo}
        ref={refPinTwo}
        disabled={isError}
        handleFocusPrevRef={() => handleFocusRef(refPinOne)}
        handleFocusNextRef={() => handleFocusRef(refPinThree)}
      />
      <InputOtp
        value={pinThree}
        setValue={setPinThree}
        ref={refPinThree}
        disabled={isError}
        handleFocusPrevRef={() => handleFocusRef(refPinTwo)}
        handleFocusNextRef={() => handleFocusRef(refPinFour)}
      />
      <InputOtp
        value={pinFour}
        setValue={setPinFour}
        ref={refPinFour}
        disabled={isError}
        handleFocusPrevRef={() => handleFocusRef(refPinThree)}
        handleFocusNextRef={() => handleFocusRef(refPinFive)}
      />
      <InputOtp
        value={pinFive}
        setValue={setPinFive}
        ref={refPinFive}
        disabled={isError}
        handleFocusPrevRef={() => handleFocusRef(refPinFour)}
        handleFocusNextRef={() => handleFocusRef(refPinSix)}
      />
      <InputOtp
        value={pinSix}
        setValue={setPinSix}
        ref={refPinSix}
        disabled={isError}
        handleFocusPrevRef={() => handleFocusRef(refPinFive)}
        handleFocusNextRef={null}
      />
      {isLoading && (
        <FontAwesomeIcon
          icon={faCircleNotch}
          className="tw-animate-spin tw-size-6 tw-text-main tw-ml-2"
        />
      )}
    </div>
  );
}
