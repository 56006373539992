import React, { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import fleetBrand from '../../assets/images/logos/FleetWhite.png';
import './forgot.css';

export default function Forgot() {
  const [email, setEmail] = useState('');

  const BASE_URL = useSelector((state) => state.BASE_URL);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const sendForgottenPasswordEmail = (e) => {
    e.preventDefault();

    const url = new URL(BASE_URL + '/auth/send-forgot-password-token');
    let myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');

    let myInit = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({
        email: email,
      }),
      cache: 'no-cache',
    };

    fetch(url, myInit)
      .then((response) => {
        if (response.ok) {
          response.json().then((result) => {
            toast.success(result?.message);
          });
        } else if (response.status === 400) {
          response.json().then((result) => {
            if (result?.errors[0]?.param === 'email') {
              toast.warn('Votre email ne semble pas valide.');
            } else {
              toast.error("Une erreur s'est produite.");
            }
          });
        } else {
          toast.error("Une erreur s'est produite.");
        }
      })
      .catch(function () {
        toast.error("Une erreur s'est produite.");
      });
  };

  return (
    <div style={{ height: '100%' }}>
      <Row className="align-items-center h-100">
        <Col lg={6} className="d-flex align-items-center">
          <div className="container-form">
            <h2 className="welcome">
              Demande de réinitialisation <br /> de votre mot de passe
            </h2>
            <p className="p-info">
              Saisissez l'adresse e-mail associé à votre compte. Nous vous{' '}
              <br /> enverrons un lien par e-mail redirigeant sur une autre page
              de <br />
              notre site, vous permettant de réinitialiser votre mot de passe.
            </p>
            <Form>
              <Form.Label className="label">Adresse email</Form.Label>
              <Form.Control
                className="custom-input-forgot"
                type="email"
                placeholder="Votre adresse email"
                onChange={handleEmailChange}
                defaultValue={email}
                aria-describedby="basic-addon1"
              />
              <div style={{ width: '100%' }}>
                <Button
                  className={'send-btn mt-4'}
                  variant="none"
                  type="submit"
                  onClick={sendForgottenPasswordEmail}
                  block="true"
                >
                  Envoyer le lien de réinitialisation
                </Button>
                <Link to="/login">
                  <p className="back-login mt-3">
                    Retour à la page de connexion
                  </p>
                </Link>
              </div>
            </Form>
          </div>
        </Col>
        <Col
          lg={6}
          className="h-100 d-sm-none d-lg-block"
          style={{ backgroundColor: '#079992' }}
        >
          <div className="background-img d-flex align-items-center justify-content-center">
            <img src={fleetBrand} alt="logo" className="logo-login" />
          </div>
        </Col>
      </Row>
    </div>
  );
}
