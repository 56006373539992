import moment from 'moment';
import 'moment/locale/fr';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import GenericPageContainer from '../../components/agv-generic-page-container';
import SearchFilter from '../../components/agv-header-search';
import ReactDatePicker from 'react-datepicker';
import SelectFilter from '../../components/agv-header-select';
import AgrivitechPagination from '../../components/agv-pagination';
import CustomDatePickerDashboard from '../../components/agv-header-date-picker';
import useGetRequest from '../../api/useGetRequest';

import './admin.css';

import { useNavigate, useSearchParams } from 'react-router-dom';
import { ORDER_BY_ENUM } from '../../enums/orderby.enum';
import { buildUrlWithParams } from '../../utils/url.utils';
import { faArrowDownWideShort, faArrowUpWideShort } from '@fortawesome/free-solid-svg-icons';
import Datatable from '../../components/agv-datatable/index.js';
import { useSelector } from 'react-redux';

const Admin = () => {

  const navigate = useNavigate();
  const config = useSelector((state) => state.config);

  const [invoicesFiltered, setInvoicesFiltered] = useState([]);
  const aboPrice = 20.0;
  const smsPrice = 0.1;

  const [searchParams] = useSearchParams();
  const [filters, setFilters] = React.useState({
    limit: 20,
    offset: searchParams.get('query') || 10,
    query: searchParams.get('query') || '',
    order:
      searchParams.get('order') === ORDER_BY_ENUM.ASC ||
      searchParams.get('order') === ORDER_BY_ENUM.DESC
        ? searchParams.get('order')
        : ORDER_BY_ENUM.DESC,
    ...(searchParams.get('start_date') &&
      !isNaN(new Date(searchParams.get('start_date'))) && {
        start_date: moment(new Date(searchParams.get('start_date'))).format(
          'YYYY-MM-DD'
        ),
      }),
    ...(searchParams.get('end_date') &&
      !isNaN(new Date(searchParams.get('end_date'))) && {
        end_date: moment(new Date(searchParams.get('end_date'))).format(
          'YYYY-MM-DD'
        ),
      }),
  });

  const onChangeDate = (dates) => {
    const [start, end] = dates;
    handleUpdateParam('start_date', start);
    handleUpdateParam('end_date', end);
  };

  /**
   * Handle the update of filters when a filter is changed
   * @param {string} key - The key of the filter
   * @param {string} value - The value of the filter
   * @returns {void}
   */
  const handleUpdateParam = useCallback(
    (key, value) => {
      setFilters((prevFilters) => {
        const newFilters = { ...prevFilters, [key]: value };

        if (key !== 'offset') {
          newFilters.offset = 0;
        }

        if (key === 'start_date' || key === 'end_date') {
          if (value) {
            newFilters[key] = moment(value).format('YYYY-MM-DD');
          } else {
            delete newFilters[key];
          }
        }

        if (
          key === 'status' &&
          (value === null || value === undefined || value === '')
        ) {
          delete newFilters.status;
        }

        return newFilters;
      });
    },
    [navigate]
  );

  const url = useMemo(
    () => buildUrlWithParams('/admin/invoices', filters),
    [filters]
  );

  const { data, isLoading, isFirstLoad } = useGetRequest(url, 300);

  useEffect(() => {
    const params = new URLSearchParams(filters);
    navigate({ search: params.toString() }, { replace: true });
  }, [filters, navigate]);


  useEffect(() => {
    // Si un seul client est retourné, sélectionner directement celui-là.
    if (data?.invoicesPerClient?.length === 1) {
      setInvoicesFiltered(data?.invoicesPerClient?.[0]?.invoices);
    }
  }, [data]);


  const selectInvoicesForClientId = (clientId) => {
    const invoicesFiltered =
    data?.invoicesPerClient?.filter((client) => client?.client_id == clientId)?.[0]
        ?.invoices ?? [];
    setInvoicesFiltered(invoicesFiltered);
  };

  return (
    <>
    <GenericPageContainer
        title={'Factures'}
        subtitle={'Consultez l\'historique de vos factures Fleet'}
        filters={
          <>
            <SearchFilter
              value={filters.query}
              setValue={(value) => handleUpdateParam('query', value)}
              disabled={isLoading}
            />
            <div className="tw-relative">
              <ReactDatePicker
                selected={filters.start_date}
                onChange={onChangeDate}
                customInput={<CustomDatePickerDashboard />}
                // new Date si value car il faut une date et la valeur est "YYYY-MM-DD"
                startDate={
                  filters.start_date ? new Date(filters.start_date) : null
                }
                endDate={filters.end_date ? new Date(filters.end_date) : null}
                selectsRange
                locale="fr"
                dateFormat="dd MMM yyyy"
              />
            </div>
            <SelectFilter
              value={filters.order}
              onChange={(value) => handleUpdateParam('order', value)}
              options={[
                { value: ORDER_BY_ENUM.DESC, name: 'Desc.' },
                { value: ORDER_BY_ENUM.ASC, name: 'Asc.' },
              ]}
              icon={
                filters.order === ORDER_BY_ENUM.DESC
                  ? faArrowUpWideShort
                  : faArrowDownWideShort
              }
            />
            {config.is_super_admin ? 
              <SelectFilter
                onChange={selectInvoicesForClientId}
                options={[
                  { value: '', name: 'Client' },
                  { value: '1', name: 'Sévéal' },
                  { value: '2' , name: 'Capvert' },
                  { value: '4' , name: 'Novagrain' },
                  { value: '5' , name: 'Cooperl' },
                  { value: '6' , name: 'Moulins Dumée' },
                ]}
              />
            : null}
          </>
        }
      >
        <Datatable
          style={'tw-mt-5'}
          isLoading={isLoading}
          isFirstLoading={isFirstLoad && isLoading} // Utilisation de isFirstLoad
          header={[
            'Période',
            'Véhicules actifs',
            'Véhicules externes actifs',
            'Abonnement',
            'Email envoyés',
            'SMS envoyés',
            'Coûts SMS',
            'TOTAL FACTURE',
          ]}
          data={invoicesFiltered}
          values={(element) => [
            moment(element?.end_date).format('MMMM YYYY'),
            element?.vehicle_count,
            0,
            (element?.vehicle_count * aboPrice).toFixed(2)+" € ht",
            element?.email_count,
            <p
              key={`${element?.id}_roles`}
              className="tw-mb-1 tw-leading-3 tw-flex tw-flex-wrap tw-justify-start tw-items-start tw-gap-1"
            >
              {element?.sms_count}
            </p>,
            <div key={`${element?.id}_status`} className="tw-flex">
              {(element?.sms_count * smsPrice).toFixed(2)+" € ht"}
            </div>,
            ((element?.sms_count * smsPrice) + (element?.vehicle_count * aboPrice)).toFixed(2)+" € ht",
          ]}
        />

        <AgrivitechPagination
          data={invoicesFiltered}
          setOffset={(value) => handleUpdateParam('offset', value)}
        />
      </GenericPageContainer>
      
    </>
  );
};

export default Admin;
