import axios from 'axios';
import React, { useState } from 'react';
import { Col, ProgressBar, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import DragAndDrop from '../../../components/agv-drag-and-drop';

import notebook from '../../../assets/images/illustrations/notebook.png';
import GenericPageContainer from '../../../components/agv-generic-page-container';
import AddFileUploadSuccessModal from './add-file-upload-success';

export default function AddFile() {
  const supportedFiles = ['.xlsx', '.xlsm', '.csv', '.xls'];
  const supportedMimeTypes = [
    'text/csv',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-excel',
    'application/vnd.ms-excel.sheet.macroenabled.12',
    'application/vnd.ms-excel.sheet.macroEnabled.12',
  ];

  const BASE_URL = useSelector((state) => state.BASE_URL);

  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [status, setStatus] = useState("En attente d'un fichier...");
  const [isOpenAddFileUploadSuccess, setIsOpenAddFileUploadSuccess] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadResult, setUploadResult] = useState(null);
  const [file, setFile] = useState(null);

  const uploadFile = (e) => {
    if (e.target.files) {
      handleDrop(e.target.files);
      e.target.value = null;
    }
  };

  const handleDrop = (files) => {
    if (!files[0]) return;

    setFile(files[0]);

    if (!supportedMimeTypes.includes(files[0].type)) {
      setStatus(
        `Le type de fichier est incorrect, voici les types de fichiers supportés : ${supportedFiles.join(', ')}`
      );
      setTimeout(() => {
        setStatus("En attente d'un fichier...");
      }, 8000); // Changé de 3000 à 5000 ms
      return;
    }

    const formData = new FormData();
    formData.append('file', files[0], files[0].name);
    formData.append('dry_run', '1');

    const token = localStorage.getItem('fleetToken');

    const options = {
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
        Authorization: token,
      },
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total);
        if (percent < 100) {
          setUploadPercentage(percent);
          setStatus('Téléchargement du fichier en cours...');
        }
      },
    };

    setUploadPercentage(10);
    setStatus('Téléchargement du fichier en cours...');
    setIsLoading(true);

    axios
      .post(BASE_URL + '/tours/upload', formData, options)
      .then((res) => {
        setUploadPercentage(100);
        setStatus('Fichier téléchargé avec succès');
        toast.info('Fichier téléchargé avec succès.');
        setUploadResult(res.data);
        setIsOpenAddFileUploadSuccess(true);
      })
      .catch((err) => {
        const errorMessage =
          err.response?.data?.message ||
          'Une erreur est survenue lors du téléchargement du fichier.';
        toast.error(errorMessage);
        setStatus(errorMessage);
      })
      .finally(() => {
        setIsLoading(false);
        setTimeout(() => {
          setUploadPercentage(0);
          setStatus("En attente d'un fichier...");
        }, 5000); // Changé de 3000 à 5000 ms
      });
  };

  return (
    <>
      <GenericPageContainer
        title={'Import de tournée'}
        subtitle={'Importez votre liste des BLs pour votre prochaine tournée'}
        filters={''}
      >
        <div className={'fleet-item-upload'}>
          <DragAndDrop handleDrop={handleDrop} disabled={uploadPercentage > 0}>
            <div className={'upload-inner'}>
              <div>
                <img
                  src={notebook}
                  className={'img-illustration-fleet-upload'}
                  alt={'notebook'}
                />
              </div>
              <span className="fleet-upload-text-label">
                Glissez/Déposez votre fichier ici, ou{' '}
                <label htmlFor={'file-input'}>
                  <span className={'fleet-click-label-input'}>
                    Rechercher sur votre PC
                  </span>
                </label>
              </span>
              <div className="tw-flex tw-gap-2 tw-justify-center">
                {supportedFiles.map((fileType) => {
                  return (
                    <span key={fileType} className="badge-file-type">
                      {fileType.toUpperCase()}
                    </span>
                  );
                })}
              </div>
            </div>
          </DragAndDrop>

          <div className={'upload-inner-inprogress'}>
            <h5>{status}</h5>

            <ProgressBar
              variant="info"
              now={uploadPercentage}
              label={`${uploadPercentage}%`}
            />
          </div>

          <input
            type={'file'}
            id={'file-input'}
            className={'form-control'}
            style={{ display: 'none' }}
            onChange={uploadFile}
            accept={supportedFiles.join(', ')}
            disabled={uploadPercentage > 0}
          />
        </div>
      </GenericPageContainer>
      <AddFileUploadSuccessModal
        isOpen={isOpenAddFileUploadSuccess}
        setIsOpen={setIsOpenAddFileUploadSuccess}
        isLoading={isLoading}
        uploadResult={uploadResult}
        file={file}
      />
    </>
  );
}
