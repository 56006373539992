import React from 'react';

import earth from '../../../../assets/images/illustrations/notebook.png';
import './bl-optimization.scss';

function BlOptimization(props) {
  return (
    <div className="fleet-item-step-optimization-container">
      <div className={'fleet-item-bl-optimization'}>
        <div className={'fleet-first-line'}>
          <div className={'hw'}>
            <img
              src={earth}
              className={'img-illustration-fleet-step'}
              alt={'truck'}
            />
          </div>
          <div className={'fr'}>
            <span className={'fleet-item-title'}>
              {props.step.key || 'BL XXXXXXXX'}{' '}
            </span>
            <span className={'fleet-item-desc'}></span>
          </div>
          <div className={'hwe'}>
            <div className="hwe-child-wrapper">
              <span className="truck-capacity">
                {props.step.products_volume} L
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(BlOptimization);
