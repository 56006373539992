import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import fleetApi from '../../services/api.service';

export default function useGetVehicleDetails({ vehicleId }) {
  const [data, setData] = useState(null);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getDetails();
  }, []);

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error]);

  const getDetails = async () => {
    try {
      setIsLoading(true);
      const response = await fleetApi.get(`/vehicles/managements/${vehicleId}`);
      if (response.status === 200) {
        setData(response.data);
      } else {
        throw new Error();
      }
    } catch (error) {
      setError(error?.message || 'Une erreur est survenue.');
    } finally {
      setIsLoading(false);
    }
  };

  return {
    data,
    isLoading,
    refetch: getDetails,
    error,
  };
}
