import { faMessage, faUser } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import React from 'react';
import GenericModal from '../../components/agv-generic-modal';
import Row from '../../components/agv-generic-table/row';
import Status from '../../components/agv-status';
import { STATUS_ENUM } from '../../components/agv-status/status-enum';
import { MESSAGE_TYPE_DISPLAY } from '../../enums/message-type.enum';

export default function MessagingDetailsModal({ isOpen, setIsOpen, message }) {
  return (
    <GenericModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title={'Message aux chauffeurs'}
      mainAction={() => setIsOpen(false)}
      mainActionText={'Retour'}
    >
      <div className="tw-flex tw-flex-col tw-my-4">
        <Row
          title={`${message?.recipients?.[0]?.vehicle_key}${message?.recipients?.length > 1 ? ` +${message?.recipients?.length - 1} autre${message?.recipients?.length - 1 > 1 ? 's' : ''} destinataire${message?.recipients?.length - 1 > 1 ? 's' : ''}` : ''}`}
          subtitle={message?.message}
          icon={faMessage}
          iconColor={'tw-text-checklist-common'}
          status={STATUS_ENUM.NEUTRAL}
          statusText={'Envoyé'}
          style={
            'tw-px-0 tw-border-solid tw-border-0 tw-border-b tw-border-background tw-py-4'
          }
        />
      </div>
      <div className="tw-mb-4">
        <div className="tw-text-smi tw-font-work tw-text-dimgray-100">
          Envoyé le
        </div>
        <div className="tw-text-sm tw-mt-2">
          {moment(new Date(message?.created_at)).format(
            'DD MMM YYYY [à] HH:mm'
          )}
        </div>
      </div>
      <div className="tw-mb-4">
        <div className="tw-text-smi tw-font-work tw-text-dimgray-100">
          Type de message
        </div>
        <div className="tw-text-sm tw-mt-2">
          {MESSAGE_TYPE_DISPLAY[message?.notification_type]}
        </div>
      </div>
      <div className="tw-mb-4">
        <div className="tw-text-smi tw-font-work tw-text-dimgray-100">
          Message à afficher
        </div>
        <div className="tw-text-sm tw-mt-2">{message?.message}</div>
      </div>
      <div className="tw-mb-4">
        <div className="tw-text-smi tw-font-work tw-text-dimgray-100 tw-mb-2">
          Destinataires
        </div>
        {message?.recipients?.map((recipient, index) => (
          <div
            className="tw-flex tw-flex-row tw-items-center tw-px-0 tw-border-solid tw-border-0 tw-border-t tw-border-background tw-py-2"
            key={index}
          >
            <Row
              title={recipient?.driver_fullname ?? '-'}
              subtitle={`${recipient?.vehicle_key} • Tour ${recipient?.tour_number} • Départ le ${moment(new Date(recipient?.checklist_done_at)).format('DD MMM YYYY [à] HH:mm')}`}
              icon={faUser}
              iconColor={'tw-text-checklist-common'}
              style={'tw-flex tw-flex-1'}
            />
            <Status
              text={recipient?.has_been_read ? 'Vu' : 'Envoyé'}
              status={
                recipient?.has_been_read
                  ? STATUS_ENUM.POSITIVE
                  : STATUS_ENUM.NEUTRAL
              }
            />
          </div>
        ))}
      </div>
    </GenericModal>
  );
}
