export const STATUS_ENUM = {
  POSITIVE: 'POSITIVE',
  NEGATIVE: 'NEGATIVE',
  WARN: 'WARN',
  INFO: 'INFO',
  NEUTRAL: 'NEUTRAL',
  PRIMARY: 'PRIMARY',
  SECONDARY: 'SECONDARY',
};
export const STATUS_COLORS = {
  [STATUS_ENUM.POSITIVE]: {
    backgroundColor: 'tw-bg-status-positive/10',
    borderColor: 'tw-border-status-positive/15',
    textColor: 'tw-text-status-positive/55',
  },
  [STATUS_ENUM.NEGATIVE]: {
    backgroundColor: 'tw-bg-status-negative/10',
    borderColor: 'tw-border-status-negative/15',
    textColor: 'tw-text-status-negative/55',
  },
  [STATUS_ENUM.WARN]: {
    backgroundColor: 'tw-bg-status-warn/10',
    borderColor: 'tw-border-status-warn/15',
    textColor: 'tw-text-status-warn/55',
  },
  [STATUS_ENUM.INFO]: {
    backgroundColor: 'tw-bg-status-info/10',
    borderColor: 'tw-border-status-info/15',
    textColor: 'tw-text-status-info/55',
  },
  [STATUS_ENUM.NEUTRAL]: {
    backgroundColor: 'tw-bg-[#F2F3F5]',
    borderColor: 'tw-border-[#707070]/10',
    textColor: 'tw-text-status-neutral/55',
  },
  [STATUS_ENUM.PRIMARY]: {
    backgroundColor: 'tw-bg-status-primary/10',
    borderColor: 'tw-border-status-primary/15',
    textColor: 'tw-text-status-primary/55',
  },
  [STATUS_ENUM.SECONDARY]: {
    backgroundColor: 'tw-bg-status-secondary/10',
    borderColor: 'tw-border-status-secondary/15',
    textColor: 'tw-text-status-secondary/55',
  },
};
