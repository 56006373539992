import {
  faArrowDownShortWide,
  faArrowDownWideShort,
  faDownload,
} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo } from 'react';
import Datatable from '../../components/agv-datatable';
import GenericPageContainer from '../../components/agv-generic-page-container';
import HeaderButton from '../../components/agv-header-button';
import SearchFilter from '../../components/agv-header-search';

import ReactDatePicker from 'react-datepicker';
import { useNavigate, useSearchParams } from 'react-router-dom';
import usePostDocument from '../../api/documents/usePostDocuement';
import useGetRequest from '../../api/useGetRequest';
import MainButton from '../../components/agv-button';
import CustomDatePickerDashboard from '../../components/agv-header-date-picker';
import SelectFilter from '../../components/agv-header-select';
import AgrivitechPagination from '../../components/agv-pagination';
import DOCUMENT_TYPE from '../../enums/documents.enum';
import { ORDER_BY_ENUM } from '../../enums/orderby.enum';
import { buildUrlWithParams } from '../../utils/url.utils';

moment.locale('fr');

const Documents = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [filters, setFilters] = React.useState({
    limit: 10,
    offset: searchParams.get('query') || 0,
    query: searchParams.get('query') || '',
    order:
      searchParams.get('order') === ORDER_BY_ENUM.ASC ||
      searchParams.get('order') === ORDER_BY_ENUM.DESC
        ? searchParams.get('order')
        : ORDER_BY_ENUM.DESC,
    ...(searchParams.get('start_date') &&
      !isNaN(new Date(searchParams.get('start_date'))) && {
        start_date: moment(new Date(searchParams.get('start_date'))).format(
          'YYYY-MM-DD'
        ),
      }),
    ...(searchParams.get('end_date') &&
      !isNaN(new Date(searchParams.get('end_date'))) && {
        end_date: moment(new Date(searchParams.get('end_date'))).format(
          'YYYY-MM-DD'
        ),
      }),
  });

  const {
    postDocument,
    loading: postLoading,
    error: postError,
  } = usePostDocument('/api/documents');

  /**
   * Télécharge un document PDF.
   * @param {object} element - L'élément contenant l'URL du PDF à télécharger.
   * @returns {void}
   */
  const handleDownloadElement = (element) => {
    // Assurez-vous que 'element' contient la propriété 'url' avec l'URL du PDF
    if (element.url) {
      try {
        window.open(element.url, '_blank');
      } catch (err) {
        console.error('Error downloading document:', err);
      }
    } else {
      console.error('No URL found in the element');
    }
  };

  /**
   * Télécharge tous les documents PDF.
   * @returns {void}
   * @async
   * @throws {Error} - Une erreur si le téléchargement échoue.
   */
  const handleDownloadALL = async () => {
    if (data?.pagination_info?.total_items == 0) return;

    if (
      data?.pagination_info?.total_items > 5 &&
      data?.pagination_info?.total_items < 50
    ) {
      if (
        !window.confirm(
          `Vous êtes sur le point de télécharger ${data?.pagination_info?.total_items} documents. Continuer ?`
        )
      ) {
        return;
      }
    }

    if (data?.pagination_info?.total_items >= 50) {
      alert(
        `Le nombre de documents selectionnés (${data?.pagination_info?.total_items}) est trop important pour être téléchargé. Veuillez affiner votre recherche et réessayer.`
      );
      return;
    }

    try {
      await postDocument({
        query: filters.query,
        start_date: filters.start_date,
        end_date: filters.end_date,
      });
    } catch (err) {
      console.error('Error downloading document:', err);
    }
  };

  /**
   * Handle the change of the date filter
   * @param {array} dates - The selected dates
   * @returns {void}
   */
  const onChangeDate = (dates) => {
    const [start, end] = dates;
    handleUpdateParam('start_date', start);
    handleUpdateParam('end_date', end);
  };

  /**
   * Handle the update of filters when a filter is changed
   * @param {string} key - The key of the filter
   * @param {string} value - The value of the filter
   * @returns {void}
   */
  const handleUpdateParam = useCallback(
    (key, value) => {
      setFilters((prevFilters) => {
        const newFilters = { ...prevFilters, [key]: value };

        if (key !== 'offset') {
          newFilters.offset = 0;
        }

        if (key === 'start_date' || key === 'end_date') {
          if (value) {
            newFilters[key] = moment(value).format('YYYY-MM-DD');
          } else {
            delete newFilters[key];
          }
        }

        if (
          key === 'status' &&
          (value === null || value === undefined || value === '')
        ) {
          delete newFilters.status;
        }

        return newFilters;
      });
    },
    [navigate]
  );

  const url = useMemo(
    () => buildUrlWithParams('/documents', filters),
    [filters]
  );

  const { data, isLoading, mutate, isFirstLoad } = useGetRequest(url, 300);

  /**
   * Update the URL with the new filters
   * @returns {void}
   */
  useEffect(() => {
    const params = new URLSearchParams(filters);
    navigate({ search: params.toString() }, { replace: true });
  }, [filters, navigate]);

  return (
    <>
      <GenericPageContainer
        title={'Gestion des documents'}
        subtitle={'Consultez et modifiez les documents qui vous sont rattachés'}
        filters={
          <>
            <SearchFilter
              value={filters.query}
              setValue={(value) => handleUpdateParam('query', value)}
              disabled={isLoading}
            />
            <div className="tw-relative">
              <ReactDatePicker
                selected={filters.start_date}
                onChange={onChangeDate}
                customInput={<CustomDatePickerDashboard />}
                // new Date si value car il faut une date et la valeur est "YYYY-MM-DD"
                startDate={
                  filters.start_date ? new Date(filters.start_date) : null
                }
                endDate={filters.end_date ? new Date(filters.end_date) : null}
                selectsRange
                locale="fr"
                dateFormat="dd MMM yyyy"
              />
            </div>
            <SelectFilter
              value={filters.order}
              onChange={(value) => handleUpdateParam('order', value)}
              options={[
                { value: ORDER_BY_ENUM.DESC, name: 'Desc.' },
                { value: ORDER_BY_ENUM.ASC, name: 'Asc.' },
              ]}
              icon={
                filters.order === ORDER_BY_ENUM.DESC
                  ? faArrowDownWideShort
                  : faArrowDownShortWide
              }
            />
            <HeaderButton
              onClick={() => handleDownloadALL()}
              text={`Tout télécharger ${data?.pagination_info?.total_items ? `(${data.pagination_info?.total_items ?? 0})` : '(0)'}`}
              icon={faDownload}
              disabled={postLoading || data?.pagination_info?.total_items === 0}
            />
          </>
        }
      >
        <Datatable
          style={'tw-mt-5'}
          isLoading={isLoading}
          isFirstLoading={isFirstLoad && isLoading}
          header={[
            'Identifiant',
            'Type de document',
            'Chauffeur',
            'Camion',
            'Date de création',
            'Opérations',
          ]}
          data={data?.items}
          values={(element) => [
            element?.id,
            DOCUMENT_TYPE.properties[element?.doc_type]?.display_name,
            <>
              <p className="tw-mb-1 tw-leading-3">{element?.driver.type}</p>
              <p className="tw-mb-1 tw-leading-3 tw-text-text-dark-medium-importance">
                {element?.driver?.fullname}
              </p>
            </>,
            element?.vehicle.vehicule_key,
            element?.creation_date
              ? moment(new Date(element?.creation_date)).format(
                  'DD/MM/YY HH:mm:ss'
                )
              : 'Date invalide',
            <>
              {element?.url ? (
                <MainButton
                  key={`${element?.url}_action`}
                  onClick={() => handleDownloadElement(element)}
                  text={'Télécharger'}
                  variant={'link'}
                />
              ) : null}
            </>,
          ]}
        />
        {/* Pagination */}
        <AgrivitechPagination
          data={data}
          setOffset={(value) => handleUpdateParam('offset', value)}
        />
      </GenericPageContainer>
    </>
  );
};

export default Documents;
