import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

const themes = {
  default: {
    button: 'tw-px-[4px] tw-py-[2px] tw-border-none',
    items:
      'tw-bg-white tw-border tw-border-[#CDCDCA] tw-border-solid tw-p-1 tw-rounded',
    item: 'tw-text-text-dark-high-importance tw-rounded',
  },
};

export default function AgvTimePickerDropdown({
  onChange,
  defaultValue,
  theme = 'default',
  width,
}) {
  const currentTheme = themes[theme] || themes.default;
  const [selectedTime, setSelectedTime] = useState(defaultValue);

  const buttonClassName = classNames(
    'tw-relative tw-text-[13px] tw-underline tw-font-medium tw-font-work-sans tw-text-[#29a9a3] tw-text-left tw-px-4 tw-py-2',
    currentTheme.button
  );

  const generateTimeOptions = () => {
    const options = [];
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 15) {
        const time = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
        options.push({ value: time, name: time });
      }
    }
    return options;
  };

  const timeOptions = generateTimeOptions();

  const handleTimeChange = (time) => {
    setSelectedTime(time);
    onChange?.(time);
  };

  return (
    <Menu as="div" className="tw-relative">
      <MenuButton
        className={buttonClassName}
        style={{
          lineHeight: '20px',
          borderRadius: '5px',
          ...(width && { width }),
        }}
      >
        {selectedTime}
      </MenuButton>
      <MenuItems
        className={classNames(
          'tw-absolute tw-z-10 tw-mt-2 tw-w-32 tw-shadow-lg tw-max-h-60 tw-overflow-y-auto',
          currentTheme.items
        )}
      >
        {timeOptions.map((option) => (
          <MenuItem key={option.value}>
            {({ active }) => (
              <button
                className={classNames(
                  'tw-block tw-w-full tw-text-left tw-px-2 tw-py-1 tw-border-none tw-bg-transparent',
                  currentTheme.item,
                  'data-[headlessui-state~="active"]:tw-bg-black/5'
                )}
                onClick={() => handleTimeChange(option.value)}
              >
                {option.name}
              </button>
            )}
          </MenuItem>
        ))}
      </MenuItems>
    </Menu>
  );
}
