import React from 'react';
import './step-optimization.scss';

export default function StepOptimization({ bls, color, step, stepsNumber }) {
  let imgComponent = null;

  if (bls) {
    let inValue = false;
    let outValue = false;

    bls?.forEach((value) => {
      if (value.delivery_type != null) {
        if (value.delivery_type === 'LIVRAISON') {
          outValue = true;
        }
        if (value.delivery_type === 'REPRISE') {
          inValue = true;
        }
      }
    });

    if (inValue && !outValue) {
      imgComponent = (
        <img
          src={null}
          className={'img-illustration-fleet-step'}
          alt={'truck'}
        />
      );
    } else if (!inValue && outValue) {
      imgComponent = (
        <img
          src={null}
          className={'img-illustration-fleet-step'}
          alt={'truck'}
        />
      );
    } else if (inValue && outValue) {
      imgComponent = (
        <img
          src={null}
          className={'img-illustration-fleet-step'}
          alt={'truck'}
        />
      );
    } else {
      imgComponent = (
        <img
          src={null}
          className={'img-illustration-fleet-step'}
          alt={'truck'}
        />
      );
    }
  } else {
    imgComponent = (
      <img src={null} className={'img-illustration-fleet'} alt={'truck'} />
    );
  }

  return (
    <div className="fleet-item-step-optimization-container">
      <span className="fleet-step-opt-nb" style={{ backgroundColor: color }}>
        {stepsNumber}
      </span>
      <div className={'fleet-item-step-optimization'}>
        <div className={'fleet-first-line'}>
          <div className={'hw'}>{imgComponent}</div>
          <div className={'fr'}>
            <span className={'fleet-item-title'}>
              {step.name || 'Étape invalide'}{' '}
            </span>
            <span className={'fleet-item-desc'}>
              {step.address} {step.city}, {step.zipcode}
            </span>
          </div>
          <div className={'hwe'}>
            <div className="hwe-child-wrapper">
              <span className="truck-capacity">{step.bls_volume}L</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
