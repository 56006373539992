import React, { useEffect, useState } from 'react';
import useGetRequest from '../../api/useGetRequest';
import InputCombobox from '../../components/agv-forms-combobox/input-combobox';
import TextArea from '../../components/agv-forms-textarea';
import GenericModal from '../../components/agv-generic-modal';

export default function MessagingCreateModal({
  isOpen,
  setIsOpen,
  createMessage,
  mainActionIsLoading,
}) {
  const [tours, setTours] = useState([]);
  const [message, setMessage] = useState('');

  const { data, isLoading, mutate } = useGetRequest('/tours/for-messaging');

  useEffect(() => {
    mutate();
  }, []);

  useEffect(() => {
    if (isOpen) {
      setTours([]);
      setMessage('');
      mutate();
    }
  }, [isOpen]);

  const changeToursSelected = (tours) => {
    if (tours.includes('ALL')) {
      tours = data.map((element) => element.tour_id);
    }
    setTours(tours);
  };

  return (
    <GenericModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title={'Créer un nouveau message'}
      mainAction={() => createMessage(tours, message)}
      mainActionText={'Envoyer le message'}
      mainActionIsLoading={mainActionIsLoading}
      mainActionDisabled={!message.length || !tours.length}
    >
      <div className="tw-flex tw-flex-col tw-my-4">
        {data && (
          <InputCombobox
            value={tours}
            setValue={changeToursSelected}
            placeholder={'Sélectionnez parmi les tournées en cours'}
            fieldTitle={'Destinataires'}
            items={[
              {
                name: 'Sélectionner tous les chauffeurs',
                value: 'ALL',
              },
              ...data.map((element) => ({
                name: `Véhicule ${element?.vehicule_key} - Tour ${element?.tour_number} - ${element?.fullname}`,
                value: element?.tour_id,
              })),
            ]}
            isRequired
            emptyError={`Le champ "Destinataires" ne peut être vide.`}
            style={'tw-mt-6'}
            isMultiple
          />
        )}
        <TextArea
          value={message}
          setValue={setMessage}
          fieldTitle={'Message à afficher aux chauffeurs'}
          isRequired
          emptyError={`Le champ "Message" ne peut être vide.`}
          style={'tw-mt-6 tw-h-200'}
          placeholder={'Écrivez votre message...'}
          maxLength={300}
        />
      </div>
    </GenericModal>
  );
}
