import { Input } from '@headlessui/react';
import React from 'react';
import FieldTitle from '../agv-forms-title';

export default function InputText({
  value,
  setValue,
  fieldTitle,
  isRequired,
  disabled,
  style,
  emptyError,
  isBadFormat,
  placeholder,
  type = 'text',
  tooltip = 'requis',
  maxLength,
}) {
  const isEmpty = isRequired && !value?.length;
  const isError = isEmpty || isBadFormat;

  return (
    <div className={`${style} tw-flex tw-flex-col`}>
      {fieldTitle && (
        <FieldTitle
          title={fieldTitle}
          isError={isError}
          isRequired={isRequired}
          tooltip={tooltip}
        />
      )}
      <Input
        className={`${disabled ? 'tw-bg-white tw-cursor-not-allowed' : 'tw-bg-input-background'} tw-border-input-border focus:tw-border-input-borderFocused tw-border-solid tw-rounded-lg tw-px-3 tw-py-1.5 tw-text-sm`}
        value={value}
        onChange={(event) => setValue(event.target.value)}
        disabled={disabled}
        placeholder={placeholder}
        type={type}
        maxLength={maxLength}
      />
      {isEmpty && (
        <div className="tw-text-sm tw-text-input-required tw-mt-2">
          {emptyError}
        </div>
      )}
      {value?.length > 0 && isBadFormat && (
        <div className="tw-text-sm tw-text-input-required tw-mt-2">
          {"Le format n'est pas correct"}
        </div>
      )}
    </div>
  );
}
