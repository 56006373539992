import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Input } from '@headlessui/react';
import React from 'react';

export default function SearchFilter({ value, setValue }) {
  return (
    <div className="tw-relative">
      {/* padding-right => padding-left + right icon size + 1 de margin left */}
      <Input
        placeholder="Rechercher..."
        className={'tw-pl-4 tw-pr-10 header-button-style'}
        value={value}
        onChange={(event) => setValue(event.target.value)}
        style={{
          boxShadow: '0px 4px 2px 0px rgba(255, 255, 255, 0.1) inset',
          lineHeight: '20px',
          borderRadius: '5px',
        }}
      />
      <FontAwesomeIcon
        className="tw-group tw-pointer-events-none tw-absolute tw-top-1/3 tw-right-4 tw-size-2.5 tw-text-text-dark-high-importance"
        icon={faSearch}
      />
    </div>
  );
}
