import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import fleetApi from '../../services/api.service';
import { buildUrlWithParams } from '../../utils/url.utils';
import moment from 'moment';

let timeout = null;

export default function useGetKilometersHistory({ vehicleId }) {
  const [data, setData] = useState(null);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isFetched, setIsFetched] = useState(false);

  const [filters, setFilters] = useState({
    limit: 10,
    offset: 0,
  });

  useEffect(() => {
    if (isFetched || filters?.query?.length) {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        getKilometersHistory();
      }, 500);
    }
  }, [filters]);

  useEffect(() => {
    getKilometersHistory();
  }, []);

  const updateFilter = (key, value) => {
    setFilters((prevFilters) => {
      const newFilters = { ...prevFilters, [key]: value };
      if (key !== 'offset') {
        newFilters.offset = 0;
      }
      return newFilters;
    });
  };

  const getKilometersHistory = async () => {
    try {
      setIsLoading(true);

      const { ...restFilters } = filters;
      const params = {
        ...restFilters,
      };

      const url = buildUrlWithParams(
        `/vehicles/kilometers/${vehicleId}`,
        params
      );

      const response = await fleetApi.get(url);

      if (isFetched) {
        await new Promise((resolve) => setTimeout(resolve, 150));
      }

      if (response.status === 200) {
        setData((prevData) => ({
          ...response.data,
          items:
            prevData && filters.offset !== 0
              ? [...prevData.items, ...response.data.items]
              : response.data.items,
        }));
      } else {
        throw new Error();
      }
    } catch (error) {
      setError(error?.message || 'Une erreur est survenue.');
    } finally {
      setIsLoading(false);
      setIsFetched(true);
    }
  };

  const fetchNextPage = () => {
    updateFilter('offset', filters.offset + filters.limit);
  };

  return {
    data,
    isLoading,
    isFirstLoading: isLoading && !isFetched,
    refetch: getKilometersHistory,
    fetchNextPage,
    hasMore: data
      ? filters.offset + filters.limit < data.pagination_info.total_items
      : true,
    filters,
    updateFilter,
  };
}
