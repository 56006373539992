import { STATUS_ENUM } from "../components/agv-status/status-enum";

export const step_status = {
  CREATED: "CREATED",
  STARTED: "STARTED",
  DELIVERED: "DELIVERED",
  PARTIALLY_DELIVERED: "PARTIALLY_DELIVERED",
  CANCELED: "CANCELED",
  LATE: "LATE",
  properties: {
    CREATED: { 
      display_name: "Planifiée", 
      color: "#878c92", 
      color_class: "ok",
      style: STATUS_ENUM.NEUTRAL 
    },
    STARTED: {
      display_name: "Livraison en cours",
      color: "#079992",
      color_class: "started",
      style: STATUS_ENUM.INFO 
    },
    DELIVERED: { 
      display_name: "Livrée", 
      color: "#079992", 
      color_class: "ok",
      style: STATUS_ENUM.POSITIVE 
    },
    PARTIALLY_DELIVERED: {
      display_name: "Partiellement livrée",
      color: "#e55039",
      color_class: "issue",
      style: STATUS_ENUM.WARN 
    },
    CANCELED: {
      display_name: "Non livrée",
      color: "#e55039",
      color_class: "issue",
      style: STATUS_ENUM.NEGATIVE 
    },
    LATE: { 
      display_name: "En retard", 
      color: "#ffc251", 
      color_class: "late",
      style: STATUS_ENUM.WARN 
    },
  },
};
