import React, { useEffect, useRef, useState } from 'react';
export default function DragAndDrop({ children }) {
  const [drag, setDrag] = useState(false);

  const dropRef = useRef();

  let dragCounter = 0;

  const handleDrag = (e) => {
    e?.preventDefault();
    e?.stopPropagation();
  };

  const handleDragIn = (e) => {
    e?.preventDefault();
    e?.stopPropagation();
    dragCounter++;
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      setDrag(true);
    }
  };

  const handleDragOut = (e) => {
    e?.preventDefault();
    e?.stopPropagation();
    dragCounter--;
    if (dragCounter === 0) {
      setDrag(false);
    }
  };

  const handleDrop = (e) => {
    e?.preventDefault();
    e?.stopPropagation();
    setDrag(false);
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      handleDrop(e.dataTransfer.files);
      e.dataTransfer.clearData();
      dragCounter = 0;
    }
  };

  useEffect(() => {
    dropRef?.current?.addEventListener('dragenter', handleDragIn);
    dropRef?.current?.addEventListener('dragleave', handleDragOut);
    dropRef?.current?.addEventListener('dragover', handleDrag);
    dropRef?.current?.addEventListener('drop', handleDrop);

    return () => {
      dropRef?.current?.removeEventListener('dragenter', handleDragIn);
      dropRef?.current?.removeEventListener('dragleave', handleDragOut);
      dropRef?.current?.removeEventListener('dragover', handleDrag);
      dropRef?.current?.removeEventListener('drop', handleDrop);
    };
  }, []);

  return (
    <div style={{ display: 'block', position: 'relative' }} ref={dropRef}>
      {drag && (
        <div
          style={{
            border: 'dashed grey 4px',
            backgroundColor: 'rgba(255,255,255,.8)',
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 9999,
          }}
        >
          <div
            style={{
              position: 'absolute',
              top: '50%',
              right: 0,
              left: 0,
              textAlign: 'center',
              color: 'grey',
              fontSize: 36,
            }}
          >
            <div>drop here :)</div>
          </div>
        </div>
      )}
      {children}
    </div>
  );
}
