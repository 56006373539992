const FUEL = {
  DIESEL: 'DIESEL',
  GNC: 'GNC',
  ADBLUE: 'ADBLUE',
  properties: {
    DIESEL: {
      display_name: 'Diesel',
    },
    GNC: {
      display_name: 'GNC',
    },
    ADBLUE: {
      display_name: 'Adblue',
    },
  },
};

export default FUEL;