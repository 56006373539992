import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

const styleMap = {
  primary:
    'tw-text-white tw-border tw-border-white/10 relative tw-bg-main hover:tw-bg-main-hover active:tw-bg-main-active ',
  secondary:
    'tw-text-black tw-bg-white tw-border-black/10 tw-border hover:tw-bg-background/50 active:tw-bg-background ',
  link: 'tw-p-0 tw-text-primary tw-bg-transparent tw-border-none hover:tw-underline',
};

export default function MainButton({
  icon,
  text,
  onClick,
  disabled,
  isLoading,
  style,
  variant = 'primary',
}) {
  const buttonStyle = styleMap[variant] || styleMap.primary;

  return (
    <button
      onClick={onClick}
      disabled={disabled || isLoading}
      className={`${style} tw-flex tw-items-center disabled:tw-cursor-not-allowed disabled:tw-opacity-50 tw-py-2 tw-px-3 tw-text-sm tw-leading-[14px] tw-font-normal tw-font-work tw-rounded ${buttonStyle}`}
      style={{
        boxShadow:
          variant !== 'link'
            ? '0px -1px 0px rgba(0, 0, 0, 0.25) inset'
            : 'none',
      }}
    >
      {isLoading && (
        <FontAwesomeIcon
          className="tw-size-3 tw-mr-2 tw-animate-spin"
          icon={faCircleNotch}
        />
      )}
      {icon && (
        <FontAwesomeIcon
          className={`tw-size-3 ${text ? 'tw-mr-1' : ''}`}
          icon={icon}
        />
      )}
      {text}
    </button>
  );
}
