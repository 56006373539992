import { useState } from 'react';
import fleetApi from '../../services/api.service';

export default function usePutValidationStep() {
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(null);

  const putValidationStep = async (
    id,
    title,
    description,
    type,
    icon,
    is_archived
  ) => {
    resetStates();
    try {
      setIsLoading(true);
      const response = await fleetApi.put(`/checklist/${id}`, {
        title,
        description,
        type,
        icon,
        is_archived,
      });
      await new Promise((resolve) => setTimeout(resolve, 500));
      if (response.status === 200) {
        setIsSuccess(true);
      } else {
        setIsSuccess(false);
        throw new Error();
      }
    } catch (error) {
      setError(error?.message || 'Une erreur est survenue.');
      setIsSuccess(false);
    } finally {
      setIsLoading(false);
    }
  };

  const resetStates = () => {
    setError('');
    setIsSuccess(false);
    setIsLoading(false);
  };

  return {
    error,
    isLoading,
    isSuccess,
    putValidationStep,
  };
}
