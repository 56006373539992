import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import fleetApi from '../../services/api.service';

export default function usePutVehiclePin() {
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(null);

  useEffect(() => {
    if (isSuccess) {
      toast.success('Code pin mis à jour avec succès.');
    }
  }, [isSuccess]);

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error]);

  const putVehiclePin = async (pin, vehicleId) => {
    resetStates();
    try {
      setIsLoading(true);
      const response = await fleetApi.put(
        `/vehicles/change-pin-code/${vehicleId}`,
        {
          pin,
        }
      );
      await new Promise((resolve) => setTimeout(resolve, 500));
      if (response.status === 200) {
        setIsSuccess(true);
      } else {
        setIsSuccess(false);
        throw new Error();
      }
    } catch (error) {
      setError(error?.message || 'Une erreur est survenue.');
      setIsSuccess(false);
    } finally {
      setIsLoading(false);
    }
  };

  const resetStates = () => {
    setError('');
    setIsSuccess(false);
    setIsLoading(false);
  };

  return {
    error,
    isLoading,
    isSuccess,
    putVehiclePin,
  };
}
