import { useEffect, useState } from 'react';
import fleetApi from '../../services/api.service';

export default function useGetVehicleQrCode({ vehicleId }) {
  const [data, setData] = useState(null);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getQrCode();
  }, []);

  const getQrCode = async () => {
    try {
      setIsLoading(true);
      const response = await fleetApi.get(
        `vehicles/managements/download-qr-code/${vehicleId}`,
        { responseType: 'blob' }
      );
      if (response.status === 200) {
        setData(response.data);
      } else {
        throw new Error();
      }
    } catch (error) {
      setError(error?.message || 'Une erreur est survenue.');
    } finally {
      setIsLoading(false);
    }
  };

  return {
    data,
    isLoading,
    error,
  };
}
