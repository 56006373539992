import { faPlus } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useGetRequest from '../../api/useGetRequest';
import useCreateUser from '../../api/users/useCreateUser';
import useDeleteUser from '../../api/users/useDeleteUser';
import useEditUser from '../../api/users/useEditUser';
import MainButton from '../../components/agv-button';
import Datatable from '../../components/agv-datatable';
import GenericPageContainer from '../../components/agv-generic-page-container';
import HeaderButton from '../../components/agv-header-button';
import SearchFilter from '../../components/agv-header-search';
import AgrivitechPagination from '../../components/agv-pagination';
import Status from '../../components/agv-status/index';
import { STATUS_ENUM } from '../../components/agv-status/status-enum';
import { ROLE_ENUM_COLOR, ROLE_ENUM_DISPLAY } from '../../enums/role-enum.enum';
import CreateUserModal from './create-user-modal';
import EditUserModal from './edit-user-modal';
import { buildUrlWithParams } from '../../utils/url.utils';
import useGetClients from '../../api/admin/useGetClients';
import { useSelector } from 'react-redux';

export default function Users() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const config = useSelector((state) => state.config);

  const [isOpenCreate, setIsOpenCreate] = useState(false);
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [user, setUser] = useState(null);
  const [filters, setFilters] = useState({
    limit: 10,
    offset: searchParams.get('offset') || 0,
    query: searchParams.get('query') || '',
  });

  const {
    data: dataGetClients,
    isSuccess: isSuccessGetClients,
    isLoading: isLoadingGetClients,
    getClients,
  } = useGetClients();

  const {
    createUser,
    isLoading: isLoadingCreateUser,
    isSuccess: isSuccessCreateUser,
  } = useCreateUser();

  const {
    editUser,
    isLoading: isLoadingEditUser,
    isSuccess: isSuccessEditUser,
  } = useEditUser();

  const {
    deleteUser,
    isLoading: isLoadingDeleteUser,
    isSuccess: isSuccessDeleteUser,
  } = useDeleteUser();

  useEffect(() => {
    if (config?.is_super_admin) {
      getClients();
    }
  }, [config?.is_super_admin]);

  useEffect(() => {
    if (isSuccessCreateUser) {
      setIsOpenCreate(false);
      mutate();
    }
  }, [isSuccessCreateUser]);

  useEffect(() => {
    if (isSuccessEditUser || isSuccessDeleteUser) {
      setIsOpenEdit(false);
      mutate();
    }
  }, [isSuccessEditUser, isSuccessDeleteUser]);

  /**
   * Open the modal with the selected element
   * @param {object} element - The selected element
   * @returns {void}
   */
  const onOpenModal = (element) => {
    setUser(element);
    setIsOpenEdit(true);
  };

  /**
   * Handle the update of filters when a filter is changed
   * @param {string} key - The key of the filter
   * @param {string} value - The value of the filter
   * @returns {void}
   */
  const handleUpdateParam = useCallback(
    (key, value) => {
      setFilters((prevFilters) => {
        const newFilters = { ...prevFilters, [key]: value };

        if (key !== 'offset') {
          newFilters.offset = 0;
        }

        if (key === 'start_date' || key === 'end_date') {
          if (value) {
            newFilters[key] = moment(value).format('YYYY-MM-DD');
          } else {
            delete newFilters[key];
          }
        }

        if (
          key === 'status' &&
          (value === null || value === undefined || value === '')
        ) {
          delete newFilters.status;
        }

        return newFilters;
      });
    },
    [navigate]
  );

  const url = useMemo(() => buildUrlWithParams('/users', filters), [filters]);

  const { data, isLoading, isFirstLoad, mutate } = useGetRequest(url, 300);

  /**
   * Update the URL with the new filters
   * @returns {void}
   */
  useEffect(() => {
    const params = new URLSearchParams(filters);
    navigate({ search: params.toString() }, { replace: true });
  }, [filters, navigate]);

  return (
    <>
      <GenericPageContainer
        title={'Gestion des utilisateurs'}
        subtitle={
          'Consultez et modifiez les utilisateurs qui vous sont rattachés'
        }
        filters={
          <>
            <SearchFilter
              value={filters.query}
              setValue={(value) => handleUpdateParam('query', value)}
              disabled={isLoading}
            />
            <HeaderButton
              onClick={() => setIsOpenCreate(true)}
              text={'Créer'}
              icon={faPlus}
            />
          </>
        }
      >
        <Datatable
          style={'tw-mt-5'}
          isLoading={isLoading}
          isFirstLoading={isFirstLoad && isLoading} // Utilisation de isFirstLoad
          header={[
            'ID client',
            'Nom et prénom',
            'Contact',
            'Dernière connexion',
            'Création',
            'Rôles',
            'Accès',
            'Opérations',
          ]}
          data={data?.items}
          values={(element) => [
            element?.id,
            element?.fullname,
            <>
              <p className="tw-mb-1 tw-leading-3">{element?.email}</p>
              <p className="tw-mb-1 tw-leading-3 tw-text-text-dark-medium-importance">
                {element?.phone_number}
              </p>
            </>,
            element?.last_login_date
              ? moment(new Date(element?.last_login_date)).format(
                  'DD/MM/YY HH:mm:ss'
                )
              : 'Aucune connexion',
            moment(new Date(element?.created_at)).format('DD/MM/YY HH:mm:ss'),
            <p
              key={`${element?.id}_roles`}
              className="tw-mb-1 tw-leading-3 tw-flex tw-flex-wrap tw-justify-start tw-items-start tw-gap-1"
            >
              {element?.roles.map((role) => (
                <Status
                  key={role}
                  text={ROLE_ENUM_DISPLAY[role]}
                  status={ROLE_ENUM_COLOR[role]}
                  style={''}
                />
              ))}
            </p>,
            <div key={`${element?.id}_status`} className="tw-flex">
              <Status
                text={element?.is_password_defined ? 'Actif' : 'En attente'}
                status={
                  element?.is_password_defined
                    ? STATUS_ENUM.NEUTRAL
                    : STATUS_ENUM.WARN
                }
              />
            </div>,
            <MainButton
              key={`${element?.id}_action`}
              onClick={() => onOpenModal(element)}
              text={'Détails'}
              variant="link"
            />,
          ]}
        />

        {/* Pagination */}
        <AgrivitechPagination
          data={data}
          setOffset={(value) => handleUpdateParam('offset', value)}
        />
      </GenericPageContainer>

      <CreateUserModal
        isOpen={isOpenCreate}
        setIsOpen={setIsOpenCreate}
        createUser={createUser}
        mainActionIsLoading={isLoadingCreateUser || isLoadingGetClients}
        clientsData={dataGetClients}
      />

      <EditUserModal
        isOpen={isOpenEdit}
        setIsOpen={setIsOpenEdit}
        editUser={editUser}
        user={user}
        mainActionIsLoading={isLoadingEditUser || isLoadingGetClients}
        secondaryActionIsLoading={isLoadingDeleteUser}
        deleteUser={deleteUser}
        clientsData={dataGetClients}
      />
    </>
  );
}
