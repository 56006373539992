import PropTypes from 'prop-types';
import React from 'react';
import ReactPaginate from 'react-paginate';
// import './pagination.css'; // Assurez-vous d'ajouter les styles nécessaires pour ce composant

const AgrivitechPagination = ({ data, setOffset, onPageChange }) => {
  const currentPage = data?.pagination_info?.current_page ?? 0;
  const itemsPerPage = data?.pagination_info?.items_per_page ?? 0;
  const totalItems = data?.pagination_info?.total_items ?? 0;
  const startResult = (currentPage - 1) * itemsPerPage + 1;
  const endResult = Math.min(currentPage * itemsPerPage, totalItems);

  const handleOnPageChange = (page) => {
    if (setOffset) {
      setOffset(page.selected * data?.pagination_info?.items_per_page);
    } else {
      onPageChange(page);
    }
  };

  if (totalItems < 1) {
    return null;
  }

  return (
    <div className="agrivitech-pagination">
      <div className="agrivitech-pagination-text tw-mb-2">
        résultats {startResult} à {endResult} sur un total de {totalItems}
      </div>
      <ReactPaginate
        breakLabel="..."
        nextLabel=">"
        forcePage={currentPage - 1}
        className="agrivitech-pagination-list"
        onPageChange={handleOnPageChange}
        pageRangeDisplayed={2}
        pageCount={data?.pagination_info?.total_pages}
        previousLabel="<"
        renderOnZeroPageCount={null}
      />
    </div>
  );
};

AgrivitechPagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
  totalItems: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

export default AgrivitechPagination;
