import { useState } from 'react';
import { toast } from 'react-toastify';
import fleetApi from '../../services/api.service';
import { ApiError } from '../../utils/error.util';
import moment from 'moment';

const usePostDocument = (url) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const postDocument = async (filters) => {
    setIsLoading(true);
    setError(null); // Reset error before starting a new request

    try {
      const { start_date, end_date, query } = filters;

      const params = {
        ...(query && { query }),
        ...(start_date && {
          start_date: moment(start_date).format('YYYY-MM-DD'),
        }),
        ...(end_date && { end_date: moment(end_date).format('YYYY-MM-DD') }),
      };

      const response = await fleetApi.post(
        '/documents/download-matching-documents',
        params,
        { responseType: 'blob' }
      );

      if (response.status !== 200) {
        throw new ApiError(
          response.data?.message || 'Une erreur est survenue.',
          response
        );
      }

      // Create a link to download the file
      const blob = new Blob([response.data], { type: 'application/zip' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.setAttribute(
        'download',
        `documents_${moment().format('YYYYMMDD')}.zip`
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(link.href); // Clean up the object URL

      toast.success('Document téléchargé.');

      return response.data;
    } catch (err) {
      setError(err.message || 'Une erreur est survenue.');
      toast.error(err.message || 'Une erreur est survenue.');
      throw err; // Re-throw the error for the calling component to handle if needed
    } finally {
      setIsLoading(false);
    }
  };

  return { postDocument, isLoading, error };
};

export default usePostDocument;
