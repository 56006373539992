import { useState, useEffect } from 'react';
import fleetApi from '../../services/api.service';

export default function useGetTourDetails(tourId) {
  const [details, setDetails] = useState(null);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (tourId) {
      getTourDetails();
    }
  }, [tourId]);

  const getTourDetails = async () => {
    try {
      setIsLoading(true);

      const url = `/tours/${tourId}`;
      const response = await fleetApi.get(url);

      if (response.status === 200) {
        setDetails(response.data?.tour);
      } else {
        throw new Error('Failed to fetch tour details');
      }
    } catch (error) {
      setDetails(null);
      setError(error?.message || 'An error occurred');
    } finally {
      setIsLoading(false);
    }
  };

  return {
    details,
    error,
    isLoading,
    refetch: getTourDetails,
  };
}
