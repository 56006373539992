import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import './modal-reserve.scss';

import { faClipboard } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import useGetReserveDetails from '../../../api/reserves/useGetReserveDetails';
import BlReserve from '../../../components/__OLD__/BlReserve';
import Log from '../../../components/__OLD__/Log';
import Message from '../../../components/__OLD__/Message';
import { MODAL_SIZE, MODAL_SIZE_STYLE } from '../../../enums/modal-size.enum';
import { reserve_status } from '../../../enums/reserve-status.enum';
import ProductReserve from '../product-reserve';

function ModalReserve({ returnReserveSubtitle, reserve_id, show, onHide }) {
  const [comment, setComment] = useState('');
  const [events, setEvents] = useState([]);
  const [selectedBls, setSelectedBls] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [modalSize, setModalSize] = useState(MODAL_SIZE_STYLE[MODAL_SIZE.MD]);

  const features = useSelector((state) => state.features);
  const BASE_URL = useSelector((state) => state.BASE_URL);
  const TOKEN = localStorage.getItem('fleetToken');

  const {
    reserveDetails,
    error,
    isLoading,
    refetch: getReserveDetails,
  } = useGetReserveDetails(reserve_id);

  const CREATION_DATE = new Date(reserveDetails?.reserve?.created_at);

  useEffect(() => {
    getReserveDetails;
    setComment('');
  }, [reserve_id]);

  useEffect(() => {
    if (reserveDetails) {
      let bls_already_linked = [];
      reserveDetails?.reserve?.delivery_details_available?.forEach((bl) => {
        if (bl?.is_already_linked) {
          bls_already_linked.push(bl?.delivery_detail_key);
        }
      });
      setSelectedBls(bls_already_linked);

      let events = [];
      let creation_log = reserveDetails?.reserve?.logs?.filter(
        (element) => element?.type === 'CREATION_LOG'
      )[0];
      if (creation_log) {
        events.push({
          text: creation_log?.message,
          type: 'LOG',
        });
      }
      if (reserveDetails?.reserve?.message?.length) {
        events.push({
          text: reserveDetails?.reserve?.message,
          type: reserveDetails?.reserve?.type,
        });
      }
      let response_log = reserveDetails?.reserve?.logs?.filter(
        (element) => element?.type === 'ANSWERED_AT'
      )[0];
      if (response_log) {
        events.push({
          text: response_log?.message,
          type: 'LOG',
        });
      }
      if (reserveDetails?.reserve?.response?.length) {
        events.push({
          text: reserveDetails?.reserve?.response,
          type: 'RESPONSE',
        });
      }

      setEvents(events);
    }
  }, [reserveDetails]);

  const handleSubmitMethod = async () => {
    if (!reserve_id) {
      throw new Error('Impossible to find the Reserve !');
    }

    let resultUpdateBls, resultSolve;

    let have_to_update = false;
    selectedBls.forEach((element) => {
      if (
        !reserveDetails?.reserve?.delivery_details_linked
          ?.map((bl) => bl.delivery_detail_key)
          ?.includes(element)
      ) {
        have_to_update = true;
      }
    });

    if (have_to_update) {
      try {
        const response = await updateBls();
        if (!response.ok) {
          const error = await response.json();

          throw new Error(error.message || response.status);
        }
        resultUpdateBls = await response.json();
      } catch (error) {
        console.log(error.toString());
      }
    }
    if (!have_to_update || resultUpdateBls?.has_been_updated) {
      try {
        const response = await solveReserve();
        if (!response.ok) {
          const error = await response.json();
          throw new Error(error || response.status);
        }
        resultSolve = await response.json();
      } catch (error) {
        console.log(error.toString());
      }
    }

    if (resultSolve?.has_been_updated) {
      onHide(true);
    }
  };

  const updateBls = async () => {
    const body = {};
    let selectedBlsIds = reserveDetails?.reserve?.delivery_details_linked
      ?.filter((bl) => selectedBls.includes(bl?.delivery_detail_key))
      ?.map((bl) => bl?.delivery_detail_id);
    if (selectedBlsIds?.length) {
      body['delivery_detail_ids'] = selectedBlsIds;
    }

    let myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Authorization', TOKEN);
    let myInit = {
      method: 'PUT',
      headers: myHeaders,
      cache: 'no-cache',
      body: JSON.stringify(body),
    };

    return fetch(
      BASE_URL + `/reserves/${reserve_id}/change-delivery-details`,
      myInit
    );
  };

  const solveReserve = async () => {
    const body = {};

    if (comment?.length) {
      body['response'] = comment;
    }

    let myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Authorization', TOKEN);
    let myInit = {
      method: 'PUT',
      headers: myHeaders,
      cache: 'no-cache',
      body: JSON.stringify(body),
    };

    return fetch(BASE_URL + `/reserves/${reserve_id}/solved`, myInit);
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size={modalSize}
      contentClassName="modal-content-reserve"
      onClick={() => setIsOpen(false)}
    >
      <Modal.Header className="modal-header-reserve" closeButton>
        <div className="claim-header-inner-modal">
          <div className="header-title">
            {`${
              reserveDetails?.reserve?.status !== 'SOLVED' ? 'Traiter r' : 'R'
            }éserve ${reserveDetails?.reserve?.id}`}
          </div>
        </div>
      </Modal.Header>
      <Modal.Body className="modal-body-reserve">
        <div>
          <div className="head-section">
            <div className="left-part">
              <div className="icon-container">
                <div className="icon-wrapper">
                  <FontAwesomeIcon
                    icon={faClipboard}
                    height={19}
                    className="select-none"
                    color="#ABB5BF"
                    width={19}
                  />
                </div>
              </div>
              <div>
                <div className="bls-title">
                  {returnReserveSubtitle(reserveDetails?.reserve)}
                </div>
                <div className="reserve-date">
                  {`Créée le ${CREATION_DATE.toLocaleDateString('fr-FR', {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                  })} à ${CREATION_DATE.toLocaleTimeString('fr-FR', {
                    hour: '2-digit',
                    minute: '2-digit',
                  })}`}
                </div>
              </div>
            </div>
            <div className="reserve-status">
              <span
                className="badge"
                style={{
                  color:
                    reserve_status.properties[reserveDetails?.reserve?.status]
                      ?.color,
                }}
              >
                {
                  reserve_status.properties[reserveDetails?.reserve?.status]
                    ?.display_name
                }
              </span>
            </div>
          </div>

          {reserveDetails?.reserve?.vehicle_key && (
            <div className="tw-mb-4">
              <div className="tw-text-[11px] tw-font-semibold tw-uppercase tw-text-black">
                N° de Véhicule
              </div>
              <div className="tw-text-smi tw-mt-2 tw-px-3.5 tw-py-1.5 tw-bg-whitesmoke-100 tw-rounded-md tw-box-border tw-border-[1px] tw-border-solid tw-border-border-primary-strong">
                {reserveDetails?.reserve?.vehicle_key}
              </div>
            </div>
          )}

          {reserveDetails?.reserve?.tour_key && (
            <div className="tw-mb-4">
              <div className="tw-text-[11px] tw-font-semibold tw-uppercase tw-text-black">
                ID du Tour
              </div>
              <div className="tw-text-smi tw-mt-2 tw-px-3.5 tw-py-1.5 tw-bg-whitesmoke-100 tw-rounded-md tw-box-border tw-border-[1px] tw-border-solid tw-border-border-primary-strong">
                {features?.tour_number_only_last_digit
                  ? reserveDetails.reserve.tour_key.slice(-1)
                  : reserveDetails.reserve.tour_key}
              </div>
            </div>
          )}

          {reserveDetails?.reserve?.receiver_name && (
            <div className="tw-mb-4">
              <div className="tw-text-[11px] tw-font-semibold tw-uppercase tw-text-black">
                Nom du client
              </div>
              <div className="tw-text-smi tw-mt-2 tw-px-3.5 tw-py-1.5 tw-bg-whitesmoke-100 tw-rounded-md tw-box-border tw-border-[1px] tw-border-solid tw-border-border-primary-strong">
                {reserveDetails?.reserve?.receiver_name}
              </div>
            </div>
          )}

          {reserveDetails?.reserve?.status === 'SOLVED' &&
            reserveDetails?.delivery_details_linked?.length > 0 && (
              <div className="section">
                <div className="subtitle-reserve">BONS DE LIVRAISON</div>
                <div className="text-description-reserve">
                  Retrouvez la liste des BLs concernés par la réserve.
                </div>

                <div style={{ marginTop: '1rem' }}>
                  {reserveDetails?.reserve?.delivery_details_linked?.map(
                    (bl) => (
                      <BlReserve key={bl?.delivery_detail_id} bl={bl} />
                    )
                  )}
                </div>
              </div>
            )}

          <div className="section">
            <div className="subtitle-reserve">HISTORIQUE DE L'ÉCHANGE</div>
            <div className="text-description-reserve">
              Retrouvez l'entièreté de l'historique des échanges concernant
              cette réserve.
            </div>

            <div className="messages ">
              {events.map((event) => {
                return event?.type === 'LOG' ? (
                  <Log log={event?.text} />
                ) : (
                  <Message message={event?.text} type={event?.type} />
                );
              })}
            </div>
          </div>

          {reserveDetails?.reserve?.documents?.length > 0 && (
            <div className="section">
              <div className="subtitle-reserve">PHOTOS</div>
              <div className="text-description-reserve">
                Photos associées à la réserve :
              </div>

              <div className=" tw-flex" style={{ marginTop: '1rem' }}>
                {reserveDetails?.reserve?.documents?.map((photo) => {
                  return (
                    <div
                      key={photo?.id}
                      className="tw-overflow-hidden tw-rounded-lg tw-h-[150px] tw-w-[120px] tw-mr-2"
                    >
                      <a
                        href={photo?.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={photo?.url}
                          alt="photo de réserve"
                          className="tw-h-full tw-w-full tw-object-cover tw-object-center"
                        />
                      </a>
                    </div>
                  );
                })}
              </div>
            </div>
          )}

          {reserveDetails?.reserve?.products?.length > 0 && (
            <div className="section">
              <div className="subtitle-reserve">PRODUITS CONCERNÉS</div>
              <div className="text-description-reserve">
                Veuillez retrouver ci-dessous la liste des produits signalés par
                l'utilisateur pour réclamation :
              </div>

              <div style={{ marginTop: '1rem' }}>
                {reserveDetails?.reserve?.products?.map((product, index) => {
                  return <ProductReserve key={index} product={product} />;
                })}
              </div>
            </div>
          )}

          {reserveDetails?.reserve?.status !== 'SOLVED' && (
            <>
              {/* <div className="section">
                <div className="subtitle-reserve">
                  COMMANDE(S) CONCERNÉE(S)
                </div>
                <div className="text-description-reserve">
                  Vous pouvez réassigner les commandes concernées par la réserve si
                  besoin.
                </div>

                <div className="bls-select-container">
                  <Select
                    values={reserveDetails?.reserve?.delivery_details_available
                      ?.filter((bl) => bl?.is_valid)
                      ?.map((bl) => bl?.delivery_detail_key)}
                    selectedValues={selectedBls}
                    setSelectedValues={setSelectedBls}
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                  />
                  <div className="bls-selected">
                    {selectedBls?.length} commande{selectedBls?.length > 1 ? 's' : ''} concernée
                    {selectedBls?.length > 1 ? 's' : ''}
                  </div>
                </div>
              </div> */}

              <div className="section">
                <div className="subtitle-reserve">
                  AJOUTER UN COMMENTAIRE (OPTIONNEL)
                </div>
                {/* <div className="text-description-reserve">
                  Ajoutez un message visible par le destinataire sur sa commande
                  digitappro.
                </div> */}

                <textarea
                  onChange={(event) => setComment(event.target.value)}
                  value={comment}
                  className="reserve-comment"
                  placeholder="Votre message..."
                />
              </div>
            </>
          )}
        </div>
      </Modal.Body>

      <Modal.Footer className="modal-reserve-footer justify-content-center">
        {error?.length > 0 && <div className="error">{error}</div>}
        <div className="modal-reserve-footer-body">
          <Button
            variant="secondary"
            className="modal-btn-footer"
            disabled={isLoading || reserveDetails?.reserve?.status === 'SOLVED'}
            onClick={() => {
              handleSubmitMethod();
            }}
          >
            {reserveDetails?.reserve?.status !== 'SOLVED'
              ? 'Traiter la réserve'
              : 'Réserve traitée'}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalReserve;
