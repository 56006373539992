import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlus,
  faPen,
  faMinus,
  faEyeSlash,
} from '@fortawesome/free-solid-svg-icons';
import GenericModal from '../../../components/agv-generic-modal';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export default function AddFileUploadSuccessModal({
  isOpen,
  setIsOpen,
  isLoading,
  uploadResult,
  file,
}) {
  const BASE_URL = useSelector((state) => state.BASE_URL);
  const [error, setError] = useState(null);
  const [isFinalizing, setIsFinalizing] = useState(false);
  const navigate = useNavigate();

  const handleFinalizeImport = () => {
    setIsFinalizing(true);
    setError(null);

    const token = localStorage.getItem('fleetToken');
    const formData = new FormData();
    formData.append('file', file);
    formData.append('dry_run', '0');

    axios
      .post(BASE_URL + '/tours/upload', formData, {
        headers: {
          'content-type': 'application/x-www-form-urlencoded',
          Authorization: token,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          toast.success('Importation réussie.');
          setIsOpen(false);
          navigate(
            '/tours?limit=10&offset=0&query=&orderBy=DESC&status=IMPORTED'
          );
        } else {
          setError("Une erreur est survenue lors de l'importation.");
        }
      })
      .catch(() => {
        setError("Une erreur est survenue lors de l'importation.");
      })
      .finally(() => {
        setIsFinalizing(false);
      });
  };

  return (
    <GenericModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title={'Importation des tournées'}
      mainActionText={
        isFinalizing ? 'Chargement...' : 'Importer définitivement'
      }
      mainActionIsLoading={isLoading || isFinalizing}
      mainAction={handleFinalizeImport}
    >
      <div className="tw-flex tw-flex-col tw-gap-6">
        <div className="tw-flex tw-flex-col tw-items-center">
          <div className="">
            <div className="tw-text-sm tw-font-medium">
              Votre fichier à été importé et traité. Un résumé provisoire de
              l'opération est disponible ci-dessous.
            </div>
            <div className="tw-mt-4 tw-text-sm tw-font-medium">
              Confirmez pour appliquer les changements ou fermez la fenêtre pour
              revenir à l'étape précédente.
            </div>
          </div>
        </div>

        <div className="tw-w-full tw-relative tw-rounded-lg tw-bg-[#f8f8f9] tw-border-[#dfe3e6] tw-border-[1px] tw-border-solid tw-box-border tw-overflow-hidden tw-flex tw-flex-col tw-items-start tw-justify-center tw-py-2 tw-px-3 tw-gap-[5px] tw-min-w-[447px] tw-text-left tw-text-sm tw-text-[rgba(0,0,0,0.9)] tw-font-button-text">
          <div className="tw-flex tw-flex-row tw-items-center tw-justify-start tw-gap-2">
            <FontAwesomeIcon
              icon={faPlus}
              className="tw-w-[13px] tw-h-[13px] tw-text-[#5CB85C]"
            />
            <div className="tw-relative tw-leading-[14px] tw-text-ellipsis tw-whitespace-nowrap">
              {uploadResult?.tours_created} tournées créée
            </div>
          </div>
          <div className="tw-flex tw-flex-row tw-items-center tw-justify-start tw-gap-2">
            <FontAwesomeIcon
              icon={faPen}
              className="tw-w-[13px] tw-h-[13px] tw-text-[#DE9B43]"
            />
            <div className="tw-relative tw-leading-[14px] tw-text-ellipsis tw-whitespace-nowrap">
              {uploadResult?.tours_updated} tournées modifiées
            </div>
          </div>
          <div className="tw-flex tw-flex-row tw-items-center tw-justify-start tw-gap-2">
            <FontAwesomeIcon
              icon={faMinus}
              className="tw-w-[13px] tw-h-[13px] tw-text-[#D9413D]"
            />
            <div className="tw-relative tw-leading-[14px] tw-text-ellipsis tw-whitespace-nowrap">
              {uploadResult?.tours_deleted} tournées supprimés
            </div>
          </div>
          <div className="tw-flex tw-flex-row tw-items-center tw-justify-start tw-gap-2">
            <FontAwesomeIcon
              icon={faEyeSlash}
              className="tw-w-[13px] tw-h-[13px] tw-text-[#4D88FF]"
            />
            <div className="tw-relative tw-leading-[14px] tw-text-ellipsis tw-whitespace-nowrap">
              {uploadResult?.tours_ignored} tournées ignorées
            </div>
          </div>
          <div className="tw-flex tw-flex-row tw-items-center tw-justify-start tw-gap-2">
            <FontAwesomeIcon
              icon={faPlus}
              className="tw-w-[13px] tw-h-[13px] tw-text-[#5CB85C]"
            />
            <div className="tw-relative tw-leading-[14px] tw-text-ellipsis tw-whitespace-nowrap">
              {uploadResult?.vehicle_created} véhicules créée
            </div>
          </div>
          <div className="tw-flex tw-flex-row tw-items-center tw-justify-start tw-gap-2">
            <FontAwesomeIcon
              icon={faPlus}
              className="tw-w-[13px] tw-h-[13px] tw-text-[#5CB85C]"
            />
            <div className="tw-relative tw-leading-[14px] tw-text-ellipsis tw-whitespace-nowrap">
              {uploadResult?.driver_created} chauffeurs créée
            </div>
          </div>
        </div>

        <div className="tw-flex tw-flex-col">
          <div
            className={`tw-text-sm ${
              error ? 'tw-text-red-500' : 'tw-invisible'
            }`}
          >
            {error || 'No error'}
          </div>
        </div>
      </div>
    </GenericModal>
  );
}
