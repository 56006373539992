import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionContext,
  Badge,
  Button,
  Modal,
} from 'react-bootstrap';
import bgdeco from '../../../../assets/images/background/background_decoration2x.png';
import './optimization-detail-modal.scss';

import StepItemOptimization from '../../../../components/__OLD__/StepItemOptimization';
// import Swiper core and required modules

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
// import SwiperCore, { Navigation, Pagination } from "swiper";

// SwiperCore.use([Navigation, Pagination]);

function DistributionItemContainer(props) {
  return (
    <div className="distribution-item-container">
      <div
        className="distribution-item"
        style={
          props?.shouldHighlight
            ? !props.highlight
              ? { opacity: 0.3 }
              : null
            : null
        }
      >
        <div className="distribution-container-number">{props.index}</div>
        <div className="distribution-item-bloc-wrapper">{props.children}</div>
      </div>
      <div className="mt-2 d-flex justify-content-center">
        <Badge pill bg="" className="max-container">
          {props.maxvolume} L max.
        </Badge>
      </div>
    </div>
  );
}

const DistributionItem = React.memo(function DistributionItemWrapped(props) {
  const colorArray = [
    ['#1e3799', '#0c2461'],
    ['#E19F3C', '#C18A37'],
  ];

  const product_index = props.productIndex % 2 === 0 ? 0 : 1;

  return (
    <>
      <div
        className="distribution-item-bloc"
        style={{
          height: props.height,
          backgroundColor: colorArray[product_index][0],
          borderColor: colorArray[product_index][1],
        }}
      >
        <span>
          {props.quantity} {props.unit}
        </span>
      </div>
    </>
  );
});

function OptimizationDetailModal(props) {
  // const [swiper, setSwiper] = useState(null);
  const [openedTourKey, setOpenedTourKey] = useState(null);
  const [stepsToHighlight, setStepsToHighlight] = useState([]);

  useEffect(() => {
    if (openedTourKey) {
      let _stepsToHighlight = [];

      for (let i = 0; i < props.selectedtour?.containers?.length; i++) {
        if (props.selectedtour?.containers[i]?.step_uuid === openedTourKey) {
          if (!_stepsToHighlight.includes(openedTourKey)) {
            _stepsToHighlight.push(openedTourKey);
            break;
          }
        }
        for (
          let j = 0;
          j < props.selectedtour?.containers[i]?.products?.length;
          j++
        ) {
          // DO SOMETHING ?
        }
      }
      setStepsToHighlight(_stepsToHighlight);
    } else {
      setStepsToHighlight([]);
    }
  }, [openedTourKey, props.selectedtour]);

  return (
    <Modal
      {...props}
      dialogClassName="modal-90w detail-modal"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onShow={() => {
        setStepsToHighlight([]);
        setOpenedTourKey(null);
      }}
    >
      <Modal.Header closeButton>
        <div className="upload-header-inner">
          <h5>Détails Véhicule {props.selectedtour?.vehicule_key}</h5>
          <p>
            Cliquez sur une étape pour consulter les BL à livrer pour le camion
            immatriculé n° {props.selectedtour?.imat}
          </p>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="vehicle-detail-modal-body">
          <div className="upload-main-wrapper">
            <div className="left-part">
              <div className="tour-upload-item-wrapper">
                {props.selectedtour?.steps?.length > 0 ? (
                  <Accordion>
                    {props.selectedtour?.steps?.map((value) => {
                      return (
                        <StepItemOptimization
                          key={`StedItemWrapper_${value?.name}_${value?.address}_${value?.city}_${value?.zipcode}`}
                          step={value}
                          accordionContext={AccordionContext}
                          openedTourKey={openedTourKey}
                          setOpenedTourKey={setOpenedTourKey}
                        />
                      );
                    })}
                  </Accordion>
                ) : !props.isLoading ? (
                  <div>Aucune étape pour cette tournée</div>
                ) : (
                  <div>
                    <div className={'loader-section'}>loading</div>
                  </div>
                )}
              </div>

              <img
                src={bgdeco}
                alt="background_decoration_fleet"
                className="bg-deco-fleet"
              />

              {/* <div className="tour-upload-button">
                <Button variant="" className="button-confirm">
                  Retour à l'optimisation
                </Button>
              </div> */}
            </div>
            <div className="right-part">
              <div className="distribution-header">
                <h5>Distribution des produits par conteneur</h5>
                <p>
                  Aperçu de la distribution des produits pour chaque conteneur
                  du camion
                </p>
              </div>
              <div className="distribution-container">
                <div className="distribution-wrapper">
                  {props.show ? (
                    <Swiper
                      spaceBetween={10}
                      updateOnWindowResize={true}
                      slidesPerView={'auto'}
                      // onSwiper={(swiper) => setSwiper(swiper)}
                      observer={true}
                      pagination={true}
                      id="main"
                      navigation={true}
                      centerInsufficientSlides={
                        props.selectedtour?.containers?.length < 4
                      }
                    >
                      {props.selectedtour?.containers?.length > 0 &&
                        props.selectedtour?.containers?.map(
                          (container, container_index) => {
                            return (
                              <SwiperSlide key={`key_index_${container_index}`}>
                                <DistributionItemContainer
                                  index={container?.container_key}
                                  highlight={stepsToHighlight.includes(
                                    container?.step_uuid
                                  )}
                                  maxvolume={container?.max_volume}
                                  shouldHighlight={stepsToHighlight.length > 0}
                                >
                                  {container?.products?.length > 0 &&
                                    container?.products?.map((product) => {
                                      //get random int in table
                                      return (
                                        <DistributionItem
                                          key={`DistributionItem_${product?.key}`}
                                          quantity={
                                            product?.volume_in_container
                                          }
                                          unit={'L'}
                                          height={`${
                                            (product?.volume_in_container /
                                              container?.max_volume) *
                                            100
                                          }%`}
                                          shouldHighlight
                                          maxvolume={container?.max_volume}
                                          productIndex={container_index}
                                        />
                                      );
                                    })}
                                </DistributionItemContainer>
                              </SwiperSlide>
                            );
                          }
                        )}
                    </Swiper>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="tour-upload-button">
          <div className="left-part">
            <Button
              variant=""
              className="button-confirm"
              onClick={() => props.onHide()}
            >
              Retour à l'optimisation
            </Button>
          </div>
          <div className="right-part"></div>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default OptimizationDetailModal;
