import { useState } from 'react';
import { toast } from 'react-toastify';
import fleetApi from '../../services/api.service';

export default function usePostSetupPassword() {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(null);

  const setupPassword = async (token, password) => {
    resetStates();
    try {
      setIsLoading(true);
      const response = await fleetApi.post(`/auth/setup-password`, {
        token,
        password,
      });
      await new Promise((resolve) => setTimeout(resolve, 500));
      if (response.status === 200) {
        setIsSuccess(true);
        toast.success(
          response.data?.message || 'Votre compte a bien été créé.'
        );
      } else {
        throw new Error(response.data?.message || 'Une erreur est survenue.');
      }
    } catch (error) {
      setIsSuccess(false);
      toast.error(error?.response.data?.message || error?.message);
    } finally {
      setIsLoading(false);
    }
  };

  const resetStates = () => {
    setIsSuccess(false);
    setIsLoading(false);
  };

  return {
    isLoading,
    isSuccess,
    setupPassword,
  };
}
