import {
  faCube,
  faKey,
  faPlusCircle,
  faTrash,
  faTruck,
  faWeightHanging,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Button, Card, Col, Modal, Row } from 'react-bootstrap';
import SimpleBarReact from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import truck from '../../../../assets/images/icons/__OLD__/truck.png';
import './modal-add-truck.scss';

import { v1 as uuidv1 } from 'uuid';

import { Form, Formik, useField } from 'formik';
import SkeletonLoader from 'tiny-skeleton-loader-react';
import * as Yup from 'yup';
import BadgeColor from '../../platformColorBadge';

// SwiperCore.use([Navigation, Pagination]);

// import Swiper core and required modules

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

const add_truck_title_and_desc = [
  {
    title: "Ajout d'un véhicule",
    desc: 'Commencez par saisir les information générales du véhicule',
  },
  {
    title: "Ajout d'un véhicule: définition des compartiments",
    desc: 'Décrire les compartiments de votre véhicule',
  },
  {
    title: "Ajout d'un véhicule: platforme associée",
    desc: 'Choisissez la plateforme associée à ce véhicule',
  },
  {
    title: "Ajout d'un véhicule: confirmation",
    desc: 'Vérifiez les paramètres de votre camion',
  },
];

export const GenericFomikTextInput = ({ children, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input>. We can use field meta to show an error
  // message if the field is invalid and it has been touched (i.e. visited)
  const [field, meta] = useField(props);

  return (
    <div className={'form-group'}>
      {children ? (
        <label className="modal-span-title" htmlFor={props.id || props.name}>
          {children}
        </label>
      ) : null}
      <br />
      <input className="modal-input-col-left" {...field} {...props} />
      <div className="error">
        {meta.touched && meta.error ? meta.error : null}
      </div>
    </div>
  );
};

function ModalAddVehicule(props) {
  const initialValues = {
    vehicle_type: props.selectedVehicle?.vehicle_type || null,
    imat: props.selectedVehicle?.imat || '',
    vehicule_key: props.selectedVehicle?.vehicule_key || 'XXX',
    pin: '123456',
    length: props.selectedVehicle?.length || 0,
    width: props.selectedVehicle?.width || 0,
    height: props.selectedVehicle?.height || 0,
    starting_platform_id: null,
    containers: props.selectedVehicle?.containers || [],
    is_disabled: props.selectedVehicle?.is_disabled || 0,
  };

  const vehicle = initialValues;
  const [step, setStep] = useState(0);
  const [swiper, setSwiper] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const [toggleAddContainer, setToggleAddContainer] = useState(false);
  const [addContainerMaxWeight, setAddContainerMaxWeight] = useState(0);
  const [addContainerMaxVolume, setAddContainerMaxVolume] = useState(0);

  const goToNext = () => {
    if (swiper) {
      if (step + 1 < 4) {
        swiper.slideTo(step + 1);
        setStep(step + 1);
      }
    }
  };
  const goToPrev = () => {
    if (swiper) {
      if (step - 1 >= 0) {
        swiper.slideTo(step - 1);
        setStep(step - 1);
      }
    }
  };

  const canGoToNextStep = (values, errors) => {
    switch (step) {
      case 0:
        return (
          !values.vehicle_type ||
          !values.imat ||
          !values.vehicule_key ||
          !values.length ||
          !values.width ||
          !values.height
        );
      case 1:
        return (
          !values.containers ||
          values.containers.length === 0 ||
          errors.containers
        );
      case 3:
        return !values.starting_platform_id;
      default:
        return false;
    }
  };

  const addNewContainer = (containers, setFieldValue) => {
    const newContainers = [...containers];
    newContainers.push({
      uuid: uuidv1(),
      max_weight: addContainerMaxWeight,
      max_volume: addContainerMaxVolume,
    });
    setFieldValue('containers', newContainers);
    setToggleAddContainer(false);
    resetAddContainerInput();
  };

  const deleteContainer = (key, containers, setFieldValue) => {
    let newContainers = [...containers];
    newContainers = newContainers.filter((x) => x.uuid !== key);

    setFieldValue('containers', newContainers);
    setToggleAddContainer(false);
    resetAddContainerInput();
  };

  const resetAddContainerInput = () => {
    setAddContainerMaxWeight(0);
    setAddContainerMaxVolume(0);
  };

  const setStartingPlateformID = (_id, setFieldValue) => {
    if (_id) {
      setFieldValue('starting_platform_id', _id);
      goToNext();
    }
  };

  /******
   *
   * functions
   *
   ******/

  const handleSubmitMethod = async (values) => {
    setIsLoading(true);

    if (props.selectedVehicle) {
      return await putVehicle(values);
    } else {
      return await postVehicle(values);
    }
  };

  const postVehicle = async (values) => {
    const url = new URL(props.BASE_URL + '/vehicles');
    const token = localStorage.getItem('fleetToken');

    setIsLoading(true);

    let myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Authorization', token);
    let myInit = {
      method: 'POST',
      headers: myHeaders,
      cache: 'no-cache',
      body: JSON.stringify({
        ...values,
      }),
    };

    return fetch(url, myInit)
      .then(async (response) => {
        return await response?.json();
      })
      .catch((error) => {
        console.log(
          "Il y a eu un problème avec l'opération fetch: " + error.message
        );
        return false;
      });
  };

  const putVehicle = async (values) => {
    const url = new URL(
      props.BASE_URL +
        '/vehicles/change-pin-code/' +
        props.selectedVehicle.vehicule_id
    );
    const token = localStorage.getItem('fleetToken');

    setIsLoading(true);

    let myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Authorization', token);
    let myInit = {
      method: 'PUT',
      headers: myHeaders,
      cache: 'no-cache',
      body: JSON.stringify({
        ...values,
      }),
    };

    return fetch(url, myInit)
      .then(async (response) => {
        return await response?.json();
      })
      .catch((error) => {
        console.log(
          "Il y a eu un problème avec l'opération fetch: " + error.message
        );
        return false;
      });
  };

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size="xl"
      onShow={() => {
        setToggleAddContainer(false);
        resetAddContainerInput();
        setStep(0);
      }}
    >
      <div className="background-modal-fleet-add-truck" />
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object({
          imat: Yup.string().required('Requis'),
          vehicule_key: Yup.string().required('Requis'),
          length: Yup.number()
            .required('Requis')
            .positive('La longueur doit être supérieur à 0.'),
          width: Yup.number()
            .required('Requis')
            .positive('La largeur doit être supérieur à 0.'),
          height: Yup.number()
            .required('Requis')
            .positive('La hauteur doit être supérieur à 0.'),
          containers: Yup.array().of(
            Yup.object().shape({
              max_volume: Yup.number().required('Requis').positive(),
              max_weight: Yup.number().required('Requis').positive(),
            })
          ),
        })}
        onSubmit={(values, { setSubmitting }) => {
          handleSubmitMethod(values).then(async (response) => {
            setIsLoading(false);
            setSubmitting(false);

            if (response?.status === 'Success') {
              setError(null);
              props.onHide(true);
            } else {
              setError(response?.error || 'une erreur est survenue');
            }
          });
        }}
      >
        {({ errors, values, submitForm, setFieldValue }) => (
          <Form>
            <Modal.Header className="modal-add-vehicule-header" closeButton>
              <div className="claim-header-inner-modal-add-veh">
                <div>
                  <h5>{add_truck_title_and_desc[step].title}</h5>
                  <p>{add_truck_title_and_desc[step].desc}</p>
                </div>
              </div>
            </Modal.Header>
            <Modal.Body className="modal-swiper-design">
              <Swiper
                spaceBetween={10}
                updateOnWindowResize={true}
                slidesPerView={'auto'}
                observer={true}
                id="main"
                pagination={true}
                onSwiper={(swiper) => setSwiper(swiper)}
                allowTouchMove={false}
              >
                {/* SLIDE 1  */}
                <SwiperSlide>
                  <div className="modal-add-vehicule-body">
                    <Row className="justify-content-center">
                      <Col lg={7} className="modal-col-left">
                        <Row>
                          <Col lg={7} className="modal-div-center">
                            <span className="modal-span-title">
                              Type de véhicule
                            </span>
                            <br />
                            <div className="modal-div-background-btn d-inline-flex jusitfy-content-between div-margin-bottom">
                              {props.can_create_tanker_trucks && (
                                <>
                                  <Button
                                    variant=""
                                    className={
                                      values.vehicle_type === 'CAMION_CITERNE'
                                        ? 'modal-btn'
                                        : 'modal-btn-desactive'
                                    }
                                    onClick={() =>
                                      setFieldValue(
                                        'vehicle_type',
                                        'CAMION_CITERNE'
                                      )
                                    }
                                  >
                                    Citerne sans débitmètre
                                  </Button>
                                  <Button
                                    variant=""
                                    className={
                                      values.vehicle_type ===
                                      'CAMION_CITERNE_DEBIMETRE'
                                        ? 'modal-btn'
                                        : 'modal-btn-desactive'
                                    }
                                    onClick={() =>
                                      setFieldValue(
                                        'vehicle_type',
                                        'CAMION_CITERNE_DEBIMETRE'
                                      )
                                    }
                                  >
                                    Citerne avec débitmètre
                                  </Button>
                                </>
                              )}
                              {props.can_create_trucks && (
                                <>
                                  <Button
                                    variant=""
                                    className={
                                      values.vehicle_type === 'CAMION'
                                        ? 'modal-btn'
                                        : 'modal-btn-desactive'
                                    }
                                    onClick={() =>
                                      setFieldValue('vehicle_type', 'CAMION')
                                    }
                                  >
                                    Camion remorque
                                  </Button>
                                </>
                              )}
                            </div>
                          </Col>
                          <Col>
                            <label className="modal-span-title">
                              Statut du véhicule
                            </label>
                            <div className="d-flex flex-column justify-content-center align-items-center">
                              <div
                                className="d-flex align-items-center"
                                style={{ marginTop: 25 }}
                              >
                                {props?.selectedVehicle?.is_disabled === 1 ? (
                                  <>
                                    <div
                                      className="fleet-background-modal-1 d-flex justify-content-center align-items-center"
                                      style={{
                                        backgroundColor: '#FFDAA4',
                                        color: '#FFC578',
                                      }}
                                    >
                                      <FontAwesomeIcon icon={faTruck} />
                                    </div>
                                    <span className="fleet-text-statut ms-2">
                                      Véhicule désactivé pour l’optimisation
                                    </span>
                                  </>
                                ) : props?.selectedVehicle
                                    ?.starting_platform_id === null ? (
                                  <>
                                    <div
                                      className="fleet-background-modal-1 d-flex justify-content-center align-items-center"
                                      style={{
                                        backgroundColor: '#c9cacc',
                                        color: '#6f7072',
                                      }}
                                    >
                                      <FontAwesomeIcon icon={faTruck} />
                                    </div>
                                    <span className="fleet-text-statut ms-2">
                                      Configuration nécessaire pour
                                      l’optimisation
                                    </span>
                                  </>
                                ) : props?.selectedVehicle === null ? (
                                  <>
                                    <SkeletonLoader
                                      height={'100%'}
                                      width={'100%'}
                                    />
                                  </>
                                ) : (
                                  <>
                                    <div
                                      className="fleet-background-modal-1 d-flex justify-content-center align-items-center"
                                      style={{
                                        backgroundColor: '#c4e7e5',
                                        color: '#079992',
                                      }}
                                    >
                                      <FontAwesomeIcon icon={faTruck} />
                                    </div>
                                    <span className="fleet-text-statut ms-2">
                                      Véhicule prêt pour l'optimisation
                                    </span>
                                  </>
                                )}
                              </div>
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col lg={6}>
                            <GenericFomikTextInput
                              type="text"
                              name="imat"
                              id="imat"
                              placeholder="CS123ABC"
                            >
                              Immatriculation du véhicule
                            </GenericFomikTextInput>
                          </Col>
                          <Col lg={1} />
                          <Col lg={5}>
                            <GenericFomikTextInput
                              type="text"
                              name="vehicule_key"
                              id="vehicule_key"
                              placeholder="12A"
                            >
                              Clé véhicule
                            </GenericFomikTextInput>
                          </Col>
                        </Row>

                        <Row>
                          <Col lg={4}>
                            <GenericFomikTextInput
                              type="number"
                              name="width"
                              id="width"
                              min="0"
                              placeholder="3.00"
                            >
                              Largeur (mètre)
                            </GenericFomikTextInput>
                          </Col>
                          <Col lg={4}>
                            <GenericFomikTextInput
                              type="number"
                              name="height"
                              id="height"
                              min="0"
                              placeholder="5.00"
                            >
                              Hauteur (mètre)
                            </GenericFomikTextInput>
                          </Col>
                          <Col lg={4}>
                            <GenericFomikTextInput
                              type="number"
                              name="length"
                              id="length"
                              min="0"
                              placeholder="8.00"
                            >
                              Longueur (mètre)
                            </GenericFomikTextInput>
                          </Col>
                        </Row>
                      </Col>
                      {/* <Col
                  lg={1}
                  className="modal-col-middle d-flex justify-content-center"
                >
                  <div className="modal-separator" />
                </Col>

                <Col lg={3} className="modal-col-right">
                  <span className="modal-span-title">
                    Propriétés du vehicule
                  </span>
                  <div className="container-background">
                    <span className="vehicule-props">
                      <FontAwesomeIcon icon={faWeightHanging} /> 750 kg max.
                    </span>
                    <span className="vehicule-props">
                      <FontAwesomeIcon icon={faWeightHanging} /> 7 compartiments
                    </span>
                    <span className="vehicule-props">
                      <FontAwesomeIcon icon={faWeightHanging} /> Piable
                    </span>
                  </div>
                </Col> */}
                    </Row>
                  </div>
                </SwiperSlide>

                {/* SLIDE 2  */}
                <SwiperSlide>
                  <div className="modal-add-vehicule-body-2">
                    <div className="container-top-modal-2">
                      <div className={'container-flex'}>
                        <div className={'l-side'}>
                          <div className="d-flex justify-content-center flex-column align-items-center">
                            <FontAwesomeIcon
                              className="icon-small"
                              icon={faKey}
                            />
                            <span className="span-small">clé compartiment</span>
                          </div>
                        </div>
                        <div className={'container-separator-1'}>
                          <div className="separator-1" />
                        </div>
                        <div className={'r-side-1'}>
                          <div className="d-flex justify-content-center flex-column align-items-center">
                            <FontAwesomeIcon
                              className="icon-small"
                              icon={faWeightHanging}
                            />
                            <span className="span-small">kg max.</span>
                          </div>
                        </div>
                        <div className={'container-separator-2'}>
                          <div className="separator-2" />
                        </div>
                        <div className={'r-side-2'}>
                          <div className="d-flex justify-content-center flex-column align-items-center">
                            <FontAwesomeIcon
                              className="icon-small"
                              icon={faCube}
                            />
                            <span className="span-small">Volume m³</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="container-color-modal-2">
                      <div className="fleet-item-tour-compartiment-list-part">
                        <SimpleBarReact
                          forceVisible="y"
                          style={{
                            maxHeight: '100%',
                            width: '100%',
                            padding: '10px 10px 10px 0px',
                            flexGrow: 1,
                          }}
                        >
                          {values.containers?.map(
                            (container, container_index) => {
                              return (
                                <div
                                  className="fleet-item-tour-compartiment-modal-2"
                                  key={`COMPARTIMENT_${container.uuid}`}
                                >
                                  <div
                                    className={
                                      'fleet-first-line-tour-compartiment-modal-2'
                                    }
                                  >
                                    <div className={'hw-modal-2'}>
                                      <div className="border-dashed d-flex justify-content-center align-items-center">
                                        <div className="rounded-background d-flex justify-content-center align-items-center">
                                          <span className="span-color">
                                            {container_index + 1}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div className={'fr-modal-2'}>
                                      <span className="fleet-item-title-id-modal-2">
                                        COMPARTIMENT N° {container_index + 1}
                                      </span>
                                    </div>
                                    <div className={'hwe-1-modal-2'}>
                                      <input
                                        type="number"
                                        min="0"
                                        className="modal-input-modal-2"
                                        value={container.max_weight}
                                        placeholder="17"
                                        onChange={(event) => {
                                          setFieldValue('containers', [
                                            ...values.containers.slice(
                                              0,
                                              container_index
                                            ),
                                            {
                                              ...container,
                                              max_weight: event.target.value,
                                            },
                                            ...values.containers.slice(
                                              container_index + 1
                                            ),
                                          ]);
                                        }}
                                      />
                                    </div>
                                    <div className={'hwe-2-modal-2'}>
                                      <input
                                        type="number"
                                        min="0"
                                        className="modal-input-modal-2"
                                        placeholder="34"
                                        value={container.max_volume}
                                        onChange={(event) => {
                                          setFieldValue('containers', [
                                            ...values.containers.slice(
                                              0,
                                              container_index
                                            ),
                                            {
                                              ...container,
                                              max_volume: event.target.value,
                                            },
                                            ...values.containers.slice(
                                              container_index + 1
                                            ),
                                          ]);
                                        }}
                                      />
                                    </div>
                                    <div className={'hwe-3-modal-2'}>
                                      <Button
                                        variant=""
                                        className="fleet-btn-suppr toggleable"
                                        onClick={() => {
                                          deleteContainer(
                                            container.uuid,
                                            values.containers,
                                            setFieldValue
                                          );
                                        }}
                                        disabled={values.containers?.length < 2}
                                      >
                                        <FontAwesomeIcon
                                          icon={faTrash}
                                          className="fleet-icon-trash"
                                        />
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          )}
                        </SimpleBarReact>
                      </div>
                      <div className="fleet-item-tour-compartiment-horizontal-divider"></div>
                      <div className="fleet-item-tour-compartiment-add-part">
                        <Button
                          variant=""
                          className="fleet-button-add-modal-2"
                          onClick={() => {
                            setToggleAddContainer(!toggleAddContainer);
                            if (toggleAddContainer) {
                              setAddContainerMaxWeight(0);
                              setAddContainerMaxVolume(0);
                            }
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faPlusCircle}
                            className="btn-icon-more-platform-modal-2"
                          />
                          <span className="fleet-span-text-modal-2">
                            Ajouter un compartiment au véhicule
                          </span>
                        </Button>

                        {toggleAddContainer ? (
                          <div className="fleet-item-tour-compartiment-modal-2 add">
                            <div
                              as={Card.Header}
                              className={
                                'fleet-first-line-tour-compartiment-modal-2'
                              }
                            >
                              <div className={'hw-modal-2'}>
                                <div className="border-dashed d-flex justify-content-center align-items-center">
                                  <div className="rounded-background d-flex justify-content-center align-items-center">
                                    <span className="span-color">
                                      {vehicle.containers?.length + 1}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className={'fr-modal-2'}>
                                <span className="fleet-item-title-id-modal-2">
                                  COMPARTIMENT N°{' '}
                                  {vehicle.containers?.length + 1}
                                </span>
                              </div>
                              <div className={'hwe-1-modal-2'}>
                                <input
                                  type="number"
                                  min="0"
                                  className="modal-input-modal-2"
                                  value={addContainerMaxWeight}
                                  placeholder="17"
                                  onChange={(e) =>
                                    setAddContainerMaxWeight(e.target.value)
                                  }
                                />
                              </div>
                              <div className={'hwe-2-modal-2'}>
                                <input
                                  type="number"
                                  min="0"
                                  placeholder="34"
                                  className="modal-input-modal-2"
                                  value={addContainerMaxVolume}
                                  onChange={(e) =>
                                    setAddContainerMaxVolume(e.target.value)
                                  }
                                />
                              </div>
                              <div className={'hwe-3-modal-2'}>
                                <Button
                                  variant=""
                                  className="fleet-btn-add-container"
                                  onClick={() => {
                                    addNewContainer(
                                      values.containers,
                                      setFieldValue
                                    );
                                  }}
                                  disabled={
                                    !addContainerMaxWeight ||
                                    !addContainerMaxVolume
                                  }
                                >
                                  {/* <FontAwesomeIcon
                                    icon={faPlus}
                                    className="btn-icon-platform-add"
                                  /> */}
                                  Ajouter
                                </Button>
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </SwiperSlide>

                {/* SLIDE 3  */}
                <SwiperSlide>
                  <div className="modal-add-vehicule-body-3">
                    <div className="platform-list-container">
                      <SimpleBarReact
                        forceVisible="y"
                        style={{
                          maxHeight: '100%',
                          width: '100%',
                          padding: '10px 10px 10px 0px',
                          flexGrow: 1,
                        }}
                      >
                        {props.platforms?.length > 0 ? (
                          <>
                            {props.platforms.map((platform, index) => (
                              <div
                                key={index}
                                className={'fleet-platform-list-modal-3'}
                                onClick={() =>
                                  setStartingPlateformID(
                                    platform.id,
                                    setFieldValue
                                  )
                                }
                              >
                                <div
                                  as={Card.Header}
                                  className={
                                    'fleet-first-line-tour-management-modal-3'
                                  }
                                >
                                  <div className={'hw-modal-3'}>
                                    {/* <img
                                      src={imgBox}
                                      className={
                                        "img-illustration-fleet-2-modal-3"
                                      }
                                      alt={"box"}
                                    /> */}
                                    <div
                                      className="rounded-background d-flex justify-content-center align-items-center"
                                      style={{
                                        backgroundColor: platform.color,
                                      }}
                                    >
                                      <div className="span-color">
                                        {platform.id}
                                      </div>
                                    </div>
                                  </div>
                                  <div className={'fr-modal-3'}>
                                    <BadgeColor
                                      color={platform.color}
                                      platform={platform.id}
                                    />
                                    <span
                                      className={'fleet-item-address-modal-3'}
                                    >
                                      {platform.full_address}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </>
                        ) : (
                          <div className="no-tour-container">
                            <span className="no-tour-title">
                              Aucune plateforme définie
                            </span>
                            <span className="no-tour-text-platform">
                              Ajouter une plateforme avec le bouton ci-dessous.
                            </span>
                          </div>
                        )}
                      </SimpleBarReact>
                    </div>
                    <div className="fleet-item-tour-compartiment-horizontal-divider-modal-3"></div>
                  </div>
                </SwiperSlide>

                {/* SLIDE 4  */}
                <SwiperSlide>
                  <div className="modal-add-vehicule-body-4">
                    <div className="container-text">
                      <h3 className="title-verif text-center">
                        Tout est bon pour vous ?
                      </h3>
                      <p className="msg-verif text-center">
                        Vérifiez les paramètres de votre camion et confirmez via
                        le bouton ci-dessous, si tout vous semble bon
                      </p>
                    </div>
                    <div className="container-background">
                      <div className={`fleet-item-tour-management-modal-4`}>
                        <div
                          as={Card.Header}
                          className={'fleet-first-line-tour-management-modal-4'}
                        >
                          <div className={'hw-modal-4'}>
                            <img
                              src={truck}
                              className={'img-illustration-fleet-2-modal-4'}
                              alt={'box'}
                            />
                          </div>
                          <div className={'fr-modal-4'}>
                            <BadgeColor
                              color={
                                props.platforms.find(
                                  (element) =>
                                    element.id === values.starting_platform_id
                                )?.color
                              }
                              platform={values.starting_platform_id}
                            />
                            <span className={'fleet-item-title-modal-4'}>
                              Véhicule immatriculé {values.imat}
                            </span>
                            <span className={'fleet-item-desc-modal-4'}>
                              CLÉ VÉHICULE {values.vehicule_key}
                            </span>
                          </div>
                          {/* <div className={"hwe-auto-modal-4"}>
                      <span className="fleet-info-truck-modal-4">
                        <FontAwesomeIcon icon={faWeightHanging} /> 750 kg max.
                      </span>
                      <span className="fleet-info-truck-modal-4">
                        <FontAwesomeIcon icon={faWeightHanging} /> Pliable
                      </span>
                      <span className="fleet-info-truck-modal-4">
                        <FontAwesomeIcon icon={faWeightHanging} /> Solide
                      </span>
                      <span className="fleet-info-truck-modal-4">
                        <FontAwesomeIcon icon={faArrowsAltH} /> 670m²
                      </span>
                    </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
              <div className="d-flex justify-content-center">
                <span className="error-body">{error}</span>
              </div>
            </Modal.Body>

            <Modal.Footer className="modal-add-vehicule-footer justify-content-center">
              {step > 0 ? (
                <Button
                  variant="secondary"
                  className="modal-btn-footer-back"
                  onClick={() => {
                    goToPrev();
                  }}
                >
                  Retour
                </Button>
              ) : null}

              {step !== 2 ? (
                <Button
                  variant="secondary"
                  className="modal-btn-footer"
                  disabled={isLoading || canGoToNextStep(values, errors)}
                  onClick={() => {
                    step !== 3 ? goToNext() : submitForm();
                  }}
                >
                  {step !== 3 ? "Passer à l'étape suivante" : 'Enregistrer'}
                </Button>
              ) : null}
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

export default ModalAddVehicule;
