import { useState } from 'react';
import { toast } from 'react-toastify';
import fleetApi from '../../services/api.service';

export default function useGetClients() {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(null);
  const [data, setData] = useState(null);

  const getClients = async () => {
    resetStates();
    try {
      setIsLoading(true);
      const response = await fleetApi.get(`/admin/clients`);
      await new Promise((resolve) => setTimeout(resolve, 500));
      if (response.status === 200) {
        setIsSuccess(true);
        setData(response?.data?.clients);
      } else {
        throw new Error(response.data?.message || 'Une erreur est survenue.');
      }
    } catch (error) {
      setIsSuccess(false);
      toast.error(error?.response.data?.message || error?.message);
    } finally {
      setIsLoading(false);
    }
  };

  const resetStates = () => {
    setIsSuccess(false);
    setIsLoading(false);
  };

  return {
    data,
    isLoading,
    isSuccess,
    getClients,
  };
}
