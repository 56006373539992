import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import EmptyList from '../agv-empty-list';
import DatatableSkeleton from './datatableSkeleton';

export default function Datatable({
  header,
  data,
  values,
  isLoading,
  isFirstLoading,
  style,
}) {
  return data?.length > 0 || isLoading ? (
    <div className={`${style}`}>
      <div className="tw-relative">
        {isFirstLoading ? (
          <DatatableSkeleton />
        ) : (
          <table className="tw-w-full tw-border-collapse tw-border-input-border tw-border tw-rounded tw-overflow-hidden tw-relative">
            <thead className="tw-bg-white/50">
              <tr className="tw-border-solid tw-border-background tw-border-0 tw-border-b">
                {header?.map((element, index) => (
                  <th
                    key={index}
                    className="tw-px-3 tw-py-1.5 tw-text-smi tw-font-normal tw-border-solid tw-border-background tw-border-0 tw-border-x tw-font-work tw-text-[#777777]"
                  >
                    {element}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="tw-bg-white">
              {data?.map((row, index) => {
                return (
                  <tr
                    key={index}
                    className="tw-border-solid tw-border-background tw-border-0 tw-border-b"
                  >
                    {values(row).map((element, index) => {
                      return (
                        <td
                          key={index}
                          className="tw-px-3 tw-py-3 tw-text-smi tw-font-normal tw-border-solid tw-border-background tw-border-0 tw-border-x tw-font-work tw-text-text-dark-high-importance"
                        >
                          {element}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
        {isLoading && (
          <div className="tw-absolute tw-top-0 tw-h-full tw-w-full tw-flex tw-justify-center tw-items-center tw-backdrop-blur-[1px]">
            <FontAwesomeIcon
              icon={faCircleNotch}
              className="tw-animate-spin tw-size-6 tw-text-dimgray-100"
            />
          </div>
        )}
      </div>
    </div>
  ) : (
    <EmptyList />
  );
}
