import React from 'react';

export default function Header({
  title,
  subtitle,
  filters,
  padding = { x: 'tw-px-[32px]', y: 'tw-py-[16px]' },
  page,
}) {
  return (
    <div
      className={`tw-flex tw-justify-center tw-w-full ${padding.y} ${padding.x} 
        tw-bg-[#EBECF0] tw-border-0 tw-border-b tw-border-solid tw-border-border-primary-strong`}
    >
      <div className={`tw-flex tw-grow tw-bg-background tw-justify-between tw-items-center ${page !== 'Dashboard' && "tw-max-w-[1320px]"}`}>
        <div className="tw-flex tw-flex-col tw-gap-1">
          <h1 className="m-0 relative tw-tracking-[-0.02em] tw-font-semibold tw-whitespace-nowrap tw-text-[22px] tw-leading-[22px]">
            {title}
          </h1>
          <span className="tw-tracking-[-0.02em] tw-font-semibold tw-font-work tw-whitespace-nowrap mq450:hidden tw-text-text-dark-medium-importance tw-text-xs tw-leading-[12px]">
            {subtitle}
          </span>
        </div>
        <div className="tw-flex tw-space-x-3 tw-flex-wrap">{filters}</div>
      </div>
    </div>
  );
}
