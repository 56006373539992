import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import React, { useState, useEffect } from 'react';
import classNames from 'classnames';

const themes = {
  default: {
    button: 'tw-px-[4px] tw-py-[2px] tw-border-none',
    items:
      'tw-bg-white tw-border tw-border-[#CDCDCA] tw-border-solid tw-p-1 tw-rounded',
    item: 'tw-text-text-dark-high-importance tw-rounded',
  },
};

export default function AgvTextDropdown({
  options,
  onChange,
  defaultValue,
  theme = 'default',
  width,
}) {
  const currentTheme = themes[theme] || themes.default;
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    if (defaultValue) {
      setSelectedOption(defaultValue);
    }
  }, [defaultValue]);

  const buttonClassName = classNames(
    'tw-relative tw-text-[13px] tw-underline tw-font-medium tw-font-work-sans tw-text-[#29a9a3] tw-text-left tw-px-4 tw-py-2',
    currentTheme.button
  );

  const handleOptionChange = (optionValue) => {
    setSelectedOption(optionValue);
    onChange(optionValue);
  };

  return (
    <Menu as="div" className="tw-relative">
      <MenuButton
        className={buttonClassName}
        style={{
          lineHeight: '20px',
          borderRadius: '5px',
          ...(width && { width }),
        }}
      >
        {options.find((option) => option.value === selectedOption)?.name ||
          'À définir'}
      </MenuButton>
      <MenuItems
        className={classNames(
          'tw-absolute tw-z-10 tw-mt-2 tw-w-32 tw-shadow-lg tw-max-h-60 tw-overflow-y-auto',
          currentTheme.items
        )}
      >
        {options.map((option) => (
          <MenuItem key={option.value}>
            {({ active }) => (
              <button
                className={classNames(
                  'tw-block tw-w-full tw-text-left tw-px-2 tw-py-1 tw-border-none tw-bg-transparent',
                  currentTheme.item,
                  'data-[headlessui-state~="active"]:tw-bg-black/5'
                )}
                onClick={() => handleOptionChange(option.value)}
              >
                {option.name}
              </button>
            )}
          </MenuItem>
        ))}
      </MenuItems>
    </Menu>
  );
}
