import React from 'react';

export default function FieldTitle({ title, isRequired, isError, tooltip }) {
  return (
    <div className="tw-flex tw-font-work tw-justify-between tw-text-text-dark-medium-importance tw-items-center tw-mb-3 tw-text-sm">
      <div className="tw-text-s">{title}</div>
      {isRequired && (
        <div className={`tw-text-xs tw-text-[#999999]}`}>{tooltip}</div>
      )}
    </div>
  );
}
