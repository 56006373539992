import {
  faBiohazard,
  faDolly,
  faEye,
  faFile,
  faGasPump,
  faGear,
  faKey,
  faListCheck,
  faTruckDroplet,
  faVest,
  faWarning,
} from '@fortawesome/free-solid-svg-icons';

export const CHECKLIST_TYPE = {
  COMMON: 'COMMON',
  SPECIFIC: 'SPECIFIC',
};

export const CHECKLIST_ICONS = {
  BAG_SHOPPING: 'faDolly',
  BIOHAZARD: 'faBiohazard',
  FILE: 'faFile',
  GAS: 'faGasPump',
  FLUID: 'faTruckDroplet',
  VISU: 'faEye',
  ENGINE: 'faGear',
  VESTE: 'faVest',
  OTHER: 'faListCheck',
  WARNING: 'faWarning',
  KEY: 'faKey',
};

export const CHECKLIST_ICONS_DISPLAY = {
  [CHECKLIST_ICONS.BAG_SHOPPING]: faDolly,
  [CHECKLIST_ICONS.BIOHAZARD]: faBiohazard,
  [CHECKLIST_ICONS.FILE]: faFile,
  [CHECKLIST_ICONS.GAS]: faGasPump,
  [CHECKLIST_ICONS.FLUID]: faTruckDroplet,
  [CHECKLIST_ICONS.VISU]: faEye,
  [CHECKLIST_ICONS.ENGINE]: faGear,
  [CHECKLIST_ICONS.VESTE]: faVest,
  [CHECKLIST_ICONS.OTHER]: faListCheck,
  [CHECKLIST_ICONS.WARNING]: faWarning,
  [CHECKLIST_ICONS.KEY]: faKey,
};

export const CHECKLIST_ICONS_DISPLAY_NAME = {
  [CHECKLIST_ICONS.BAG_SHOPPING]: 'Sac',
  [CHECKLIST_ICONS.BIOHAZARD]: 'Risque',
  [CHECKLIST_ICONS.FILE]: 'Fichier',
  [CHECKLIST_ICONS.GAS]: 'Essence',
  [CHECKLIST_ICONS.FLUID]: 'Niveaux',
  [CHECKLIST_ICONS.VISU]: 'Visuel',
  [CHECKLIST_ICONS.ENGINE]: 'Moteur',
  [CHECKLIST_ICONS.VESTE]: 'Accessoires',
  [CHECKLIST_ICONS.OTHER]: 'Divers',
  [CHECKLIST_ICONS.WARNING]: 'Important',
  [CHECKLIST_ICONS.KEY]: 'Clé',
};

export const CHECKLIST_ICONS_ARRAY = [
  CHECKLIST_ICONS.BAG_SHOPPING,
  CHECKLIST_ICONS.BIOHAZARD,
  CHECKLIST_ICONS.FILE,
  CHECKLIST_ICONS.GAS,
  CHECKLIST_ICONS.FLUID,
  CHECKLIST_ICONS.VISU,
  CHECKLIST_ICONS.ENGINE,
  CHECKLIST_ICONS.VESTE,
  CHECKLIST_ICONS.OTHER,
  CHECKLIST_ICONS.WARNING,
  CHECKLIST_ICONS.KEY,
];
