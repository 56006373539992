import debounce from 'lodash.debounce';
import mapboxgl from 'mapbox-gl';
import React, { useEffect, useRef, useState } from 'react';
import SkeletonLoader from 'tiny-skeleton-loader-react';
import in_out_disable from '../../../../assets/images/icons/__OLD__/2.0.png';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

const MapFleetOptimization = (props) => {
  /**
   ******* States & hooks ********
   **/

  const mapContainerRef = useRef(null);
  const mapWrapper = useRef(null);

  const LONGITUDE = 4.0333;
  const LATITUDE = 49.25;
  const ZOOM = 5;
  const [map, setMap] = useState(null);
  const [isMapLoaded, setIsMapLoaded] = useState(false);

  /**
   ******* Effects ********
   **/

  useEffect(() => {
    const resizeObserver = new ResizeObserver(
      debounce(() => {
        map?.resize?.();
      }, 100)
    );

    resizeObserver.observe(mapWrapper.current);
    return () => {
      resizeObserver.disconnect();
    };
  }, [map, mapWrapper]);

  // Initialize map when component mounts
  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: 'mapbox://styles/aberte1829/ckmc3nvuj2sbg17n33jf8rsr0',
      center: [LONGITUDE, LATITUDE],
      zoom: ZOOM,
      attributionControl: false,
    });

    setMap(map);

    map.on('load', () => {
      // remove all the previous point
      if (map.hasImage('mapPin')) map.removeImage('mapPin');
      if (map.hasImage('mapPinStep')) map.removeImage('mapPinStep');

      map.loadImage(in_out_disable, function (error, image) {
        if (error) throw error;
        map.addImage('mapPin', image);
        map.addImage('mapPinStep', image, { sdf: true });
      });
    });
    // map.on("moveend", () => {
    //   map?.resize();
    // });

    // Clean up on unmount
    return () => map?.remove();
  }, []);

  useEffect(() => {
    function buildPlatformStart(data) {
      // features modeling
      let plaformStart;

      if (data) {
        plaformStart = {
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'Point',
            coordinates: [data?.[1], data?.[0]],
          },
        };
      }

      return plaformStart;
    }

    function buildFeatures(data) {
      // features modeling
      let features = [];
      let index = 1;
      for (let waypoint of data) {
        let feature = {
          type: 'Feature',
          properties: {
            point_count: index ?? 0,
          },
          geometry: {
            type: 'Point',
            coordinates: [waypoint?.long, waypoint?.lat],
          },
        };

        features.push(feature);
        index++;
      }
      return features;
    }

    function buildRoute(GeoJsonLine) {
      // features modeling
      let feature = {
        type: 'Feature',
        properties: {},

        geometry: {
          type: 'LineString',
          coordinates: GeoJsonLine,
        },
      };
      return feature;
    }

    function setSourceLayer(map, features, route, platformStart) {
      removeLayerAndFeatures(
        [
          'route-outline',
          'routes-outline',
          'routes-step',
          'route',
          'routes',
          'clusters',
          'cluster-count',
          'steps',
          'platform-start',
        ],
        map
      );

      map.addSource('route', {
        type: 'geojson',
        data: route,
      });

      map.addLayer({
        id: 'route-outline',
        type: 'line',
        source: 'route',
        layout: {
          'line-join': 'round',
          'line-cap': 'round',
        },
        paint: {
          'line-color': props.pickColorAtIndex(props.geometry_index)[1],
          'line-width': 2,
          'line-gap-width': 3,
        },
      });

      map.addLayer({
        id: 'route',
        type: 'line',
        source: 'route',
        layout: {
          'line-join': 'round',
          'line-cap': 'round',
        },
        paint: {
          'line-color': props.pickColorAtIndex(props.geometry_index)[0],
          'line-width': 3,
        },
      });

      map.addSource('steps', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: features,
        },
      });

      map.addSource('platform-start', {
        type: 'geojson',
        data: platformStart,
      });

      map.addLayer({
        id: 'platform-start',
        type: 'symbol',
        source: 'platform-start',
        layout: {
          'icon-image': 'mapPin',
          'icon-size': 0.6,
          'icon-allow-overlap': true,
        },
      });

      map.addLayer({
        // Clusters
        id: 'clusters',
        type: 'circle',
        source: 'steps',
        paint: {
          'circle-color': '#079992',
          'circle-radius': 11,
          'circle-stroke-color': '#FFFFFF',
          'circle-stroke-width': 3,
        },
      });

      // Cluster Count number
      map.addLayer({
        id: 'cluster-count',
        type: 'symbol',
        source: 'steps',
        filter: ['has', 'point_count'],
        layout: {
          'text-field': '{point_count}',
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': 13,
        },
        paint: {
          'text-color': '#FFFFFF',
        },
      });

      // Zoom to the line
      if (route.geometry.coordinates) {
        const coordinates = route.geometry.coordinates;
        // Create a 'LngLatBounds' with both corners at the first coordinate.
        const bounds = new mapboxgl.LngLatBounds(
          coordinates[0],
          coordinates[0]
        );

        for (const coord of coordinates) {
          bounds.extend(coord);
        }

        map.fitBounds(bounds, {
          duration: 1200,
          linear: false,
          padding: 120,
        });

        // // excute after 1200ms
        // setTimeout(() => {
        //   map?.resize?.();
        // }, 200);
      }
    }

    // if there is shops
    if (props.waypoints && props.geometry) {
      // create popup
      const features = buildFeatures(props.waypoints);
      const route = buildRoute(props.geometry);
      const platformStart = buildPlatformStart(props.platformStart);

      if (map != null && map !== undefined) {
        setSourceLayer(map, features, route, platformStart);
        setIsMapLoaded(true);
        map?.resize?.();
      }
    } else {
      removeLayerAndFeatures(
        [
          'route-outline',
          'routes-outline',
          'route',
          'routes',
          'clusters',
          'cluster-count',
          'steps',
          'platform-start',
        ],
        map
      );
      map?.zoomTo(ZOOM, {
        duration: 1200,
      });
      map?.resize?.();

      setIsMapLoaded(true);
    }
  }, [props.waypoints, props.geometry, props.platformStart, map]);

  /**
   * Draw all routes on the map when the file is uploaded & none tours are selected --- tempo
   */
  useEffect(() => {
    function buildRouteStep(steps, color) {
      // features modeling
      let routesSteps = [];

      if (steps?.length > 0) {
        steps.forEach((step) => {
          if (step.lat && step.long) {
            routesSteps.push({
              type: 'Feature',
              properties: {
                color: color,
              },
              geometry: {
                type: 'Point',
                coordinates: [step.long, step.lat],
              },
            });
          }
        });
      }

      return routesSteps;
    }

    function removeLayerAndFeatures(idsArray = ['steps', 'route'], map) {
      for (let id of idsArray) {
        if (map?.getLayer(id)) {
          map.removeLayer(id);
        }
        if (map?.getSource(id)) {
          map.removeSource(id);
        }
      }
    }

    if (map != null && !props.geometry && props.optimizationData?.length > 0) {
      let trips = [];
      let steps = [];

      for (let i = 0; i < props.optimizationData.length; i++) {
        for (let j = 0; j < props.optimizationData[i].vehicles?.length; j++) {
          if (
            props.optimizationData[i].vehicles[j]?.routes?.[0]?.geometry
              ?.coordinates
          ) {
            trips.push({
              type: 'Feature',
              properties: {
                'line-color-prop': props.pickColorAtIndex(j)?.[0],
                'outline-color-prop': props.pickColorAtIndex(j)?.[1],
              },

              geometry: {
                type: 'LineString',
                coordinates:
                  props.optimizationData[i].vehicles[j]?.routes?.[0]?.geometry
                    ?.coordinates,
              },
            });

            steps = [
              ...steps,
              ...buildRouteStep(
                props.optimizationData[i].vehicles[j].steps,
                props.pickColorAtIndex(j)?.[1]
              ),
            ];
          }
        }
      }

      if (trips?.length > 0) {
        removeLayerAndFeatures(
          [
            'route-outline',
            'routes-outline',
            'routes-step',
            'route',
            'routes',
            'clusters',
            'cluster-count',
            'steps',
            'platform-start',
          ],
          map
        );

        map.addSource('routes', {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: trips,
          },
        });

        map.addLayer({
          id: 'routes-outline',
          type: 'line',
          source: 'routes',
          layout: {
            'line-join': 'round',
            'line-cap': 'round',
          },
          paint: {
            'line-color': ['get', 'outline-color-prop'],
            'line-width': 1,
            'line-gap-width': 2,
          },
        });

        map.addSource('routes-step', {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: steps,
          },
        });

        map.addLayer({
          id: 'routes-step',
          type: 'symbol',
          source: 'routes-step',
          layout: {
            'icon-image': 'mapPinStep',
            'icon-size': 0.4,
            'icon-allow-overlap': true,
          },
          paint: {
            'icon-color': ['get', 'color'],
            'icon-halo-color': 'rgba(0, 0, 0, 0.2)',
            'icon-halo-width': 1.5,
          },
        });

        map.addLayer({
          id: 'routes',
          type: 'line',
          source: 'routes',
          layout: {
            'line-join': 'round',
            'line-cap': 'round',
          },
          paint: {
            'line-color': ['get', 'line-color-prop'],
            'line-width': 0,
          },
        });

        if (trips?.[0]?.geometry?.coordinates) {
          const coordinates = trips?.[0]?.geometry?.coordinates;
          // Create a 'LngLatBounds' with both corners at the first coordinate.
          const bounds = new mapboxgl.LngLatBounds(
            coordinates[0],
            coordinates[0]
          );

          for (const coord of coordinates) {
            bounds.extend(coord);
          }

          for (let k = 0; k < trips?.length; k++) {
            const coordinates = trips?.[k]?.geometry?.coordinates;
            // Create a 'LngLatBounds' with both corners at the first coordinate.

            for (const coord of coordinates) {
              bounds.extend(coord);
            }
          }

          map.fitBounds(bounds, {
            duration: 1200,
            linear: false,
            padding: {
              top: 200,
              right: 100,
              bottom: 200,
              left: 100,
            },
          });
          map?.resize?.();
        }
      }
    }
  }, [props.optimizationData, props.geometry, map]);

  /**
   * Draw all start platform when the file is uploaded & none tours are selected
   */
  useEffect(() => {
    if (map && !props.geometry && props.allPlatformStart) {
      let platform = [];

      // Create features to display
      for (let i = 0; i < props.allPlatformStart.length; i++) {
        platform.push({
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'Point',
            coordinates: props.allPlatformStart[i],
          },
        });
      }

      if (platform?.length > 0) {
        // remove all layer from the map
        removeLayerAndFeatures(
          [
            'route-outline',
            'route',
            'clusters',
            'cluster-count',
            'steps',
            'platform-start',
          ],
          map
        );

        // add the data source of start platform
        map.addSource('platform-start', {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: platform,
          },
        });

        // platform marker layer
        map.addLayer({
          id: 'platform-start',
          type: 'symbol',
          source: 'platform-start',
          layout: {
            'icon-image': 'mapPin',
            'icon-size': 0.6,
          },
        });

        // This part will center the camera on all the platforms
        if (platform?.[0]?.geometry?.coordinates) {
          let bounds = null;

          for (let k = 0; k < platform?.length; k++) {
            const coordinates = platform?.[k]?.geometry?.coordinates;

            if (!bounds) {
              bounds = new mapboxgl.LngLatBounds(coordinates, coordinates);
            } else {
              for (const trip of platform) {
                bounds.extend(trip.geometry?.coordinates);
              }
            }
          }

          map.fitBounds(bounds, {
            duration: 1200,
            linear: false,
            maxZoom: 6,
            padding: 120,
          });

          map?.resize?.();
        }
      }
    }
  }, [props.allPlatformStart, props.geometry, map]);

  /**
   ******* Functions ********
   **/

  function removeLayerAndFeatures(idsArray = ['steps'], map) {
    for (let id of idsArray) {
      if (map?.getLayer(id)) {
        map.removeLayer(id);
      }
      if (map?.getSource(id)) {
        map.removeSource(id);
      }
    }
  }

  /**
   ******* Functions ********
   **/

  return (
    <div className="map-wrapper" ref={mapWrapper}>
      {!isMapLoaded ? <SkeletonLoader height={'100%'} width={'100%'} /> : null}
      <div
        style={isMapLoaded ? { display: 'block' } : { display: 'none' }}
        className="mapboxgl-map"
        ref={mapContainerRef}
      />
    </div>
  );
};

export default MapFleetOptimization;
