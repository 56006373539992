import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Dialog,
  DialogPanel,
  Transition,
  TransitionChild,
} from '@headlessui/react';
import React from 'react';
import { MODAL_SIZE, MODAL_SIZE_STYLE } from '../../enums/modal-size.enum';
import MainButton from '../agv-button';

export default function GenericModal({
  isOpen,
  setIsOpen,
  title,
  mainAction,
  mainActionText,
  mainActionDisabled,
  mainActionIsLoading,
  secondaryAction,
  secondaryActionIsDisabled,
  secondaryActionText,
  secondaryActionIsLoading,
  size = MODAL_SIZE_STYLE[MODAL_SIZE.LG],
  padding = 'tw-p-4',
  children,
}) {
  return (
    <Transition appear show={isOpen}>
      <Dialog
        as="div"
        className="tw-relative tw-z-[100] focus:tw-outline-none tw-w-full tw-rounded-8xs tw-bg-white tw-box-border tw-flex tw-flex-col tw-items-start tw-justify-start tw-gap-[32px] tw-text-base tw-text-text-dark-high-importance tw-font-list-item-title border-[1px] tw-border-solid border-dimgray-100 tw-mq750:gap-[16px]"
        onClose={() => setIsOpen(false)}
      >
        <div
          className="tw-fixed tw-inset-0 tw-bg-black/40"
          aria-hidden="true"
        />
        <TransitionChild
          enter="tw-ease-out tw-duration-300"
          enterFrom="tw-opacity-0 tw-transform-[scale(95%)]"
          enterTo="tw-opacity-100 tw-transform-[scale(100%)]"
          leave="tw-ease-in tw-duration-200"
          leaveFrom="tw-opacity-100 tw-transform-[scale(100%)]"
          leaveTo="tw-opacity-0 tw-transform-[scale(95%)]"
        >
          <div className="tw-fixed tw-inset-0 tw-w-screen tw-overflow-y-auto tw-p-4 ">
            <div className="tw-flex tw-min-h-full tw-items-center tw-justify-center">
              <DialogPanel
                className={`tw-flex tw-flex-col tw-max-h-[95vh] tw-w-full ${size} tw-rounded tw-bg-white`}
              >
                {/* Header */}
                <div
                  className={`tw-flex tw-items-center tw-justify-between tw-py-3 ${padding} tw-border-solid tw-border-background tw-border-0 tw-border-b`}
                >
                  <div className="tw-flex tw-font-semibold tw-text-base">
                    {title}
                  </div>
                  <FontAwesomeIcon
                    onClick={() => setIsOpen(false)}
                    className="tw-size-5 tw-cursor-pointer tw-text-checklist-common"
                    icon={faTimes}
                  />
                </div>
                {/* Body */}
                <div
                  className={`${padding} tw-overflow-y-auto tw-overflow-x-hidden tw-max-h-[90vh] tw-relative tw-flex tw-flex-col`}
                >
                  {children}
                </div>
                {/* Footer */}
                {mainAction || secondaryAction ? (
                  <div
                    className={`tw-flex tw-flex-1 tw-items-center tw-justify-end tw-py-3 ${padding} tw-border-solid tw-border-background tw-border-0 tw-border-t tw-overflow-auto`}
                  >
                    {secondaryAction && (
                      <MainButton
                        text={secondaryActionText}
                        onClick={secondaryAction}
                        disabled={
                          secondaryActionIsDisabled || mainActionIsLoading
                        }
                        isLoading={secondaryActionIsLoading}
                        style={`${mainAction ? 'tw-mr-2' : ''}`}
                        variant="secondary"
                      />
                    )}
                    {mainAction && (
                      <MainButton
                        text={mainActionText}
                        onClick={mainAction}
                        disabled={
                          mainActionDisabled || secondaryActionIsLoading
                        }
                        isLoading={mainActionIsLoading}
                      />
                    )}
                  </div>
                ) : null}
              </DialogPanel>
            </div>
          </div>
        </TransitionChild>
      </Dialog>
    </Transition>
  );
}
