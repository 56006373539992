import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

export default function HeaderButton({ onClick, text, icon, style, disabled }) {
  return (
    <button
      onClick={onClick}
      className={`tw-pl-4 tw-pr-4 header-button-style ${style}`}
      disabled={disabled}
      style={{
        lineHeight: '20px',
        borderRadius: '5px',
      }}
    >
      {icon && (
        <FontAwesomeIcon
          className={`tw-size-2.5 tw-text-text-dark-high-importance ${text ? 'tw-mr-2' : ''}`}
          icon={icon}
        />
      )}
      {text && <div>{text}</div>}
    </button>
  );
}
