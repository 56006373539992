export const PACKAGING_TYPE = {
  VRAC: 'VRAC',
  COFFRE: 'Coffre à sacs',
  SAC: 'SAC',
};

export const BL_STATUS = {
  PLANNED: 'PLANNED',
  STARTED: 'STARTED',
  DELIVERED: 'DELIVERED',
  CANCELED: 'CANCELED',
};
